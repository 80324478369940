import axios from 'axios';
import {getApi, AXIOS_CONFIG} from "../../utils";

export default class FeedApiService {

  constructor(currentNetworkId = 1) {
    this.currentNetworkId = currentNetworkId;
    this.api = getApi();
  }

  /**
   * Updates the currentNetworkId, needed for when switching network
   * @param currentNetworkId
   */
  setNetworkId(currentNetworkId) {
    this.currentNetworkId = currentNetworkId;
  }

  /**
   * Finds the latest creations on KODA
   *
   * @param limit
   * @param orderBy
   * @param order
   * @return {Promise<*>}
   */
  async latestCreations(limit = 24, orderBy = 'priceInEther', order = 'desc') {
    console.log(`Load latest creations on network [${this.currentNetworkId}]`);

    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/feed/latest?orderBy=${orderBy}&order=${order}&limit=${limit}`, AXIOS_CONFIG);
    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

  /**
   * Finds the latest trending items on KODA
   *
   * @param limit
   * @param orderBy
   * @param order
   * @return {Promise<*>}
   */
  async trendingEditions(limit = 24, orderBy = 'priceInEther', order = 'desc') {
    console.log(`Load trending on network [${this.currentNetworkId}]`);
    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/feed/trending?orderBy=${orderBy}&order=${order}&limit=${limit}`, AXIOS_CONFIG);
    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

}

