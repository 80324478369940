import Vue from 'vue';
import * as actions from '../actions';
import * as mutations from '../mutation';
import _ from 'lodash';

function featureArtworks(network) {
  switch (network) {
    case 'Main':
    case 'Rinkeby':
    case 'Ropsten':
    case 'Local':
      return [
        29700,
        29800,
        28900,
        28000,
        29800,
        29900,
        30000,
        30100,
        30200,
        30300,
        30400,
        30500,
        30600,
        30700
      ];
    default:
      return [];
  }
}

const contractStateModule = {
  namespaced: true,
  state: {

    // Assets are used through the app and stored in a map of {editionNumber -> edition}
    assets: {},
    marketApproval: {},
    accountOwnedEditions: [],

    subscriptions:{},

    // contract stats
    contractAddress: null,
    totalSupply: null,
    totalPurchaseValueInWei: null,
    totalPurchaseValueInEther: null,
    totalNumberMinted: null,
    totalNumberAvailable: null,
    totalEditions: null,
    koCommissionAccount: null
  },
  getters: {
    alreadyPurchasedEdition: (state) => (editionNumber) => {
      return _.find(state.accountOwnedEditions, {edition: _.toString(editionNumber)});
    },
    editions: (state) => {
      //console.log(state.assets)
      return _.sortBy(state.assets, 'edition')
    },
    featuredEditions: (state, getters, rootState) => () => {
      const artworks = featureArtworks(rootState.currentNetwork);
      return _.pickBy(state.assets, function (value, key) {
        return artworks.indexOf(_.toNumber(key)) > -1;
      });
    },
    editionsForArtist: (state) => (artistAccount) => {
      if (_.isArray(artistAccount)) {
        let artistEditions = {};
        _.forEach(artistAccount, (account) => {
          let found = _.pickBy(state.assets, (value, key) => value.artistAccount === account);
          if (found) {
            _.merge(artistEditions, found);
          }
        });

        return artistEditions;
      } else {
        return _.pickBy(state.assets, (value, key) => value.artistAccount === artistAccount);
      }
    },
    findEdition: (state) => (editionNumber) => {
      return state.assets[editionNumber];
    },
    findPurchasedEdition: (state) => ({tokenId}) => {
      return _.find(state.accountOwnedEditions, {tokenId});
    },
    isStartDateInTheFuture: (state) => (startDate) => {
      // Zero means the its already available from the point of being minted
      if (startDate === 0) {
        return false;
      }
      return _.toNumber(startDate) > (new Date().getTime() / 1000);
    },
  },
  mutations: {
    [mutations.SET_ACCOUNT_EDITIONS](state, accountOwnedEditions) {
      console.log('SET_ACCOUNT_EDITIONS')
      Vue.set(state, 'accountOwnedEditions', accountOwnedEditions);
    },
    [mutations.SET_EDITION](state, data) {
      console.log('SET_EDITION')
      if (data && data.edition) {
        let {edition} = data;
        Vue.set(state.assets, edition, data);
      }
    },
    [mutations.SET_EDITIONS](state, editions) {
      console.time('SET_EDITIONS');
      //for(let i=0; i<editions.length; i++) {
      //  Vue.set(state.assets, editions[i].edition, Object.freeze(editions[i]));
      //}
      //Vue.set(state, 'assets', _.keyBy(editions, 'edition'))
      _.forEach(editions, (data) => {
        Vue.set(state.assets, data.edition, Object.freeze(data));
      });
      console.timeEnd('SET_EDITIONS');
    },
    [mutations.SET_MARKET_APPROVAL](state, {account, approved}) {
      state.marketApproval = {
        ...state.marketApproval,
        [account]: approved
      };
    },
  },
  actions: {
    async [actions.GET_MARKET_APPROVAL]({commit, dispatch, state, rootState}) {
      const contract    = rootState.NotRealDigitalAssetV2;
      const tokenMarket = rootState.TokenMarket;
      const account     = rootState.account;
      const smartwallet = rootState.smartwallet;

      //const data = contract.methods.isApprovedForAll(smartwallet.address, tokenMarket._address).encodeABI()
      //const {promiEvent, hash} = await smartwallet.execTransactions([
      //  { to: contract._adddress, data }
      //])

      //console.log('hash', hash)

      const approved = await contract.methods.isApprovedForAll(smartwallet.address, tokenMarket._address).call();

      //console.log('approved', approved)
      commit(mutations.SET_MARKET_APPROVAL, {
        account,
        approved
      });
    },
    async [actions.APPROVE_MARKET]({commit, dispatch, state, rootState}, opts) {
      const tokenMarket = rootState.TokenMarket;
      const nrda        = rootState.NotRealDigitalAssetV2;

      const { account, smartwallet } = rootState;
      const { marketApproval }       = state;

      opts = opts || {}
      // Handle approving both native account and smartwallet
      // (Mostly for artist calling this)
      if(opts.smartwallet == false) {
        const approval = [];
        //if(!marketApproval[smartwallet.address]) {
        //  approval.push(nrda.methods.setApprovalForAll(tokenMarket._address, true).send({smartwallet: true, from: account}))
        //}

        if(!marketApproval[account.address]) {
          approval.push(nrda.methods.setApprovalForAll(tokenMarket._address, true).send({smartwallet: false, from: account}))
        }

        // Hmm... this looks backwards. Keeping here just in case smartwallet: false wase like that for a reason
        //if(!marketApproval[smartwallet.address]) {
        //  approval.push(nrda.methods.setApprovalForAll(tokenMarket._address, true).send({smartwallet: false, from: account}))
        //}

        //if(!marketApproval[account.address]) {
        //  approval.push(nrda.methods.setApprovalForAll(tokenMarket._address, true).send({smartwallet: true, from: account}))
        //}
        return Promise.all(approval).then(receipt => {
          commit(mutations.SET_MARKET_APPROVAL, {
            account,
            approved: true
          })
          //commit(mutations.SET_MARKET_APPROVAL, {
          //  account: smartwallet.address,
          //  approved: true
          //})
        })
      } else {
        if(!state.marketApproval[account]) {
          const approval = nrda.methods.setApprovalForAll(tokenMarket._address, true).send({from: account})
          return approval.on('receipt', receipt => {
            commit(mutations.SET_MARKET_APPROVAL, {
              account,
              approved: true
            })
          })
        }
      }
    },
    async [actions.LOAD_EDITIONS]({commit, dispatch, state, rootState}, editionNumbers) {
      if (_.size(editionNumbers) <= 0) {
        return {};
      }
      const results = await rootState.editionLookupService.getEditions(editionNumbers);
      if (results.success) {
        const {data} = results;
        commit(mutations.SET_EDITIONS, data);
      }
      return results;
    },
    async [actions.LOAD_EDITIONS_FOR_TYPE]({commit, dispatch, state, rootState}, {editionType}) {
      const results = await rootState.editionLookupService.getEditionsForType(editionType);
      if (results.success) {
        const {data} = results;
        commit(mutations.SET_EDITIONS, data);
      }
      return results;
    },
    async [actions.LOAD_EDITIONS_FOR_ARTIST]({commit, dispatch, state, rootState}, {artistAccount, availableForPurchaseOnly, offset, limit, subscribe}) {

      //dispatch(`auction/${actions.GET_AUCTION_DETAILS_FOR_ARTIST}`, {artistAccount, offset, limit}, {root: true});

      const results = await rootState.editionLookupService.getEditionsForArtist(artistAccount, {availableForPurchaseOnly, gql: subscribe});

      //console.log('results', results)
      if (results.success) {


        if (subscribe) {
          const { subscription, transform } = results
          const {editions} = state.subscriptions
          if(editions) { editions.unsubscribe() }

          state.subscriptions.editions = subscription.subscribe({
            next({data}) {
              const gqlEditions = _.keyBy(data.editions, 'id')
              const savedEditions = state.assets
              //console.log('gql', gqlEditions[5200].endDate, savedEditions[5200].endDate)
              const updatedEditions = transform(gqlEditions, savedEditions)
              //console.log('ed update', updatedEditions)
              commit(mutations.SET_EDITIONS, updatedEditions)

              //console.log(result)
              //console.log('edition subscribe', result.data)
              //console.log('subscribe', data)
              //const newEditions = transform(data)
              //const curEditions = _.pick(state.assets, _.map(newEditions, 'edition'))
              //const updatedEditions = _.merge(curEditions, newEditions)
              //commit(mutations.SET_EDITIONS, _.values(updatedEditions));
            },
            error(err) {
              console.error(err)
            }
          })

        }

        const {data} = results;
        commit(mutations.SET_EDITIONS, data);
        return data;
      }
    },
    async [actions.LOAD_EDITIONS_PURCHASED_BY_ACCOUNT]({commit, dispatch, state, rootState}, {account}) {
      if (!account) return;
      const accountEditions = await rootState.accountService.getKodaV2EditionsOwnerByAccount(account);
      commit(mutations.SET_ACCOUNT_EDITIONS, accountEditions);
    },
    async [actions.LOAD_INDIVIDUAL_EDITION]({commit, dispatch, state, rootState}, {editionNumber}) {
      const results = await rootState.editionLookupService.getEdition(editionNumber);
      if (results.success) {
        const {data} = results;
        commit(mutations.SET_EDITION, data);
      }
    },
    async [actions.REFRESH_AND_LOAD_INDIVIDUAL_EDITION]({commit, dispatch, state, rootState}, {editionNumber}) {
      // Response from this refresh contains latest data
      const results = await rootState.editionLookupService.refreshEditionData(editionNumber);
      if (results.success) {
        const {data} = results;
        commit(mutations.SET_EDITION, data);
      }
    }
  }
};

export default contractStateModule;
