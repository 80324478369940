import {contracts} from 'nrda-contract-tools';
import Web3PromiEvent from 'web3-core-promievent';
//import Web3 from 'web3';
import {omit, mapValues} from 'lodash';
const _ = {omit, mapValues};

const getL2 = (id) => {
  switch(id) {
    case 5777:
      return null;
    case 1:
      return {
        network: 137,
        endpoint: contracts.rpcHttpEndpoint(137),
        key: 'PZ_UjuDcI.0fb6431e-5211-4eee-85e0-4971dad8e8ca' 
      }
    case 3:
    case 4:
    case 5:
    case 42:
      return {
        network: 80001,
        endpoint: contracts.rpcHttpEndpoint(80001),
        key: 'Y27clIrHo.c80f54e3-26af-4ba6-be0c-fce54b91df9a'
      }
    default:
      return null;
  }
}

const getL1 = (id) => {
  switch(id) {
    case 5777:
      return null;
    case 137:
      return {
        network: 1,
        endpoint: contracts.rpcHttpEndpoint(1),
      }
    case 80001:
      return {
        network: 5,
        endpoint: contracts.rpcHttpEndpoint(5),
      }
    default:
      return null;
  }
}

const getBiconomy = async (network) => {
  const [{ Biconomy }, {default: Web3}] = await Promise.all([
    import("@biconomy/mexa"),
    import('web3')
  ])

  const l2 = getL2(network)
  if(l2) {
    const provider = new Web3.providers.HttpProvider(l2.endpoint);
    const biconomy = new Biconomy(provider, {apiKey: l2.key, debug: true});
    return new Promise((resolve, reject) => {
      biconomy.onEvent(biconomy.READY, () => {
        console.log('biconomy ready')
        resolve(biconomy)
      }).onEvent(biconomy.ERROR, (error, message) => {
        console.log('biconomy error', error)
        reject(message)
      })
    })
  } else {
    // No layer 2 for this network
    return Promise.resolve(null)
  }
}

const getSignatureParameters = (sig) => {
  const r = '0x' + sig.substring(2, 66)
  const s = '0x' + sig.substring(66, 130)
  const v = parseInt(sig.substring(130, 132), 16)
  return {r, s, v}
}

const domainType = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "verifyingContract", type: "address" },
  { name: "salt", type: "bytes32" },
];

const metaTransactionType = [
  { name: "nonce", type: "uint256" },
  { name: "from", type: "address" },
  { name: "functionSignature", type: "bytes" }
];

const metaTxTypedData = function ({ name, version, salt, verifyingContract, nonce, from, functionSignature }) {
  return {
    types: {
      EIP712Domain: domainType,
      MetaTransaction: metaTransactionType
    },
    domain: {
      name,
      version,
      verifyingContract,
      salt
    },
    primaryType: 'MetaTransaction',
    message: {
      nonce,
      from,
      functionSignature
    }
  }
}

const metaTxSignData = async (functionSignature, {state, contract}) => {
  const nonce = await contract.methods.getNonce(state.account).call()
  const chainId = state.currentNetworkId;

  const signData = metaTxTypedData({
    //name: await contract.methods.name().call(),
    name: contract.name,
    version: '1',
    salt: '0x' + chainId.toString(16).padStart(64, '0'),
    verifyingContract: contract._address,
    nonce: parseInt(nonce),
    from: state.account,
    functionSignature
  })

  //console.log('signData', signData)

  return JSON.stringify(signData)
}

const signMetaTx = async (encodedABI, {state, contract}) => {
  const {account, web3} = state;

  const message = await metaTxSignData(encodedABI, {state, contract})
  const sig = await web3.currentProvider.request({
    method: 'eth_signTypedData_v4',
    params: [account, message],
    from: account
  });

  return getSignatureParameters(sig);
}

// Wrapper that signs function call and executes a meta transaction
const sendMetaTx = (encodedABI, {state, contract, opts}) => {
  //const functionSignature = method(...args).encodeABI()
  const promiEvent = Web3PromiEvent()
  const {eventEmitter} = promiEvent;

  signMetaTx(encodedABI, {state, contract}).then(({r, s, v}) => {
    eventEmitter.emit('sign', {r,s,v})

    contract.methods.executeMetaTransaction(state.account, encodedABI, r, s, v)
      .send(opts)
      .on('transactionHash', hash =>            eventEmitter.emit('transactionHash', hash))
      .on('receipt',         receipt =>         eventEmitter.emit('receipt', receipt))
      .on('confirmation',    (conf, receipt) => eventEmitter.emit('confirmation', conf, receipt))
      .on('error',           error =>           eventEmitter.emit('error', error))
      .then(                 receipt =>         promiEvent.resolve(receipt))
      .catch(                error =>           promiEvent.reject(error))
  })

  //const {r, s, v} = await signMetaTx(functionSignature, {state, contract})
  //return contract.methods.executeMetaTransaction(state.account, functionSignature, r, s, v)
  return eventEmitter
}


const depositTokenTx = async ({state, contract, opts}) => {
  const {account, web3} = state;
  const {value} = opts;
  const erc20    = state.Erc20;

  let deposit, approve, balance;

  if (state.currentNetworkId == 5777) {
    balance = await erc20.methods.balanceOf(state.account).call();
    deposit = value;
    approve = value;
    // Deposit directly to ERC20 on local
    // await erc20.methods.deposit().send({value: deposit, from: account});
    await erc20.methods.approve(contract._address, approve).send({from: account});

  } else if([80001, 137].includes(state.currentNetworkId)) {
    // TODO
    // Transfer Ethereum from root chain to child chain gnosis contract with matic.js
  } else if([1,3,4,5].includes(state.currentNetworkId)) {
    // TODO
    // Transfer Ethereum from root chain to child chain gnosis contract with matic.js
    // Possibly might have to relay some tx through biconomy as well
  }

  return { deposit, approve, value, balance }
}


const sendSmartwalletTx = (method, args, {state, contract, opts}) => {
  const { smartwallet } = state;
  const erc20    = state.Erc20L2;
  const promise = Web3PromiEvent()
  const { eventEmitter } = promise

  let txs = [];
  if (opts.value) {
    txs = [
      { to: erc20._address, data: erc20.methods.approve(contract._address, opts.value).encodeABI() },
    ]
    args.push(opts.value)
  }

  txs.push({
    to: contract._address,
    data: method(...args).encodeABI()
  });

  //console.log('smartwallet txs', txs)

  const execTransactions = () => {
    // Shouldn't directly manipulate state like this, but don't want to pass the store all the way through
    // Signing TXs requires a nonce, and only one nonce is valid at a time. So you can't really send simultaneous TXs.
    // We use this to disable UI for sending other TXs while this one is in flight
    state.txInFlight = true
    return smartwallet.execTransactions(txs).then(({promiEvent, hash}) => {
      promiEvent.on('transactionHash', hash =>    eventEmitter.emit('transactionHash', hash))
        .on('receipt',         receipt =>         eventEmitter.emit('receipt', receipt))
        .on('confirmation',    (conf, receipt) => eventEmitter.emit('confirmation', conf, receipt))
        .on('error',           error =>           eventEmitter.emit('error', error))
        .then(                 receipt =>         promise.resolve(receipt))
        .catch(                error =>           promise.reject(error))
        .finally(              () =>              state.txInFlight = false) 
    })
  }

  execTransactions().catch(err => {
    //console.log('error', err)
    if(/409/.test(err.code)) {
      state.relayService.whitelistProxy(smartwallet.address).then(result => {
        execTransactions()
      })
    } else {
      promise.reject(err)
    }
  }).finally(() => state.txInFlight = false) 

  return eventEmitter
}

const metaTx = (method, args, {state, contract}) => {
  //const nonce = await contract.methods.getNonce(state.account).call()
  //console.log('nonce', nonce)
  //
  //const functionSignature = method(...args).encodeABI()
  //const {r, s, v} = await signMetaTx(functionSignature, {state, contract})

  return {
    encodeABI: () => method(...args).encodeABI(),
    send: (opts) => {
      opts = opts || {}
      if (state.smartwallet) {
        // Allow smartwallet override
        if (opts.smartwallet == false) {
          if (opts.l2 == true) {
            const encodedABI = method(...args).encodeABI()
            return sendMetaTx(encodedABI, {state, contract, opts})
          } else {
            return method(...args).send(opts)
          }
        } else {
          // opts.from doesn't actually mean anything here
          // As it's always sent from relayer for metaTxs
          return sendSmartwalletTx(method, args, {state, contract, opts});
        }
      } else if (state.biconomy) {
        // TODO: This branch probably isn't being hit anymore
        const encodedABI = method(...args).encodeABI()
        return sendMetaTx(encodedABI, {state, contract, opts})
      } else {
        // Just a regular function call
        return method(...args).send(opts)
      }
    },
    call: (opts) => method(...args).call(opts),
    estimateGas: (opts) => method(...args).estimateGas(opts)
  }
}

const metaMethod = (method, {state, contract}) => {
  //console.log('metaMethod')
  return (...args) => {
    //return method(...args)
    return metaTx(method, args, {state, contract})
  }
}

const metaContract = (contract, state) => {
  let decoratedMethods = _.omit(contract.methods, ['getNonce', 'name', 'executeMetaTransaction'])
  decoratedMethods = _.mapValues(decoratedMethods, m => metaMethod(m, {state, contract}))

  contract.methods = {
    ...contract.methods,
    ...decoratedMethods
  }

  return contract
}

export {
  getL2,
  getL1,
  getBiconomy,
  metaContract
};
