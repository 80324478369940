const loadContracts = (build) => {

  // const kodaV1 = require('./abi/koda-marketplace-v1.json');
  // const kodaV2 = require('./abi/koda-marketplace-v2.json');
  // const auctionV1 = require('./abi/artist-accepting-bids-v1.json');
  // const auctionV2 = require('./abi/artist-accepting-bids-v2.json');
  // const artistControlsV1 = require('./abi/artist-controls-v1');
  // const artistControlsV2 = require('./abi/artist-controls-v2');
  // const selfServiceMinterV1 = require('./abi/self-service-minter-v1');
  // const selfServiceMinterV2 = require('./abi/self-service-minter-v2');
  // const selfServiceMinterV3 = require('./abi/self-service-minter-v3');
  // const selfServiceMinterV4 = require('./abi/self-service-minter-v4');
  // const selfServiceAccessControlsV1 = require('./abi/self-service-access-controls-v1');
  //const kodaV1 = require('./contracts/NotRealDigitalAsset.json').abi;
  //const auctionV1 = require('./contracts/ArtistAcceptingBids.json').abi;
  //const artistControlsV1 = require('./contracts/ArtistEditionControls.json').abi;
  //const selfServiceMinterV1 = require('./contracts/SelfServiceEditionCuration.json').abi;
  //const selfServiceMinterV2 = require('./contracts/SelfServiceEditionCurationV2.json').abi;
  //const selfServiceMinterV3 = require('./contracts/SelfServiceEditionCurationV3.json').abi;

  const kodaV2                      = require(`./${build}/abi/NotRealDigitalAssetV2.json`);
  const auctionV2                   = require(`./${build}/abi/ArtistAcceptingBidsV2.json`);
  const tokenMarket                 = require(`./${build}/abi/TokenMarketplaceV2.json`);
  const artistControlsV2            = require(`./${build}/abi/ArtistEditionControlsV2.json`);
  const selfServiceMinterV4         = require(`./${build}/abi/SelfServiceEditionCurationV4.json`);
  const selfServiceAccessControlsV1 = require(`./${build}/abi/SelfServiceAccessControls.json`);
  const weth                        = require(`./abi/WETH.json`);

  // Matic
  const rootChainManager = require('./abi/RootChainManager.json');
  const rootChain = require('./abi/RootChain.json');

  // Gnosis
  const gnosisSafe = require('./abi/GnosisSafe.json');
  const gnosisSafeProxyFactory = require('./abi/GnosisSafeProxyFactory.json');

  const loadContractAccess = require('./access/contracts');
  const contracts = loadContractAccess(build)

  /**
   * Get the contract configs for the given network and address
   *
   * @param network
   * @param address
   * @return {{address: string, abi: array, truffle: object, network: string, deploymentBlock: number}}
   */
  const getContractForNetworkAndAddress = (network, address) => {

    //////////
    // KODA //
    //////////

    if (contracts.getKodaV2Address(network) === address) {
      return {
        abi: kodaV2,
        deploymentBlock: contracts.getKodaV2DeploymentBlock(network),
        network: contracts.getNetwork(network),
        address,
      };
    }

    //if (contracts.getKodaV1Address(network) === address) {
    //  return {
    //    abi: kodaV1,
    //    deploymentBlock: contracts.getKodaV1DeploymentBlock(network),
    //    network: contracts.getNetwork(network),
    //    address,
    //  };
    //}

    ////////////////
    //// Controls //
    ////////////////

    //if (contracts.getArtistControlsV1Address(network) === address) {
    //  return {
    //    abi: artistControlsV1,
    //    deploymentBlock: contracts.getArtistsControlsV1DeploymentBlock(network),
    //    network: contracts.getNetwork(network),
    //    address,
    //  };
    //}

    if (contracts.getArtistControlsV2Address(network) === address) {
      return {
        abi: artistControlsV2,
        deploymentBlock: contracts.getArtistsControlsV2DeploymentBlock(network),
        network: contracts.getNetwork(network),
        address,
      };
    }

    //////////////
    // Auctions //
    //////////////

    //if (contracts.getAuctionV1Address(network) === address) {
    //  return {
    //    abi: auctionV1,
    //    deploymentBlock: contracts.getAuctionV1DeploymentBlock(network),
    //    network: contracts.getNetwork(network),
    //    address,
    //  };
    //}

    if (contracts.getAuctionV2Address(network) === address) {
      return {
        abi: auctionV2,
        deploymentBlock: contracts.getAuctionV2DeploymentBlock(network),
        network: contracts.getNetwork(network),
        address,
      };
    }

    if (contracts.getTokenMarketAddress(network) === address) {
      return {
        abi: tokenMarket,
        deploymentBlock: contracts.getTokenMarketDeploymentBlock(network),
        network: contracts.getNetwork(network),
        address,
      };
    }

    //////////////////
    // Self Service //
    //////////////////

    //if (contracts.getSelfServiceMinterV1Address(network) === address) {
    //  return {
    //    abi: selfServiceMinterV1,
    //    deploymentBlock: contracts.getSelfServiceMinterV1DeploymentBlock(network),
    //    network: contracts.getNetwork(network),
    //    address,
    //  };
    //}

    //if (contracts.getSelfServiceMinterV2Address(network) === address) {
    //  return {
    //    abi: selfServiceMinterV2,
    //    deploymentBlock: contracts.getSelfServiceMinterV2DeploymentBlock(network),
    //    network: contracts.getNetwork(network),
    //    address,
    //  };
    //}

    //if (contracts.getSelfServiceMinterV3Address(network) === address) {
    //  return {
    //    abi: selfServiceMinterV3,
    //    deploymentBlock: contracts.getSelfServiceMinterV3DeploymentBlock(network),
    //    network: contracts.getNetwork(network),
    //    address,
    //  };
    //}

    if (contracts.getSelfServiceMinterV4Address(network) === address) {
      return {
        abi: selfServiceMinterV4,
        deploymentBlock: contracts.getSelfServiceMinterV4DeploymentBlock(network),
        network: contracts.getNetwork(network),
        address,
      };
    }

    if (contracts.getSelfServiceAccessControlsV1Address(network) === address) {
      return {
        abi: selfServiceAccessControlsV1,
        deploymentBlock: contracts.getSelfServiceAccessControlsV1DeploymentBlock(network),
        network: contracts.getNetwork(network),
        address,
      };
    }

    throw new Error(`Unknown address [${address}] for network [${network}]`);
  };

  const contractConfig = {
    abi: {
      //kodaV1: kodaV1,
      kodaV2: kodaV2,
      //auctionV1: auctionV1,
      auctionV2: auctionV2,
      tokenMarket: tokenMarket,
      //artistControlsV1: artistControlsV1,
      artistControlsV2: artistControlsV2,
      //selfServiceMinterV1: selfServiceMinterV1,
      //selfServiceMinterV2: selfServiceMinterV2,
      //selfServiceMinterV3: selfServiceMinterV3,
      selfServiceMinterV4: selfServiceMinterV4,
      selfServiceAccessControlsV1: selfServiceAccessControlsV1,
      gnosisSafe: gnosisSafe,
      gnosisSafeProxyFactory: gnosisSafeProxyFactory,
      weth: weth,
      rootChain: rootChain,
      rootChainManager: rootChainManager
    },
    contracts,
    getContractForNetworkAndAddress
  };

  return contractConfig
}

let buildFunctor = (buildName) => {
  const build = loadContracts(buildName)
  for(const k in build) {
    buildFunctor[k] = build[k]
  }
  return buildFunctor
}

module.exports = buildFunctor 

//const defaultBuild = loadContracts('build/contracts')
//
//module.exports = {
//  ...defaultBuild,
//  loadContracts
//}
