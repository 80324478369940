import * as actions from '../actions';
import * as mutations from '../mutation';
import {get, reduce, size, isArray, forEach}  from 'lodash';
const _ = {get, reduce, size, isArray, forEach};
import {fromWei, toWei} from 'web3-utils';

const auctionStateModule = {
  namespaced: true,
  state: {
    owner: null,
    contractAddress: null,
    contractBalance: null,
    ethPlaced: null,
    ethAccepted: null,
    bidsAccepted: null,

    subscriptions: {},
    auction: {},
    bidState: {},
    acceptingBidState: {},
    withdrawingBidState: {},
    rejectingBidState: {},
    minBidAmount: 0.0001,
    minBidAmountWei: 100000000000000,
    overtimeIncr: 5,
    //artistAddress: '0x947D8644fcC2b4186508430535604153081A4f7a',
    artistAddress: "0x813443E3b997429dfE274F32b79E7659f0ADd1C7",
    //artistAddress: "0xDD967cB643DA610850b79EFA98b00AC0C8984Dea",
    events: {}
  },
  getters: {
    isEditionAuctionEnabled: (state, getters) => (edition) => {
      return _.get(state.auction[edition], 'enabled') === true;
    },
    // Extra complication of ownership due to smartwallet or native wallet possibly being owner
    // Only the smartwallet should ever be the owner, but if somehow it gets into a weird state,
    // we should allow the user to manage things from the native wallet
    accountIsHighestBidderOld: (state, getters, rootState) => (edition) => {
      const { smartwallet, account } = rootState;
      if(!smartwallet || !account) { return false }

      const editionId = edition.edition;
      let currentHighestBidder = _.get(state.auction[editionId], 'highestBidder', 0);
      if (!currentHighestBidder) {
        return false;
      }

      return RegExp(`${account}|${smartwallet.address}`, 'i').test(currentHighestBidder)
    },
    accountIsHighestBidder: (state, getters, rootState) => (edition) => {
      const { smartwallet, account } = rootState;
      if(!smartwallet || !account) { return false }

      const editionId = edition.edition;
      let currentHighestBidder = _.get(edition.auction, 'highestBidder', 0);
      if (!currentHighestBidder) {
        return false;
      }

      return RegExp(`${account}|${smartwallet.address}`, 'i').test(currentHighestBidder)
    },
    accountIsOwnerOld: (state, getters, rootState) => (edition) => {
      const { smartwallet, account } = rootState;
      if(!smartwallet || !account) { return false }

      //let currentOwner = _.get(edition, 'tokens[0].owner', null)
      const auctionDetails = state.auction[edition.edition] || {}
      const currentOwner  = auctionDetails.owner
      if (!currentOwner) { return false }

      return RegExp(`${account}|${smartwallet.address}`, 'i').test(currentOwner)
    },
    accountIsOwner: (state, getters, rootState) => (edition) => {
      const { smartwallet, account } = rootState;
      if(!smartwallet || !account) { return false }

      //let currentOwner = _.get(edition, 'tokens[0].owner', null)
      //const auctionDetails = state.auction[edition.edition] || {}
      const auctionDetails = edition.auction || {}
      const currentOwner  = auctionDetails.owner
      if (!currentOwner) { return false }

      return RegExp(`${account}|${smartwallet.address}`, 'i').test(currentOwner)
    },
    artistIsOwner: (state, getters, rootState) => (edition) => {
      if (!edition || !edition.artistAccount) {
        return false;
      }
      //let currentOwner = _.get(edition, 'tokens[0].owner', null)
      const auctionDetails = edition.auction || {}
      const currentOwner   = auctionDetails.owner

      if (!currentOwner) { return false }

      return RegExp(currentOwner, 'i').test(edition.artistAccount)
    },

    artistIsOwnerOld: (state, getters, rootState) => (edition) => {
      if (!rootState.account || !edition || !edition.artistAccount) {
        return false;
      }
      //let currentOwner = _.get(edition, 'tokens[0].owner', null)
      const auctionDetails = state.auction[edition.edition] || {}
      const currentOwner   = auctionDetails.owner

      if (!currentOwner) { return false }

      return RegExp(currentOwner, 'i').test(edition.artistAccount)
    },
    //////////////////////////
    // Make Offer Bid State //
    //////////////////////////
    editionAuctionState: (state) => (editionNumber) => {
      return _.get(state.bidState, editionNumber);
    },
    isAuctionTriggered: (state, getters) => (editionNumber) => {
      return _.get(getters.editionAuctionState(editionNumber), 'state') === mutations.AUCTION_TRIGGERED;
    },
    isAuctionStarted: (state, getters) => (editionNumber) => {
      return _.get(getters.editionAuctionState(editionNumber), 'state') === mutations.AUCTION_STARTED;
    },
    isAuctionSuccessful: (state, getters) => (editionNumber) => {
      return _.get(getters.editionAuctionState(editionNumber), 'state') === mutations.AUCTION_PLACED_SUCCESSFUL;
    },
    isAuctionFailed: (state, getters) => (editionNumber) => {
      return _.get(getters.editionAuctionState(editionNumber), 'state') === mutations.AUCTION_FAILED;
    },
    getTransactionForEdition: (state, getters) => (editionNumber) => {
      return getters.editionAuctionState(editionNumber).transaction;
    },
    /////////////////////////
    // Accepting Bid State //
    /////////////////////////
    editionAcceptingBidState: (state) => (editionNumber) => {
      return _.get(state.acceptingBidState, editionNumber);
    },
    isAcceptingBidTriggered: (state, getters) => (editionNumber) => {
      return _.get(getters.editionAcceptingBidState(editionNumber), 'state') === mutations.BID_ACCEPTED_TRIGGERED;
    },
    isAcceptingBidStarted: (state, getters) => (editionNumber) => {
      return _.get(getters.editionAcceptingBidState(editionNumber), 'state') === mutations.BID_ACCEPTED_STARTED;
    },
    isAcceptingBidSuccessful: (state, getters) => (editionNumber) => {
      return _.get(getters.editionAcceptingBidState(editionNumber), 'state') === mutations.BID_ACCEPTED_SUCCESSFUL;
    },
    isAcceptingBidFailed: (state, getters) => (editionNumber) => {
      return _.get(getters.editionAcceptingBidState(editionNumber), 'state') === mutations.BID_ACCEPTED_FAILED;
    },
    getAcceptingBidTransactionForEdition: (state, getters) => (editionNumber) => {
      return getters.editionAcceptingBidState(editionNumber).transaction;
    },
    ///////////////////////////
    // Withdrawing Bid State //
    ///////////////////////////
    editionWithdrawnBidState: (state) => (editionNumber) => {
      return _.get(state.withdrawingBidState, editionNumber);
    },
    isWithdrawnBidTriggered: (state, getters) => (editionNumber) => {
      return _.get(getters.editionWithdrawnBidState(editionNumber), 'state') === mutations.BID_WITHDRAWN_TRIGGERED;
    },
    isWithdrawnBidStarted: (state, getters) => (editionNumber) => {
      return _.get(getters.editionWithdrawnBidState(editionNumber), 'state') === mutations.BID_WITHDRAWN_STARTED;
    },
    isWithdrawnBidSuccessful: (state, getters) => (editionNumber) => {
      return _.get(getters.editionWithdrawnBidState(editionNumber), 'state') === mutations.BID_WITHDRAWN_SUCCESSFUL;
    },
    isWithdrawnBidFailed: (state, getters) => (editionNumber) => {
      return _.get(getters.editionWithdrawnBidState(editionNumber), 'state') === mutations.BID_WITHDRAWN_FAILED;
    },
    getWithdrawnBidTransactionForEdition: (state, getters) => (editionNumber) => {
      return getters.editionWithdrawnBidState(editionNumber).transaction;
    },
    ////////////////////////
    // Rejected Bid State //
    ////////////////////////
    editionRejectedBidState: (state) => (editionNumber) => {
      return _.get(state.rejectingBidState, editionNumber);
    },
    isRejectedBidTriggered: (state, getters) => (editionNumber) => {
      return _.get(getters.editionRejectedBidState(editionNumber), 'state') === mutations.BID_REJECTED_TRIGGERED;
    },
    isRejectedBidStarted: (state, getters) => (editionNumber) => {
      return _.get(getters.editionRejectedBidState(editionNumber), 'state') === mutations.BID_REJECTED_STARTED;
    },
    isRejectedBidSuccessful: (state, getters) => (editionNumber) => {
      return _.get(getters.editionRejectedBidState(editionNumber), 'state') === mutations.BID_REJECTED_SUCCESSFUL;
    },
    isRejectedBidFailed: (state, getters) => (editionNumber) => {
      return _.get(getters.editionRejectedBidState(editionNumber), 'state') === mutations.BID_REJECTED_FAILED;
    },
    getRejectedBidTransactionForEdition: (state, getters) => (editionNumber) => {
      return getters.editionRejectedBidState(editionNumber).transaction;
    },
  },
  mutations: {
    [mutations.SET_AUCTION_ADDRESS](state, {address}) {
      state.contractAddress = address;
    },
    [mutations.SET_AUCTION_STATS](state, {ethPlaced, contractBalance, ethAccepted, bidsAccepted}) {
      state.ethPlaced = ethPlaced;
      state.ethAccepted = ethAccepted;
      state.bidsAccepted = bidsAccepted;
      state.contractBalance = contractBalance;
    },
    [mutations.SET_AUCTION_OWNER](state, {owner, address}) {
      state.owner = owner;
      state.contractAddress = address;
    },
    [mutations.SET_AUCTION_DETAILS](state, data) {
      state.auction = {
        ...state.auction,
        ...data
      };
    },
    [mutations.SET_AUCTION_EVENTS](state, events) {
      // Append new auction events
      //state.events = _.mergeWith(state.events, events, function(a, b) {
      //  return b.concat(a || []);
      //});

      //console.log(state.events)

      _.forEach(events, (evts, evtType) => {
        state.events[evtType] = evts
      })
    },
    [mutations.ADD_AUCTION_EVENTS](state, events) {
      // Most recent events go first
      _.forEach(events, (evts, evtType) => {
        state.events[evtType] = evts.concat(state.events[evtType])
        //state.events[evtType] = evts
      })
    },
    [mutations.SET_MINIMUM_BID](state, {minBidAmount, overtimeIncr}) {
      state.minBidAmount = fromWei(minBidAmount.toString(10), 'ether');
      state.minBidAmountWei = minBidAmount;
      state.overtimeIncr = overtimeIncr
    },
    //////////////////////////
    // Make Offer Bid State //
    //////////////////////////
    [mutations.RESET_BID_STATE](state, {edition}) {
      delete state.bidState[edition];
      state.bidState = {...state.bidState};
    },
    [mutations.AUCTION_TRIGGERED](state, {edition, account}) {
      state.bidState = {
        ...state.bidState,
        [edition]: {
          edition, account, state: 'AUCTION_TRIGGERED'
        }
      };
    },
    [mutations.AUCTION_FAILED](state, {edition, account}) {
      state.bidState = {
        ...state.bidState,
        [edition]: {
          edition, account, state: 'AUCTION_FAILED'
        }
      };
    },
    [mutations.AUCTION_PLACED_SUCCESSFUL](state, {edition, account}) {
      state.bidState = {
        ...state.bidState,
        [edition]: {
          edition,
          account,
          transaction: state.bidState[edition].transaction,
          state: 'AUCTION_PLACED_SUCCESSFUL'
        }
      };
    },
    [mutations.AUCTION_STARTED](state, {edition, account, transaction}) {
      // Guard against the timed account check beating the event callbacks
      if (state.bidState[edition].state === 'AUCTION_PLACED_SUCCESSFUL') {
        state.bidState = {
          ...state.bidState,
          [edition]: {
            edition, account, transaction, state: 'AUCTION_PLACED_SUCCESSFUL'
          }
        };
        return;
      }

      state.bidState = {
        ...state.bidState,
        [edition]: {
          edition, account, transaction, state: 'AUCTION_STARTED'
        }
      };
    },
    /////////////////////////
    // Accepting Bid State //
    /////////////////////////
    [mutations.RESET_BID_ACCEPTED_STATE](state, {auction}) {
      delete state.acceptingBidState[auction.edition];
      state.acceptingBidState = {...state.acceptingBidState};
    },
    [mutations.BID_ACCEPTED_FAILED](state, {auction, account}) {
      state.acceptingBidState = {
        ...state.acceptingBidState,
        [auction.edition]: {
          ...auction,
          account,
          transaction: state.acceptingBidState[auction.edition].transaction,
          state: 'BID_ACCEPTED_FAILED'
        }
      };
    },
    [mutations.BID_ACCEPTED_STARTED](state, {auction, account, transaction}) {
      state.acceptingBidState = {
        ...state.acceptingBidState,
        [auction.edition]: {
          ...auction,
          account,
          transaction,
          state: 'BID_ACCEPTED_STARTED'
        }
      };
    },
    [mutations.BID_ACCEPTED_SUCCESSFUL](state, {auction, account}) {
      state.acceptingBidState = {
        ...state.acceptingBidState,
        [auction.edition]: {
          ...auction,
          account,
          transaction: state.acceptingBidState[auction.edition].transaction,
          state: 'BID_ACCEPTED_SUCCESSFUL'
        }
      };
    },
    [mutations.BID_ACCEPTED_TRIGGERED](state, {auction, account}) {
      state.acceptingBidState = {
        ...state.acceptingBidState,
        [auction.edition]: {
          ...auction,
          account,
          state: 'BID_ACCEPTED_TRIGGERED'
        }
      };
    },
    ///////////////////////////
    // Withdrawing Bid State //
    ///////////////////////////
    [mutations.RESET_BID_WITHDRAWN_STATE](state, {auction}) {
      delete state.withdrawingBidState[auction.edition];
      state.withdrawingBidState = {...state.acceptingBidState};
    },
    [mutations.BID_WITHDRAWN_FAILED](state, {auction, account}) {
      state.withdrawingBidState = {
        ...state.withdrawingBidState,
        [auction.edition]: {
          ...auction,
          account,
          transaction: state.withdrawingBidState[auction.edition].transaction,
          state: 'BID_WITHDRAWN_FAILED'
        }
      };
    },
    [mutations.BID_WITHDRAWN_STARTED](state, {auction, account, transaction}) {
      state.withdrawingBidState = {
        ...state.withdrawingBidState,
        [auction.edition]: {
          ...auction,
          account,
          transaction,
          state: 'BID_WITHDRAWN_STARTED'
        }
      };
    },
    [mutations.BID_WITHDRAWN_SUCCESSFUL](state, {auction, account}) {
      state.withdrawingBidState = {
        ...state.withdrawingBidState,
        [auction.edition]: {
          ...auction,
          account,
          transaction: state.withdrawingBidState[auction.edition].transaction,
          state: 'BID_WITHDRAWN_SUCCESSFUL'
        }
      };
    },
    [mutations.BID_WITHDRAWN_TRIGGERED](state, {auction, account}) {
      state.withdrawingBidState = {
        ...state.withdrawingBidState,
        [auction.edition]: {
          ...auction,
          account,
          state: 'BID_WITHDRAWN_TRIGGERED'
        }
      };
    },
    ////////////////////////
    // Rejected Bid State //
    ////////////////////////
    [mutations.RESET_BID_REJECTED_STATE](state, {auction}) {
      delete state.rejectingBidState[auction.edition];
      state.rejectingBidState = {...state.acceptingBidState};
    },
    [mutations.BID_REJECTED_FAILED](state, {auction, account}) {
      state.rejectingBidState = {
        ...state.rejectingBidState,
        [auction.edition]: {
          ...auction,
          account,
          transaction: state.rejectingBidState[auction.edition].transaction,
          state: 'BID_REJECTED_FAILED'
        }
      };
    },
    [mutations.BID_REJECTED_STARTED](state, {auction, account, transaction}) {
      state.rejectingBidState = {
        ...state.rejectingBidState,
        [auction.edition]: {
          ...auction,
          account,
          transaction,
          state: 'BID_REJECTED_STARTED'
        }
      };
    },
    [mutations.BID_REJECTED_SUCCESSFUL](state, {auction, account}) {
      state.rejectingBidState = {
        ...state.rejectingBidState,
        [auction.edition]: {
          ...auction,
          account,
          transaction: state.rejectingBidState[auction.edition].transaction,
          state: 'BID_REJECTED_SUCCESSFUL'
        }
      };
    },
    [mutations.BID_REJECTED_TRIGGERED](state, {auction, account}) {
      state.rejectingBidState = {
        ...state.rejectingBidState,
        [auction.edition]: {
          ...auction,
          account,
          state: 'BID_REJECTED_TRIGGERED'
        }
      };
    },
  },
  actions: {
    async [actions.REFRESH_OPEN_OFFERS]() {
      // Dumb action which doesnt do anything, used to listen for change in open offers page
    },
    async [actions.GET_AUCTION_OWNER]({commit, state, getters, rootState}) {
      const contract = rootState.TokenMarket;
      const owner = await contract.methods.owner().call();
      commit(mutations.SET_AUCTION_OWNER, {owner, address: contract._address});
    },
    async [actions.GET_AUCTION_MIN_BID]({commit, state, getters, rootState}) {
      if (rootState.TokenMarket) {
        const contract = rootState.TokenMarket;
        const minBidAmount = await contract.methods.minBidAmount().call();
        const overtimeIncr = await contract.methods.overtimeIncr().call();

        commit(mutations.SET_MINIMUM_BID, {minBidAmount, overtimeIncr});
      }
    },
    async [actions.GET_AUCTION_DETAILS]({commit, dispatch, state, getters, rootState}, edition) {
      const results = await rootState.auctionsService.getAuctionsForEdition(edition);
      if (results.success) {
        const {data} = results;
        commit(mutations.SET_AUCTION_DETAILS, {
          [edition]: data
        });
      }
    },
    async [actions.GET_AUCTION_DETAILS_FOR_EDITIONS]({commit, dispatch, state, getters, rootState}, args) {
      let editionNumbers, subscribe 
      if (_.isArray(args)) {
        editionNumbers = args
      } else {
        ({editionNumbers, subscribe} = args)
      }

      if (_.size(editionNumbers) <= 0) {
        return {};
      }

      const mutation = (data) => {

          const editionData = _.reduce(data, (result, data) => {
            data.tokenId = data.token
            //data.events = bidAcceptedEvents;
            result[data.edition] = data;
            return result;
          }, {});
          commit(mutations.SET_AUCTION_DETAILS, editionData);
      }

      const results = await rootState.auctionsService.getAuctionsForEditions(editionNumbers, {gql: subscribe});
      //console.log('auction details', results)

      if (results.success) {
        const {data} = results;
        mutation(data)

        if(subscribe) {
          const { subscription, transform } = results

          const {auction} = state.subscriptions
          if(auction) { auction.unsubscribe() }

          state.subscriptions.auction = subscription.subscribe({
            next({data}) {
              console.log('subscribe auction', data)
              mutation(transform(data))
            },
            error(err) {
              console.error(err)
            }
          })
        }
      }

      //if (results.subscription) {
      //  results.subscription.subscribe({
      //    next(data) {
      //      console.log('new auction subscribe', data)
      //    },
      //    error(err) {
      //      console.log('subscribe err', err)
      //    }
      //  })
      //}

      // UPDATE: Got graph protocol working again! So we don't need
      // to track auction events manually anymore thank god.
      //const bidAcceptedEvents = await rootState.auctionsService.getEventsForEditions(editionNumbers, 'BidAccepted');
      //
      //console.log('server bidAcceptedEvents', bidAcceptedEvents)
      //

      //if (results.success) {
      //  const {data} = results;
      //  const editionData = _.reduce(data, (result, data) => {
      //    data.tokenId = data.token
      //    //data.events = bidAcceptedEvents;
      //    result[data.edition] = data;
      //    return result;
      //  }, {});
      //  commit(mutations.SET_AUCTION_DETAILS, editionData);
      //  //commit(mutations.SET_AUCTION_EVENTS, {bidAcceptedEvents});
      //}
    },
    async [actions.GET_AUCTION_DETAILS_FOR_ARTIST]({commit, state, getters, rootState}, {artistAccount, offset, limit}) {
      const results = await rootState.auctionsService.getAuctionsForArtist(artistAccount, offset, limit);
      if (results.success) {
        const {data} = results;
        const editionData = _.reduce(data, (result, data) => {
          result[data.edition] = data;
          return result;
        }, {});
        commit(mutations.SET_AUCTION_DETAILS, editionData);
      }
    },
    async [actions.REFRESH_AUCTION_DETAILS]({commit, dispatch, state, getters, rootState}, edition) {
      const results = await rootState.auctionsService.refreshAuctionsForEdition(edition);
      return results
    },
    async [actions.PLACE_BID]({commit, dispatch, state, getters, rootState}, {edition, value, token}) {
      commit(mutations.RESET_BID_STATE, {edition});

      const account  = rootState.account;
      const contract = rootState.TokenMarket;
      const erc20    = rootState.Erc20;

      await dispatch(`kodaV2/${actions.APPROVE_MARKET}`, null, {root: true});

      console.log('auction', state.auction[edition])

      commit(mutations.AUCTION_TRIGGERED, {edition, account});

      const timer = setInterval(function () {
        dispatch(actions.GET_AUCTION_DETAILS, edition);
      }, 2000);

      const weiValue = toWei(value, 'ether');

      // Approve transfer
      //await erc20.methods.deposit().send({value: weiValue, from: account});
      //await erc20.methods.approve(contract._address, weiValue).send({from: account});

      contract.methods.placeBid(token)
        .send({
          value: weiValue,
          from: account,
        })
        .on('transactionHash', hash => {
          console.log('Auction - place bid - transaction submitted', hash);
          rootState.notifier.hash(hash);
          commit(mutations.AUCTION_STARTED, {edition, account, transaction: hash});
        })
        .on('receipt', receipt => {
          console.log('Auction - place bid - successful', receipt);
          dispatch(actions.GET_AUCTION_DETAILS, edition);
          commit(mutations.AUCTION_PLACED_SUCCESSFUL, {edition, account});
          dispatch(actions.GET_BALANCE, account, {root: true});
        })
        .on('error', error => {
          console.log('Auction - place bid - rejection/error', error);
          commit(mutations.AUCTION_FAILED, {edition, account});
          dispatch(actions.GET_AUCTION_DETAILS, edition);
        })
        .catch((error) => {
          console.log('Auction - place bid - rejection/error', error);
          commit(mutations.AUCTION_FAILED, {edition, account});
          dispatch(actions.GET_AUCTION_DETAILS, edition);
        })
        .finally(() => {
          if (timer) clearInterval(timer);
          dispatch(actions.REFRESH_OPEN_OFFERS);
          dispatch(`kodaV2/${actions.REFRESH_AND_LOAD_INDIVIDUAL_EDITION}`, {editionNumber: edition}, {root: true});
        });
    },
    async [actions.INCREASE_BID]({commit, dispatch, state, getters, rootState}, {edition, value}) {
      commit(mutations.RESET_BID_STATE, {edition});

      const account = rootState.account;
      const contract = rootState.TokenMarket;

      dispatch(`kodaV2/${actions.APPROVE_MARKET}`, null , {root: true});

      commit(mutations.AUCTION_TRIGGERED, {edition, account});

      const timer = setInterval(function () {
        dispatch(actions.GET_AUCTION_DETAILS, edition);
      }, 2000);

      const weiValue = toWei(value, 'ether');

      contract.methods.increaseBid(edition)
        .send({
          from: account,
          value: weiValue
        })
        .on('transactionHash', hash => {
          console.log('Auction - increase bid - transaction submitted', hash);
          rootState.notifier.hash(hash);
          commit(mutations.AUCTION_STARTED, {edition, account, transaction: hash});
        })
        .on('receipt', receipt => {
          console.log('Auction - increase bid - receipt', receipt);
          dispatch(actions.GET_AUCTION_DETAILS, edition);
          commit(mutations.AUCTION_PLACED_SUCCESSFUL, {edition, account});
        })
        .on('error', error => {
          console.log('Auction - increase bid - rejection/error', error);
          commit(mutations.AUCTION_FAILED, {edition, account});
          dispatch(actions.GET_AUCTION_DETAILS, edition);
        })
        .catch((error) => {
          console.log('Auction - increase bid - rejection/error', error);
          commit(mutations.AUCTION_FAILED, {edition, account});
          dispatch(actions.GET_AUCTION_DETAILS, edition);
        })
        .finally(() => {
          if (timer) clearInterval(timer);
          dispatch(actions.REFRESH_OPEN_OFFERS);
          dispatch(`kodaV2/${actions.REFRESH_AND_LOAD_INDIVIDUAL_EDITION}`, {editionNumber: edition}, {root: true});
        });
    },
    async [actions.ACCEPT_BID]({commit, dispatch, state, getters, rootState}, auction) {
      commit(mutations.RESET_BID_ACCEPTED_STATE, {auction});

      const account = rootState.account;
      const contract = rootState.TokenMarket;
      const smartwallet = rootState.smartwallet;

      const opts = {from: account}
      // Passing in an owner
      const acceptByOwner = auction.owner && RegExp(auction.owner, 'i').test(account)
      if (acceptByOwner) {
        // This is to cover 
        // 1. the edge case that somehow
        // the native wallet became the token owner
        // In theory this shouldn't happen
        // 2. The artist is calling accept bid in order to transfer to winner's wallet 
        //
        // In either of those cases, we also need approval for the native wallet
        opts.smartwallet = false
      }

      await dispatch(`kodaV2/${actions.APPROVE_MARKET}`, opts, {root: true});

      commit(mutations.BID_ACCEPTED_TRIGGERED, {auction, account});

      // We need to save the current auction info so we can show the previous high bidder
      // Otherwise this info will be wiped from contract and we won't have access
      // without a more complicated parsing of contract event information
      await dispatch(actions.REFRESH_AUCTION_DETAILS, auction.edition);


      const timer = setInterval(function () {
        dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
      }, 2000);

      // TEMP increasing the value higher just to ensure it goes through
      //const weiValue = Web3.utils.toWei((parseFloat(auction.bidInEther)*2).toString(), 'ether');

      //console.log('tokenmarket', contract._address)
      return contract.methods.acceptBid(auction.token, auction.highestBidWei)
        .send(opts)
        .on('transactionHash', hash => {
          console.log('Auction - accepted bid - transaction submitted', hash);
          rootState.notifier.hash(hash);
          commit(mutations.BID_ACCEPTED_STARTED, {auction, account, transaction: hash});
        })
        .on('receipt', receipt => {
          console.log('Auction - accepted bid - receipt', receipt);
          dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
          commit(mutations.BID_ACCEPTED_SUCCESSFUL, {auction, account});
        })
        .on('error', error => {
          console.log('Auction - accepted bid - rejection/error', error);
          commit(mutations.BID_ACCEPTED_FAILED, {auction, account});
          dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
        })
        .catch((error) => {
          console.log('Auction - accepted bid - rejection/error', error);
          commit(mutations.BID_ACCEPTED_FAILED, {auction, account});
          dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
        })
        .finally(() => {
          if (timer) clearInterval(timer);
          dispatch(actions.REFRESH_OPEN_OFFERS);
          dispatch(`kodaV2/${actions.REFRESH_AND_LOAD_INDIVIDUAL_EDITION}`, {editionNumber: auction.edition}, {root: true});
        });
    },
    async [actions.WITHDRAW_BID]({commit, dispatch, state, getters, rootState}, auction) {
      commit(mutations.RESET_BID_WITHDRAWN_STATE, {auction});

      const account = rootState.account;

      const contract = rootState.TokenMarket;

      commit(mutations.BID_WITHDRAWN_TRIGGERED, {auction, account});

      const timer = setInterval(function () {
        dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
      }, 2000);

      contract
        .methods.withdrawBid(auction.edition)
        .send({
          from: account
        })
        .on('transactionHash', hash => {
          console.log('Auction - withdraw bid - transaction submitted', hash);
          rootState.notifier.hash(hash);
          commit(mutations.BID_WITHDRAWN_STARTED, {auction, account, transaction: hash});
        })
        .on('receipt', receipt => {
          console.log('Auction - withdraw bid - receipt', receipt);
          dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
          commit(mutations.BID_WITHDRAWN_SUCCESSFUL, {auction, account});
        })
        .on('error', error => {
          console.log('Auction - withdraw bid - rejection/error', error);
          dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
          commit(mutations.BID_WITHDRAWN_FAILED, {auction, account});
        })
        .catch((error) => {
          console.log('Auction - withdraw bid - rejection/error', error);
          dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
          commit(mutations.BID_WITHDRAWN_FAILED, {auction, account});
        })
        .finally(() => {
          if (timer) clearInterval(timer);
          dispatch(actions.REFRESH_OPEN_OFFERS);
          dispatch(`kodaV2/${actions.REFRESH_AND_LOAD_INDIVIDUAL_EDITION}`, {editionNumber: auction.edition}, {root: true});
        });
    },
    async [actions.REJECT_BID]({commit, dispatch, state, getters, rootState}, auction) {
      commit(mutations.RESET_BID_REJECTED_STATE, {auction});

      const account = rootState.account;

      const contract = rootState.TokenMarket;

      commit(mutations.BID_REJECTED_TRIGGERED, {auction, account});

      const timer = setInterval(function () {
        dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
      }, 2000);

      contract.methods.rejectBid(auction.edition)
        .send({
          from: account
        })
        .on('transactionHash', hash => {
          console.log('Auction - reject bid - transaction submitted', hash);
          rootState.notifier.hash(hash);
          commit(mutations.BID_REJECTED_STARTED, {auction, account, transaction: hash});
        })
        .on('receipt', receipt => {
          console.log('Auction - reject bid - receipt', receipt);
          dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
          commit(mutations.BID_REJECTED_SUCCESSFUL, {auction, account});
        })
        .on('error', error => {
          console.log('Auction - reject bid - rejection/error', error);
          dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
          commit(mutations.BID_REJECTED_FAILED, {auction, account});
        })
        .catch((error) => {
          console.log('Auction - reject bid - rejection/error', error);
          dispatch(actions.GET_AUCTION_DETAILS, auction.edition);
          commit(mutations.BID_REJECTED_FAILED, {auction, account});
        })
        .finally(() => {
          if (timer) clearInterval(timer);
          dispatch(actions.REFRESH_OPEN_OFFERS);
          dispatch(`kodaV2/${actions.REFRESH_AND_LOAD_INDIVIDUAL_EDITION}`, {editionNumber: auction.edition}, {root: true});
        });
    },
    async [actions.CANCEL_AUCTION]({commit, dispatch, state, getters, rootState}, auction) {
      let contract = rootState.TokenMarket;
      return contract.methods.cancelAuction(auction.edition)
        .send({
          from: rootState.account
        });
    },
  }
};

export default auctionStateModule;
