import {getApi, AXIOS_CONFIG} from '../../utils';
import axios from 'axios';

export default class InfoApiService {

  constructor(currentNetworkId = 1) {
    this.currentNetworkId = currentNetworkId;
    this.api = getApi();
  }

  /**
   * Updates the currentNetworkId, needed for when switching network
   * @param currentNetworkId
   */
  setNetworkId(currentNetworkId) {
    this.currentNetworkId = currentNetworkId;
  }

  async getContractStates() {
    console.log(`Loading contract stats for network [${this.currentNetworkId}]`);
    const results = await axios.get(`${this.api}/info/network/${this.currentNetworkId}/contracts`, AXIOS_CONFIG);
    if (results.data) {
      return results.data;
    }
    return {};
  }

  async getUsdEthPrice() {
    console.log(`Getting USD price`);
    const results = await axios.get(`${this.api}/info/usdprice`, AXIOS_CONFIG);
    return results.data.usdPrice;
  }

}

