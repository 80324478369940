import {getApi, AXIOS_CONFIG} from '../../utils';
import axios from 'axios';

export default class EventsApiService {

  constructor(currentNetworkId = 1) {
    this.currentNetworkId = currentNetworkId;
    this.api = getApi();
  }

  setNetworkId(currentNetworkId) {
    this.currentNetworkId = currentNetworkId;
  }

  async loadEventsActivity(limit = 50, offset = 0, activeFilter = 'everything') {
    console.log(`Load events activity for network [${this.currentNetworkId}] offset [${offset}] limit [${limit}] activeFilter = [${activeFilter}]`);
    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/event/activity?activeFilter=${activeFilter}&limit=${limit}&offset=${offset}`, AXIOS_CONFIG);
    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

  async loadAddressActivity(address) {
    console.log(`Load address activity for network [${this.currentNetworkId}]`);
    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/event/address/${address}`, AXIOS_CONFIG);
    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

  async loadAddressAuctionActivity(address) {
    console.log(`Load address auction activity for network [${this.currentNetworkId}]`);
    let endpoint = `${this.api}/network/${this.currentNetworkId}/event/auction/address/${address}`

    const results = await axios.get(endpoint, AXIOS_CONFIG);
    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }


  async loadPurchaseEventsForEditions(editions = [], showAll = false) {
    console.log(`Load purchase events for editions [${editions}] for network [${this.currentNetworkId}] showAll [${showAll}]`);

    const mappedEditions = editions.map((edition) => `&edition=${edition}`).join('');
    const showAllParam = `show_all=${showAll}`;

    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/event/purchases?${showAllParam}${mappedEditions}`, AXIOS_CONFIG);
    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

  async findBirthTransaction({edition}) {
    const event = await axios.get(`${this.api}/network/${this.currentNetworkId}/event/birth/${edition}`, AXIOS_CONFIG);
    return event.data;
  }

  async findPurchaseTransaction(tokenId) {
    const event = await axios.get(`${this.api}/network/${this.currentNetworkId}/event/purchase/${tokenId}`, AXIOS_CONFIG);
    return event.data;
  }

  async loadAuctionEvents({edition}) {
    console.log(`Load auction events for edition [${edition}] on network [${this.currentNetworkId}]`);
    const event = await axios.get(`${this.api}/network/${this.currentNetworkId}/event/auction/${edition}`, AXIOS_CONFIG);
    return event.data;
  }

  async loadEditionHistoryEvents({edition}) {
    console.log(`Load edition history events for edition [${edition}] on network [${this.currentNetworkId}]`);
    const event = await axios.get(`${this.api}/network/${this.currentNetworkId}/event/edition/history/${edition}`, AXIOS_CONFIG);
    return event.data;
  }

  async loadTokenHistoryEvents({tokenId}) {
    console.log(`Load edition history events for token [${tokenId}] on network [${this.currentNetworkId}]`);
    const event = await axios.get(`${this.api}/network/${this.currentNetworkId}/event/token/history/${tokenId}`, AXIOS_CONFIG);
    return event.data;
  }

}

