<template>
  <header class="header-navbar">
    <b-navbar toggleable="md" class="navbar-position pl-3 pt-1 pr-3 pb-1 z-2">
      <b-navbar-brand :to="{ name: 'home' }">
        <span class="notreal-logo">
          <b>not</b> <br />
          <b>real.</b>
        </span>
      </b-navbar-brand>
      <b-navbar-toggle class="pr-0" target="collapseNav"></b-navbar-toggle>
      <b-collapse is-nav id="collapseNav">
        <b-navbar-nav class="ml-auto">
          <!--<web3-connect-navbar-login></web3-connect-navbar-login>-->
          <router-link :to="{ name: 'gallery' }" class="nav-link">Gallery</router-link>
          <router-link :to="{ name: 'studio' }" class="nav-link">Studio</router-link>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <!--<network-warning-banner></network-warning-banner>-->
  </header>

</template>

<script>
  import NetworkWarningBanner from '../generic/NetworkWarningBanner';
  //import Web3ConnectNavbarLogin from '../auth/Web3ConnectNavBarLogin';
  import { BNavbar, BNavbarBrand, BCollapse, BNavbarNav, BNavbarToggle } from 'bootstrap-vue';

  export default {
    name: 'header-bar',
    components: {
      //Web3ConnectNavbarLogin,
      NetworkWarningBanner,
      ...{ BNavbar, BNavbarBrand, BCollapse, BNavbarNav, BNavbarToggle }
    },
    computed: {},
    /*eslint brace-style: "off"*/
    mounted() {
  } 
};
</script>
