<template>
  <nav v-if="!isOnMainnet"
    class="navbar bg-warning mb-0">
    <div class="text-danger mt-2">To make real purchases please switch to mainnet!</div>
  </nav>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'networkWarningBanner',
    props: [],
    computed: {
      ...mapGetters(['isOnMainnet'])
    },
  };
</script>

<style scoped>
.navbar {
  z-index: 2001;
  position: fixed;
  top: 0;
}
</style>
