import {AXIOS_CONFIG, getApi} from "../../utils";
import axios from "axios";
import {apolloClient} from '../../apollo';
import { AUCTIONS, TOKENS } from '../../gql/queries';
import {fromWei, toChecksumAddress} from 'web3-utils';
import {keyBy} from 'lodash';
const _ = {keyBy}; 


export default class AuctionsApiService {

  constructor(currentNetworkId = 1) {
    this.currentNetworkId = currentNetworkId;
    this.api = getApi();
  }

  /**
   * Updates the currentNetworkId, needed for when switching network
   * @param currentNetworkId
   */
  setNetworkId(currentNetworkId) {
    this.currentNetworkId = currentNetworkId;
  }

  async getOpenAuctions() {
    console.log(`Getting open auctions on network [${this.currentNetworkId}]`);

    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/auction/open`, AXIOS_CONFIG);
    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

  async getAuctionsForArtist(artistAddress, offset, limit) {
    const page = `offset=${offset|| '' }&limit=${limit || ''}`
    console.log(`Getting auction details for artists [${artistAddress}] on network [${this.currentNetworkId}] page:[${page}]`);

    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/auction/artist/${artistAddress}?${page}`, AXIOS_CONFIG);
    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

  async getAuctionsForEditions(editions = [], {gql} = {}) {
    console.log(`Getting auction details for editions [${editions}] on network [${this.currentNetworkId}]`);
    if(gql) {

      //console.log(AUCTIONS())
      //console.log('editions', editions)
      const {data} = await apolloClient.query({
        query: TOKENS(),
        variables: {
          editionIds: editions.map(e => e.toString())
        }
      })

      //console.log('data query', data)

      const subscription = apolloClient.subscribe({
        query: TOKENS('subscription'),
        variables: {
          editionIds: editions.map(e => e.toString())
        }
      })


      const transform = (data) => {
        //console.log('transform', data)
        // Map graphql query to existing structure for code compatibility
        return data.tokens.map(t => {
          const { openOffer } = t
          //console.log(o.weiValue)
          return {
            edition: t.edition.id,
            token:   t.id,
            enabled: true,
            controller:    toChecksumAddress(t.currentOwner.address),
            value:         openOffer && openOffer.weiValue.toString(10),
            highestBidder: openOffer && toChecksumAddress(openOffer.bidder.address),
            highestBid:    openOffer && fromWei(openOffer.weiValue.toString(10), 'ether'),
            highestBidWei: openOffer && openOffer.weiValue.toString(10),
            owner: t.currentOwner.address,
            endDate: parseInt(t.edition.endDate)
          }
        })
      }

      return {
        data: _.keyBy(transform(data), 'edition'),
        subscription,
        transform,
        success: true
      }

      //console.log('auctions', auctions)
      //console.log('results', results)
    } else {
      const mappedEditions = editions.map((edition) => `edition=${edition}`).join('&');
      const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/auction/edition?${mappedEditions}`, AXIOS_CONFIG);

      if (results.data.success) {
        return results.data;
      }
      return {
        success: false
      };
    }
  }

  async getEventsForEditions(editions = [], event) {
    console.log(`Getting event [${event}] details for editions [${editions}] on network [${this.currentNetworkId}]`);

    // This is a slight hack to give us the token associated with
    // each edition, since we know the convention is just to add to the edition number
    // because we are already fetching data in an edition-based manner from the component
    // Works Since we only have 1 token per edition (for now)
    const tokens = editions.map(e => parseInt(e) + 1);
    const mappedTokens = tokens.map((token) => `token=${token}`).join('&');

    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/event/token?${mappedTokens}&event=${event}`, AXIOS_CONFIG);

    if (results.status == 200) {
      results.data.forEach(r => {
        r.edition = (parseInt(r.token)-1).toString()
      })
      return results.data;
    }

    return {
      success: false
    };
  }


  async getAuctionsForEdition(edition, {gql}={}) {
    console.log(`Getting auction details for edition [${edition}] on network [${this.currentNetworkId}]`);

    const results = await this.getAuctionsForEditions([edition], {gql})
    if (results.data.success) {
      results.data = results.data[edition]
      return results.data;
    }

    return {
      success: false
    }


    //const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/auction/edition/${edition}`, AXIOS_CONFIG);
    //if (results.data.success) {
    //  return results.data;
    //}
    //return {
    //  success: false
    //};
  }

  // NOTE: Saves auction state before it gets wiped from contract,
  // so that we can show the previous high bidder amount
  async refreshAuctionsForEdition(edition) {
    console.log(`Saving auction details for edition [${edition}] on network [${this.currentNetworkId}]`);

    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/auction/edition/${edition}/refresh`, AXIOS_CONFIG);
    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

}
