//import CPK, { Web3Adapter, defaultNetworks } from 'contract-proxy-kit';
import {contracts} from 'nrda-contract-tools';

class Smartwallet { 
      
      init({network, web3, relayWeb3}) {
            this.network = network
            this.web3 = web3
            this.relayWeb3 = relayWeb3
      }

      async walletFor(ownerAccount) {
            const [
                  {default: CPK, Web3Adapter, defaultNetworks },
                  {default: BiconomyTxRelayManager},
                  {default: CpkTransactionManager}
            ] = await Promise.all([
                  import('contract-proxy-kit'),
                  import('contract-proxy-kit/lib/cjs/transactionManagers/BiconomyTxRelayManager'),
                  import('contract-proxy-kit/lib/cjs/transactionManagers/CpkTransactionManager')
            ])
                  
            const {network, web3, relayWeb3} = this
            const web3Adapters = {
              native: new Web3Adapter({ web3 }),
              relay: new Web3Adapter({ web3: relayWeb3 })
            }

            let txManager, relayAdapter
            if (network == 80001) {
              txManager = new CpkTransactionManager()
              relayAdapter = web3Adapters.native 
            } else {
              const signer = new Web3Adapter({ web3 });
              txManager = new BiconomyTxRelayManager(signer)
              relayAdapter = web3Adapters.relay
            }

            const cpk = await CPK.create({ 
              transactionManager: txManager,
              ethLibAdapter: relayAdapter, 
              ownerAccount,
              networks: {
                // Goerli 
                5: {
                  masterCopyAddress: '0x6851D6fDFAfD08c0295C392436245E5bc78B0185',
                  //proxyFactoryAddress: '0x336c19296d3989e9e0c2561ef21c964068657c38',
                  //proxyFactoryAddress: '0xFcA10fd1ea429a74F9320329e962FC22C68aD0D1',
                  //proxyFactoryAddress: '0x7eb8B36D0ceaE6A58c431E6843B04eb422ee5dD9',
                  //proxyFactoryAddress: '0xd2F1aD6c98e057069fd1B3eebA92B109711Fac3e',
                  //proxyFactoryAddress: '0x97Ff7365a49e50ae9BD96bdd3A5095D85C5CC8B6',
                  //proxyFactoryAddress: '0x4b505346A42F3891291246c830A0AB84527D22C7',
                  //proxyFactoryAddress: '0x630C0b8C4d1164c559009ee5562eBfF5eCecE929',
                  proxyFactoryAddress: '0xd4BAb913E6815BDec78F527d94CA85D70Ba9C51A',
                  multiSendAddress: '0x8D29bE29923b68abfDD21e541b9374737B49cdAD',
                  fallbackHandlerAddress: '0xd5D82B6aDDc9027B22dCA772Aa68D5d74cdBdF44',
                },
                // Mumbai
                80001: {
                  masterCopyAddress: '0x6851D6fDFAfD08c0295C392436245E5bc78B0185',
                  //proxyFactoryAddress: '0x336c19296d3989e9e0c2561ef21c964068657c38',
                  //proxyFactoryAddress: '0xFcA10fd1ea429a74F9320329e962FC22C68aD0D1',
                  //This newly deployed CPKFactory is NativeMetaTransaction enabled
                  //proxyFactoryAddress: '0x7eb8B36D0ceaE6A58c431E6843B04eb422ee5dD9',
                  //proxyFactoryAddress: '0xd2F1aD6c98e057069fd1B3eebA92B109711Fac3e',
                  //proxyFactoryAddress: '0x97Ff7365a49e50ae9BD96bdd3A5095D85C5CC8B6',
                  //proxyFactoryAddress: '0x4b505346A42F3891291246c830A0AB84527D22C7',
                  //proxyFactoryAddress: '0x630C0b8C4d1164c559009ee5562eBfF5eCecE929',
                  proxyFactoryAddress: '0xd4BAb913E6815BDec78F527d94CA85D70Ba9C51A',
                  multiSendAddress: '0x8D29bE29923b68abfDD21e541b9374737B49cdAD',
                  fallbackHandlerAddress: '0xd5D82B6aDDc9027B22dCA772Aa68D5d74cdBdF44',
                },
                // Mainnet
                1: {
                  masterCopyAddress: '0x6851D6fDFAfD08c0295C392436245E5bc78B0185',
                  proxyFactoryAddress: '0x9DbAFa696D99ED317150926c5BB1623fe07c1663',
                  multiSendAddress: '0x8D29bE29923b68abfDD21e541b9374737B49cdAD',
                  fallbackHandlerAddress: '0xd5D82B6aDDc9027B22dCA772Aa68D5d74cdBdF44',
                },
                // Matic Mainnet
                137: {
                  masterCopyAddress: '0x6851D6fDFAfD08c0295C392436245E5bc78B0185',
                  proxyFactoryAddress: '0x9DbAFa696D99ED317150926c5BB1623fe07c1663',
                  multiSendAddress: '0x8D29bE29923b68abfDD21e541b9374737B49cdAD',
                  fallbackHandlerAddress: '0xd5D82B6aDDc9027B22dCA772Aa68D5d74cdBdF44',
                },

                5777: {
                  masterCopyAddress: contracts.getGnosisAddress('GnosisSafe', network),
                  proxyFactoryAddress: contracts.getGnosisAddress('CPKFactory', network),
                  multiSendAddress: contracts.getGnosisAddress('MultiSend', network),
                  fallbackHandlerAddress: contracts.getGnosisAddress('DefaultCallbackHandler', network),
                }
              }
            });

            cpk.isDeployed = await cpk.isProxyDeployed()
            //console.log('sw deployed', cpk.isDeployed)

            return cpk
      }
}

//const smartwallet = new Smartwallet()

export default Smartwallet
