import axios from 'axios';
import {getApi, AXIOS_CONFIG} from '../../utils';

//import { MaticPOSClient } from '@maticnetwork/maticjs'
//import Dagger from '@maticnetwork/dagger';
import { getBiconomy, getL2, getL1, metaContract} from './relayUtils';

import {contracts} from 'nrda-contract-tools';


export default class RelayService {

  constructor(currentNetworkId = 1) {
    this.currentNetworkId = currentNetworkId;
    this.api = getApi();
    this.connections = {}
  }

  async init(ethereum) {
    const {default: Web3 } = await import('web3');

    const web3 = new Web3(ethereum);
    this.metaContract = metaContract;
    const walletNetwork = await web3.eth.net.getId();
    this.connections = {
      web3,
      walletNetwork
    }

    const [biconomy, matic] = await Promise.all([
      this.initBiconomy(),
      this.initMatic()
    ])

    // Incremental changes led to this clusterfuck, sorry future devs
    let relayWeb3, l1Web3, l2Web3, network, l1Network;

    if (biconomy) {
      l1Web3 = web3
      // Original web3 relegated to signer, replace web3 with biconomy web3
      relayWeb3    = new Web3(biconomy)
      network      = getL2(walletNetwork).network
      l2Web3       = new Web3(getL2(walletNetwork).endpoint)
      l1Network    = walletNetwork
    } else {
      l1Network = getL1(walletNetwork).network
      l1Web3    = new Web3(getL1(walletNetwork).endpoint)

      l2Web3    = web3
      relayWeb3 = web3
      network   = walletNetwork
    }


    this.connections = { 
      web3, 
      relayWeb3, 
      l1Web3, 
      l2Web3, 
      network, 
      l1Network, 
      walletNetwork,
      biconomy,
      matic
    }
  }

  async initBiconomy() {
    const {walletNetwork: network} = this.connections
    return await getBiconomy(network)
  }

  async initMatic() {
      const [{MaticPOSClient}, {default: Dagger}] = await Promise.all([
        import('@maticnetwork/maticjs'),
        import('@maticnetwork/dagger'),
      ])

      const {web3, walletNetwork: network} = this.connections;
      
      let matic, maticWs, dagger = {};
      const maticIds = [80001, 137]
      const testIds = [80001, 5]
      const mainIds = [137, 1]

      if (testIds.includes(network)) {
        matic = new MaticPOSClient({
          network: "testnet",
          version: "mumbai",
          parentProvider: web3.currentProvider,
          maticProvider: contracts.rpcHttpEndpoint('matic')
        });

        //maticWs = new WebSocket(contracts.rpcWebsocketEndpoint('matic'))

        dagger.eth = new Dagger("wss://goerli.dagger.matic.network")
        dagger.matic = new Dagger("wss://mumbai-dagger.matic.today")

      } else if (mainIds.includes(network)) {
        matic = new MaticPOSClient({
          network: "mainnet",
          version: "v1",
          parentProvider: web3.currentProvider,
          maticProvider: contracts.rpcHttpEndpoint('maticlive')
        });

        dagger.eth = new Dagger("wss://mainnet.dagger.matic.network")
        dagger.matic = new Dagger("wss://matic-mainnet.dagger.matic.network")

        //maticWs = new WebSocket(contracts.rpcWebsocketEndpoint('maticlive'))
      } else {
        matic = null
      }

      
      const isOnMatic =  maticIds.includes(network)

      return {
        matic,
        maticWs,
        dagger,
        isOnMatic 
      }
  }

  /**
   * Updates the currentNetworkId, needed for when switching network
   * @param currentNetworkId
   */
  setNetworkId(currentNetworkId) {
    this.currentNetworkId = currentNetworkId;
  }

  /**
   * Get total owned tokens for account
   *
   * @param account
   * @return {Promise<*>}
   */
  async whitelistProxy(address) {
    console.log(`whitelist [${address}] on network [${this.currentNetworkId}]`);

    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/biconomy/whitelist/proxy?address=${address}`, AXIOS_CONFIG);
    //console.log(results);
    return results;
  }
}
