import utils from 'web3-utils';
import {get, some, indexOf, includes, debounce, forEach, assign} from 'lodash';
const _ = {get, some, indexOf, includes, debounce, forEach, assign};

const getNetIdString = (id) => {
  // N.B - be careful changing this as the warning banner uses this string
  console.log(`Running on network ID ${id}`);
  switch (id) {
    case 1:
      return {id: 1, human: 'Main', firebasePath: 'mainnet'};
    case 3:
      return {id: 3, human: 'Ropsten', firebasePath: 'ropsten'};
    case 4:
      return {id: 4, human: 'Rinkeby', firebasePath: 'rinkeby'};
    case 5:
      return {id: 5, human: 'Goerli', firebasePath: 'goerli'};
    case 42:
      return {id: 42, human: 'kovan', firebasePath: 'mainnet'};
    case 80001:
      return {id: 80001, human: 'Matic Testnet', firebasePath: 'matic'};
    case 137:
      return {id: 137,   human: 'Matic Live', firebasePath: 'maticlive'};
    default:
      return {id: 5777, human: 'Local', firebasePath: 'local'};
  }
};

const getEtherscanAddress = (id) => {
  switch (id) {
    case 1:
      return 'https://etherscan.io';
    case 3:
      return 'https://ropsten.etherscan.io';
    case 4:
      return 'https://rinkeby.etherscan.io';
    case 5:
      return 'https://goerli.etherscan.io';
    case 42:
      return 'https://kovan.etherscan.io';
    case 137:
      return 'https://explorer-mainnet.maticvigil.com';
    case 80001:
      return 'https://explorer-mumbai.maticvigil.com';
    default:
      return '';
  }
};

const isHighResV1Old = ({artistCode, attributes, edition}) => {
  if (!attributes || !artistCode) {
    return false;
  }
  const tags = _.get(attributes, 'tags', []);

  // IPFS data drives the first check
  const isFlaggedAsHighRes = _.some(tags, (element) => _.indexOf(['high res', 'vector', 'High res', 'Vector'], element) >= 0);

  // Not all are high-res yet
  const isNotExclusedForArtist = !_.includes(
    [
      // STAN
      'STREMOJI10000DIG', 'STRTRTSYM0001DIG', 'STRSOFTFACE01DIG', 'STRLIQUIFY001DIG', 'STRTOOMNYNOONDIG', 'STRTAMINGLIONDIG', 'STRGRASSROOTSDIG',
      // FRANKY
      'FKABURNINFIATDIG',
    ],
    edition
  );

  // All artists which are enabled all editions as high-res
  const isEnabledForArtist = _.includes([
    // Aktiv, Tony Smith, Stina, L O S E V A, Lev, Franky, Martin Lukas Ostachowski, Oficinas TK
    'AKP', 'TSM', 'STJ', 'LOS', 'STR', 'LHD', 'LEV', 'FKA', 'OBX', 'MLO', 'DWW', 'HKT', 'OTK'
  ], artistCode) && isNotExclusedForArtist;

  // Force the ones we didnt stamp in IPFS but have now been provided by artists
  const forcedEditions = _.includes([
    // Stina
    'STJHAPPYFOX00DIG', 'FKAHYPDTHSTY0DIG',
    // Franky
    'FKADAFFY0RAINDIG',
  ], edition);

  return (isFlaggedAsHighRes && isEnabledForArtist) || forcedEditions;
};

const safeToCheckSumAddress = (address) => {
  try {
    return utils.toChecksumAddress(address);
  } catch (e) {
    return '0x00000000000000000000000000000000';
  }
};

const updaters = []
const addToAnimationLoop = (() => {
  const startAnimationLoop = () => {
    // A single rAF loop apparently collects garbage resulting in
    // resulting in GC pauses causing 7 FPS
    // Doing a 'ping-pong' loop like this magically solves that
    // https://stackoverflow.com/a/23129638
    // If any of the updaters return true, we rAF.
    // Otherwise don't do anything for performance reasons
    // Update: requesting animation frame every time

    const drawA = () => {
      updaters.forEach(fn => fn())
      requestAnimationFrame(drawB);
    }

    const drawB = () => {
      updaters.forEach(fn => fn())
      requestAnimationFrame(drawA);
    }
    drawA();
  }

  startAnimationLoop()

  return (updater) => {
    updaters.push(updater)
  }
})()

const resetAnimationLoop = (() => {
  updaters.length = 0
  //updaters = []
})

// Use an object so hot reloading doesn't do anything weird
// like adding the same callback over and over
let resizeListeners = {}
const addResizeListener = (() => {
  
  const startResizeListen = () => {
    window.addEventListener('resize', _.debounce(() => {
      _.forEach(resizeListeners, cb => cb())
    }, 300))
  }

  startResizeListen()

  return (listeners) => {
    _.forEach(listeners, cb => cb())
    _.assign(resizeListeners, listeners)
  }
})()

const resetResizeListeners = (() => {
  resizeListeners = {}
})


let app
if (window.location.port == 8080) {
  app = 'not-real-co' 
} else {
  app = 'beta-not-real-co' 
}
const API_CONFIG = {
  local: `http://localhost:5000/${app}/us-central1/main/api`,
  local_ip: `http://192.168.86.25:5000/${app}/us-central1/main/api`,
  // beta site
  beta: 'https://beta-not-real-co.firebaseapp.com/api',
  // beta site (web)
  beta_web: 'https://beta-not-real-co.web.app/api',
  // beta site (sub-domain)
  beta_sub_web: 'https://beta.notreal.ai/api',
  prod: 'https://not-real-co.firebaseapp.com/api',
  prod_web: 'https://not-real-co.web.app/api',
  // Live site
  //live: 'https://dapp.notreal.co/api',
  live: 'https://notreal.ai/api',
  // Live site
  live_root: 'https://notreal.ai/api',
  live_root_www: 'https://www.notreal.ai/api',
  default: (host) => `https://${host}/api`
};

const hostWhitelist = ['notrealcat.com', 'notrealcats.com']
const defaultAPI = (hostname) => {
  const match = hostWhitelist.find(h => h == hostname)
  return match ? API_CONFIG.default(match) : API_CONFIG.live
}

const getApi = () => {
  const host = window.location.hostname
  switch (host) {
    case 'localhost':
    case '127.0.0.1':
      return API_CONFIG.local;
    case '192.168.86.25':
      return API_CONFIG.local_ip;
    case 'beta-not-real-co.firebaseapp.com':
      return API_CONFIG.beta;
    case 'beta-not-real-co.web.app':
      return API_CONFIG.beta_web;
    case 'beta.notreal.ai':
    case 'notreal.ai':
    case 'www.notreal.ai':
    case 'beta.notrealcats.com':
    case 'notrealcats.com':
    case 'www.notrealcats.com':
      return `https://${host}/api`
    // Hardcoded for temp demo
    case 'not-real-co.firebaseapp.com':
      return API_CONFIG.prod;
    case 'not-real-co.web.app':
      return API_CONFIG.prod_web;
    default:
      return defaultAPI(window.location.hostname)
  }
};

const AXIOS_CONFIG = {
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
};

const keys = {
  infura: 'fb81a1a1f7bb471bb61f80207f2fee26',
  fortmatic: 'pk_live_EC7A667FE11CEE88',
  fortmatic_beta: 'pk_test_F724621EBEC94CC0',
  portis: '3980ff61-9d97-4cf2-85d1-31f00aa92af5',
  portis_beta: '70641e14-0113-4f31-9723-63774b74de87',
  portisKey: () => {
    if (window.location.hostname === 'beta-not-real-co.web.app') {
      return keys.portis_beta;
    }
    return keys.portis;
  },
  fortmaticKeys() {
    if (window.location.hostname === 'beta-not-real-co.web.app') {
      return keys.fortmatic_beta;
    }
    return keys.fortmatic;
  }
};

// https://ipfs.infura.io/ipfs/QmaBptajfHnnGzZkqDDMuaqwsRxtj4AKcDGa1w8Mc9YEw4
// V
// https://bafybeifqbgdo7ph3gd63klcophyqswv4r42jhtq6lvmy5sb3yluuah3xzm.ipfs.infura-ipfs.io
import { base32 as b32 } from 'cid-tool'
const ipfs32 = (ipfs64) => {
  if(!ipfs64) { return null }
  if(ipfs64.indexOf('ipfs/') == -1) { return ipfs64 }
  const [cid, path] = ipfs64.split('ipfs/').pop().split('/')
  return `https://${b32(cid)}.ipfs.infura-ipfs.io/${path || ''}`
}

// Stop-gap to map old infura URLs to new style ones to prevent 301s that slow everything down
const ipfs32Edition = (edition) => {
  return {
    ...edition,
    lowResImg: ipfs32(edition.lowResImg),
    tokenUri: ipfs32(edition.tokenUri)
  }
}

// Just a convenience function to map the awkward results array
const mapResults = (results, fn) => {
  if (results.data.data && results.data.data.map) {
    results.data.data = results.data.data.map(fn)
  } else if (results.data && results.success) {
    results.data = fn(results.data)
  }

  return results
}

const q = (s) => document.querySelector(s)
const qa = (s) => [...document.querySelectorAll(s)]


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// Wait for element to appear
async function waitFor(selector, timeout=99999, ping=250) {
    return new Promise(r=>{
        let time = 0
        let ivl = setInterval(()=>{
            let el = q(selector)
            console.log(el)
            if (el || time > timeout) {
                clearInterval(ivl)
                r(el)
            }
            time += ping
        }, ping)
    })
}

function getTransactionsByAccount({web3, address, start, end}) {
  if (end == null) {
    end = web3.eth.blockNumber;
    console.log("Using end: " + end);
  }
  if (start == null) {
    start = end - 1000;
    console.log("Using start: " + start);
  }
  console.log("Searching for transactions to/from account \"" + address + "\" within blocks "  + start + " and " + end);

  for (var i = start; i <= end; i++) {
    if (i % 1000 == 0) {
      console.log("Searching block " + i);
    }
    var block = web3.eth.getBlock(i, true);
    if (block != null && block.transactions != null) {
      block.transactions.forEach( function(e) {
        if (address == "*" || address == e.from || address == e.to) {
          console.log("  tx hash          : " + e.hash + "\n"
            + "   nonce           : " + e.nonce + "\n"
            + "   blockHash       : " + e.blockHash + "\n"
            + "   blockNumber     : " + e.blockNumber + "\n"
            + "   transactionIndex: " + e.transactionIndex + "\n"
            + "   from            : " + e.from + "\n" 
            + "   to              : " + e.to + "\n"
            + "   value           : " + e.value + "\n"
            + "   time            : " + block.timestamp + " " + new Date(block.timestamp * 1000).toGMTString() + "\n"
            + "   gasPrice        : " + e.gasPrice + "\n"
            + "   gas             : " + e.gas + "\n"
            + "   input           : " + e.input);
        }
      })
    }
  }
}

export {
  getApi,
  AXIOS_CONFIG,
  safeToCheckSumAddress,
  addToAnimationLoop,
  resetAnimationLoop,
  addResizeListener,
  resetResizeListeners,
  getNetIdString,
  getEtherscanAddress,
  isHighResV1Old,
  keys,
  ipfs32,
  ipfs32Edition,
  mapResults,
  sleep,
  waitFor
};
