import axios from 'axios';
import {getApi, AXIOS_CONFIG, ipfs32Edition, mapResults} from '../../utils';
import * as _ from 'lodash';

import {apolloClient} from '../../apollo';
import { EDITIONS } from '../../gql/queries';


// Must ping the following URL to refresh new editions
// http://localhost:5000/not-real-co/us-central1/main/api/network/5777/edition/refresh/all
export default class EditionLookupService {

  constructor(currentNetworkId = 1) {
    this.currentNetworkId = currentNetworkId;
    this.api = getApi();
  }

  /**
   * Updates the currentNetworkId, needed for when switching network
   * @param currentNetworkId
   */
  setNetworkId(currentNetworkId) {
    this.currentNetworkId = currentNetworkId;
  }

  /**
   * Loads only the provided editions, default sorted order apply in the backend
   * @param editions
   * @param order
   * @return {Promise<*>}
   */
  async getEditions(editions = [], order = 'asc') {
    console.log(`Load editions [${editions}] on network [${this.currentNetworkId}]`);

    const mappedEditions = editions.map((edition) => `edition=${edition}`).join('&');

    let results = await axios.get(`${this.api}/network/${this.currentNetworkId}/edition?${mappedEditions}&order=${order}`, AXIOS_CONFIG);
    results = mapResults(results, ipfs32Edition)

    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

  /**
   * Load a specific edition
   * @param edition
   * @return {Promise<*>}
   */
  async getEdition(edition) {
    console.log(`Load edition [${edition}] on network [${this.currentNetworkId}]`);

    let results = await axios.get(`${this.api}/network/${this.currentNetworkId}/edition/${edition}`, AXIOS_CONFIG);
    results = mapResults(results, ipfs32Edition)

    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

  /**
   * Load gallery editions, special method for paging the editions KODA has
   * Does not show editions which are sold out or inactive
   * @param filters
   * @return {Promise<*>}
   */
  async getGalleryEditions(filters) {
    console.log(`Load gallery editions on network [${this.currentNetworkId}] filters`, filters);
    const mappedFilters = _.map(filters, (key, value) => `${value}=${key}`).join('&');
    //console.log(mappedFilters);
    let results = await axios.get(`${this.api}/network/${this.currentNetworkId}/edition/gallery/list?${mappedFilters}`, AXIOS_CONFIG);
    results = mapResults(results, ipfs32Edition)

    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

//const lookupIPFSData = async (tokenUri) => {
//
//  const client = axios.create();
//  axiosRetry(client, {
//    retries: 3, // handle 504/timeouts from infura - they happen so use lots of retries
//    retryDelay: (retryCount, error) => {
//      console.error(`Retry interceptor triggered - attempt [${retryCount}]`, error);
//      // exponential back-off
//      return retryCount * 1000;
//    }
//  });
//
//  let tokenMeta = await client.get(tokenUri);
//  let rootMeta = tokenMeta.data;
//  let metaData = {
//    tokenUri: tokenUri,
//    name: rootMeta.name,
//    description: rootMeta.description,
//    pseudoEdition: _.get(rootMeta, 'pseudoEdition', null),
//    external_uri: _.get(rootMeta, 'external_uri', 'http://notreal.ai'),
//    attributes: _.get(rootMeta, 'attributes', {
//      tags: ['digital']
//    }),
//    // Unable to replace `lowResImg` without changing FE V1 & V2
//    lowResImg: rootMeta.image,
//  };
//
//  // we set animation_url for videos on open sea
//  if (rootMeta.animation_url) {
//    metaData.animation_url = rootMeta.animation_url;
//  }
//  return metaData;
//};
  //const mapData = (rawData) => {
  //  const totalSupply = _.toNumber(rawData[8].toString(10));
  //  const totalAvailable = _.toNumber(rawData[9].toString(10));
  //  const editionData = Web3.utils.hexToUtf8(rawData[0]);
  //  return {
  //    editionData: editionData,
  //    editionType: _.toNumber(rawData[1].toString(10)),
  //    startDate: _.toNumber(rawData[2].toString(10)),
  //    endDate: _.toNumber(rawData[3].toString(10)),
  //    artistAccount: Web3.utils.toChecksumAddress(rawData[4]),
  //    artistCommission: _.toNumber(rawData[5].toString(10)),
  //    priceInWei: rawData[6].toString(10),
  //    priceInEther: _.toNumber(Web3.utils.fromWei(rawData[6].toString(10), 'ether')),
  //    tokenUri: rawData[7],
  //    totalSupply: totalSupply,
  //    totalAvailable: totalAvailable,
  //    totalRemaining: totalAvailable - totalSupply,
  //    soldOut: (totalAvailable - totalSupply) <= 0,
  //    active: rawData[10],
  //    // V1 properties back port
  //    v1: {
  //      // First 3 chars of edition are artist code
  //      artistCode: _.size(editionData) > 3 ? editionData.substring(0, 3) : ''
  //    }
  //  };
  //};
  //const editionData = {
  //  edition: editionNumber,
  //  ...ipfsData,
  //  ...allEditionData,
  //  highResAvailable: highResAvailable,
  //  secondCommissionSplit: secondCommissionSplit,
  //  // Deeplinks - useful for 3rd parties
  //  marketplace_deeplink_url: `https://notreal.ai/edition/${editionNumber}`,
  //  artist_deeplink_url: `https://notreal.ai/artists/${allEditionData.artistAccount}`,
  //  tokens
  //};
  //const mapTokenData = async (contract, tokenId) => {
  //  //console.log(tokenId)
  //  const {_editionNumber, _editionType, _tokenURI, _owner} = await contract.tokenData(tokenId);
  //  return {
  //    tokenId: tokenId.toString(),
  //    edition: _editionNumber.toString(),
  //    editionType: _editionType.toString(),
  //    owner: _owner,
  //    tokenURI: _tokenURI
  //  };
  //}

  /**
   * Loads all editions for the given address
   * @param artistAccount
   * @param availableForPurchaseOnly when set to true we only return editions which are available for purchase
   * @return {Promise<*>}
   */
  async getEditionsForArtist(artistAccount, {availableForPurchaseOnly , offset, limit, gql}={}) {
    const page = `offset=${offset || '' }&limit=${limit || ''}`
    console.log(`Load editions for artist [${artistAccount}] on network [${this.currentNetworkId}] page:[${page}]`);

    if(!availableForPurchaseOnly) { availableForPurchaseOnly = false }

    //let results = {}
    //debugger
    let results = await axios.get(`${this.api}/network/${this.currentNetworkId}/edition/artist/${artistAccount}?availableForPurchaseOnly=${availableForPurchaseOnly}&${page}`, AXIOS_CONFIG);
    results = mapResults(results, ipfs32Edition) 

    if (results.data.success) {

      if (gql) {

        const savedEditions = _.keyBy(results.data.data, 'id')
        const editionIds = _.keys(savedEditions)

        console.log('savedEditions', savedEditions, editionIds)

        const query = await apolloClient.query({
          query: EDITIONS(),
          variables: {
            editionIds
          }
        })

        const gqlEditions = _.keyBy(query.data.editions, 'id')

        const subscription = apolloClient.subscribe({
          query: EDITIONS('subscription'),
          variables: {
            editionIds
          }
        })

        const transform = (gql, saved) => {
          return _.mergeWith(saved, gql, (s, g) => {
            return {
              ...s,
              isOvertime: s.endDate != g.endDate,
              endDate: g.endDate
            }
          })
        }

        console.log('gql', gqlEditions, savedEditions)

        // Perform transform once for sync gql query we already sent
        results.data = {
          ...results.data,
          data: transform(gqlEditions, savedEditions),
          subscription,
          transform
        }
      }

      return results.data;
    }
    return {
      success: false
    };
  }

  /**
   * Loads all editions for the given address which dont have a registered high-res download
   *
   * @param artistAccount
   * @return {Promise<*>}
   */
  async getEditionsWithoutHighResForArtist(artistAccount) {
    console.log(`Load editions for artist [${artistAccount}] missing high-res on network [${this.currentNetworkId}]`);

    let results = await axios.get(`${this.api}/network/${this.currentNetworkId}/edition/artist/${artistAccount}/status/highres`, AXIOS_CONFIG);
    results = mapResults(results, ipfs32Edition)

    if (results.data.success) {
      return results.data;
    }
    return {
      success: false
    };
  }

  /**
   * Loads all editions for the given edition type
   * @param editionType
   * @return {Promise<*>}
   */
  async getEditionsForType(editionType = 1) {
    console.log(`Load editions for type [${editionType}] on network [${this.currentNetworkId}]`);

    let results = await axios.get(`${this.api}/network/${this.currentNetworkId}/edition/type/${editionType}`, AXIOS_CONFIG);
    results = mapResults(results, ipfs32Edition)

    if (results.data.success) {
      return results.data;
    }
    return {};
  }

  /**
   * Refresh edition data in the back end
   *
   * @param edition
   * @return {Promise<*>}
   */
  async refreshEditionData(edition) {
    console.log(`Refresh edition [${edition}] on network [${this.currentNetworkId}]`);

    let results = await axios.get(`${this.api}/network/${this.currentNetworkId}/edition/${edition}/refresh`, AXIOS_CONFIG);
    results = mapResults(results, ipfs32Edition)
    //console.log(results);
    if (results.data.success) {
      return results.data;
    }
    return {};
  }

  /**
   * Get KODA v2 token data
   *
   * @param tokenId
   * @return {Promise<*>}
   */
  async loadToken(tokenId) {
    console.log(`Get KODA V2 token [${tokenId}] on network [${this.currentNetworkId}]`);

    let results = await axios.get(`${this.api}/network/${this.currentNetworkId}/token/${tokenId}`, AXIOS_CONFIG);
    results = mapResults(results, ipfs32Edition)
    // console.log(results.data);
    return results.data;
  }

  /**
   * Get KODA v1 token data
   *
   * @param tokenId
   * @return {Promise<*>}
   */
  async loadKodaV1Token(tokenId) {
    console.log(`Get KODA V1 token [${tokenId}] on network [${this.currentNetworkId}]`);

    let results = await axios.get(`${this.api}/network/${this.currentNetworkId}/token/kodav1/${tokenId}`, AXIOS_CONFIG);
    results = mapResults(results, ipfs32Edition)

    // console.log(results.data);
    return results.data;
  }

}

