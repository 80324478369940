import * as actions from '../actions';
import * as mutations from '../mutation';
import axios from 'axios';
import {getApi} from '../../utils';
import {toHex} from 'web3-utils';

const AUTH_KEY = 'koda-auth-token';
const USER_SIGNUP_MODEL_DISMISSED_KEY = 'koda-user-signup-modal-dismissed';

const authStateModule = {
  namespaced: true,
  state: {
    kodaAuthToken: null
  },
  getters: {},
  mutations: {
    clearAuthToken(state) {
      state.kodaAuthToken = null;
      localStorage.removeItem(AUTH_KEY);
    },
    setAuthToken(state, token) {
      state.kodaAuthToken = token;
      localStorage.setItem(AUTH_KEY, token);
    }
  },
  actions: {
    async [actions.WEB3_AUTH_LOGIN]({commit, dispatch, state, rootState}) {

      const account = rootState.account;
      const currentNetworkId = rootState.currentNetworkId;

      const postToApi = (signedMessage) => axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        data: JSON.stringify({
          address: account,
          signed: signedMessage,
        }),
        url: `${getApi()}/network/${currentNetworkId}/accounts/${account}/auth`,
      });

      return rootState.web3.eth.personal
        .sign(toHex(`Sign into NotReal`), account)
        .then((signedMessage) => postToApi(signedMessage))
        .then((result) => {
          console.log('Successfully authenticated', result.data.token);
          commit('setAuthToken', result.data.token);
          return result.data.token;
        })
        .catch((error) => {
          console.log('Failed to authenticate', error);
          commit('clearAuthToken');
        });
    },
    async [actions.WEB3_AUTH_LOAD_PROFILE]({commit, dispatch, state, rootState}) {
      const account = rootState.account;
      const currentNetworkId = rootState.currentNetworkId;

      if (!state.kodaAuthToken || !account) {
        return Promise.reject('Cant find authentication/account token');
      }

      return axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `bearer ${state.kodaAuthToken}`
        },
        url: `${getApi()}/network/${currentNetworkId}/accounts/${account}/profile`,
      })
        .then((result) => {
          return result.data;
        });
    },
    async [actions.WEB3_AUTH_SAVE_PROFILE]({commit, dispatch, state, rootState}, data) {
      const account = rootState.account;
      const currentNetworkId = rootState.currentNetworkId;

      if (!state.kodaAuthToken || !account) {
        return Promise.reject('Cant find authentication/account token');
      }

      return axios({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `bearer ${state.kodaAuthToken}`
        },
        data: data,
        url: `${getApi()}/network/${currentNetworkId}/accounts/${account}/profile`,
      })
        .then((result) => {
          return result.data;
        });
    },
    async [actions.WEB3_AUTH_CHECK_DUPLICATE_USERNAME]({commit, dispatch, state, rootState}, username) {
      const account = rootState.account;
      const currentNetworkId = rootState.currentNetworkId;

      if (!state.kodaAuthToken || !account) {
        return Promise.reject('Cant find authentication/account token');
      }

      return axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        url: `${getApi()}/network/${currentNetworkId}/accounts/checkduplicateusername?username=${username}&address=${account}`,
      })
        .then((result) => {
          return result.data;
        });
    },
    async [actions.WEB3_AUTH_ACCOUNT_HAS_PROFILE]({commit, dispatch, state, rootState}, {account}) {
      const currentNetworkId = rootState.currentNetworkId;
      return axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        url: `${getApi()}/network/${currentNetworkId}/accounts/profileExists?address=${account}`,
      })
        .then((result) => {
          return result.data;
        });
    },
    async [actions.CHECK_USER_SIGNUP_PROMPT]({commit, dispatch, state, rootState}, {account}) {
      // Check not already dismissed
      const userDismissedPopup = localStorage.getItem(USER_SIGNUP_MODEL_DISMISSED_KEY) || false;
      console.log(`User has already dismissed sign-up prompt popup [${userDismissedPopup}]`);
      // load user profile
      // This also creates an empty entry in DB for the user
      const {exists} = await dispatch(actions.WEB3_AUTH_ACCOUNT_HAS_PROFILE, {account});

      if (userDismissedPopup !== 'true') {
        console.log(`Loading user profile`, exists);

        // check profile exists
        if (!exists) {
          console.log(`User prompt popup - enabled`);
          return {showPopUp: true};
        }
      }
      console.log(`User prompt popup - disabled`);
      return {showPopUp: false};
    },
    async [actions.DISMISS_USER_SIGNUP_PROMPT]() {
      console.log(`Disable user signup prompt`);
      localStorage.setItem(USER_SIGNUP_MODEL_DISMISSED_KEY, 'true');
    },
  }
};

export default authStateModule;
