import Vue from 'vue';
import Router from 'vue-router';

import Meta from 'vue-meta';

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      alias: ['/home'],
      component: () => import(/* webpackChunkName: "home" */ '../views/Feed.vue'),
      props: route => ({ startPage: Math.max(parseInt(route.query.page) - 1, 1)})
    },
    {
      path: '/feed',
      name: 'feed',
      component: () => import(/* webpackChunkName: "home" */ '../views/Feed.vue'),
      props: route => ({ startPage: Math.max(parseInt(route.query.page) - 1, 1)})
    },
    {
      path: '/studio',
      name: 'studio',
      component: () => import(/* webpackChunkName: "home" */ '../views/Studio.vue')
    },
    // {
    //   path: '/debug',
    //   name: 'debug',
    //   component: () => import(/* webpackChunkName: "debug" */ '../views/Debug.vue')
    // },
    {
      path: '/gallery',
      name: 'gallery',
      props: true,
      component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue')
    },
    {
      path: '/contracts',
      name: 'contracts',
      component: () => import(/* webpackChunkName: "contracts" */ '../views/ContractLinks.vue')
    },
    {
      path: '/account/:address?',
      name: 'account',
      component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
    },
    {
      path: '/artists',
      name: 'artists',
      component: () => import(/* webpackChunkName: "artists" */ '../views/Artists.vue')
    },
    {
      path: '/activity',
      name: 'activity',
      component: () => import(/* webpackChunkName: "activity" */ '../views/Activity.vue')
    },
    {
      path: '/offers',
      name: 'openOffers',
      component: () => import(/* webpackChunkName: "openoffers" */ '../views/OpenOffers.vue')
    },
    {
      path: '/token/:tokenId',
      name: 'edition-token',
      props: true,
      component: () => import(/* webpackChunkName: "editiontoken" */ '../views/EditionTokenOverview.vue')
    },
    {
      path: '/edition/:editionNumber',
      name: 'confirmPurchaseSimple',
      props: true,
      component: () => import(/* webpackChunkName: "purchase" */ '../views/ConfirmPurchase.vue')
    },
    {
      path: '/artists/:artistAccount',
      alias: '/artists-v2/:artistAccount', // legacy path
      name: 'artist',
      props: true,
      component: () => import(/* webpackChunkName: "artist" */ '../views/ArtistPage.vue')
    },
    {
      path: '/artists/:artistAccount/editions/:editionNumber',
      alias: '/artists-v2/:artistAccount/editions/:editionNumber', // legacy path
      name: 'confirmPurchase',
      props: true,
      component: () => import(/* webpackChunkName: "purchase" */ '../views/ConfirmPurchase.vue')
    },
    {
      path: '/assets/:legacyTokenId',
      name: 'legacy-asset',
      props: true,
      component: () => import(/* webpackChunkName: "legacydeeplink" */ '../views/LegacyDeepLinkToken.vue')
    },
    //{
    //  path: '/dao-osaka',
    //  name: 'dao-osaka',
    //  component: () => import(/* webpackChunkName: "daoosaka" */ '../views/DaoOsaka.vue')
    //},
    //{
    //  path: '/darkness',
    //  name: 'darkness',
    //  component: () => import(/* webpackChunkName: "darkness" */ '../views/Darkness.vue')
    //},
    {
      path: '/boards',
      name: 'boards',
      component: () => import(/* webpackChunkName: "board" */ '../views/Boards.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import(/* webpackChunkName: "board" */ '../views/Blog.vue')
    },
    {
      path: '/tos',
      name: 'tos',
      component: () => import(/* webpackChunkName: "board" */ '../views/Blog.vue')
    },
    // TODO missing legacy paths V1
    // '/artists/:legacyArtistCode/editions/:legacyEditionCode',
    // TODO missing V2 paths
    // /editions
    // /editions/:typeId
    // /editions/qr
    // -------------------------- //
  ]
});
