import * as actions from '../actions';
import * as mutations from '../mutation';
import _ from 'lodash';
import {fromWei} from 'web3-utils';

const selfServiceStateModule = {
  namespaced: true,
  state: {
    owner: null,
    paused: null,
    contractAddress: null,
    maxEditionSize: null,
    minPricePerEdition: 40000000000000000,
    minPricePerEditionInEther: 0.04,
    mintingState: {}
  },
  getters: {
    selfServiceState: (state) => (account) => {
      return _.get(state.mintingState, account);
    },
    getSelfServiceTransactionForAccount: (state, getters) => (account) => {
      return (getters.selfServiceState(account) || {}).transactionHash;
    },
    isSelfServiceTriggered: (state, getters) => (account) => {
      return _.get(getters.selfServiceState(account), 'state') === mutations.SELF_SERVICE_TRIGGERED;
    },
    isSelfServiceStarted: (state, getters) => (account) => {
      return _.get(getters.selfServiceState(account), 'state') === mutations.SELF_SERVICE_STARTED;
    },
    isSelfServiceSuccessful: (state, getters) => (account) => {
      return _.get(getters.selfServiceState(account), 'state') === mutations.SELF_SERVICE_SUCCESSFUL;
    },
    isSelfServiceFailed: (state, getters) => (account) => {
      return _.get(getters.selfServiceState(account), 'state') === mutations.SELF_SERVICE_FAILED;
    },
  },
  mutations: {
    [mutations.SET_SELF_SERVICE_CONTROLS](state, {paused, owner, address, maxEditionSize, minPricePerEdition}) {
      state.paused = paused;
      state.owner = owner;
      state.address = address;
      state.maxEditionSize = maxEditionSize;
      state.minPricePerEdition = minPricePerEdition;
      state.minPricePerEditionInEther = fromWei(minPricePerEdition.toString(), 'ether');
    },
    [mutations.SELF_SERVICE_TRIGGERED](state, {account}) {
      state.mintingState = {
        ...state.mintingState,
        [account]: {
          ...state.mintingState[account],
          state: 'SELF_SERVICE_TRIGGERED'
        }
      };
    },
    [mutations.SELF_SERVICE_STARTED](state, {account, transactionHash}) {
      state.mintingState = {
        ...state.mintingState,
        [account]: {
          ...state.mintingState[account],
          account,
          transactionHash,
          state: 'SELF_SERVICE_STARTED'
        }
      };
    },
    [mutations.SELF_SERVICE_SUCCESSFUL](state, {account, transactionHash, receipt}) {
      state.mintingState = {
        ...state.mintingState,
        [account]: {
          ...state.mintingState[account],
          transactionHash,
          receipt,
          state: 'SELF_SERVICE_SUCCESSFUL'
        }
      };
    },
    [mutations.SELF_SERVICE_FAILED](state, {account, transactionHash, error}) {
      state.mintingState = {
        ...state.mintingState,
        [account]: {
          ...state.mintingState[account],
          error,
          transactionHash,
          state: 'SELF_SERVICE_FAILED'
        }
      };
    },
    [mutations.SELF_SERVICE_RESET](state, {account}) {
      delete state.mintingState[account];
      state.mintingState = {...state.mintingState};
    },
  },
  actions: {
    async [actions.GET_SELF_SERVICE_CONTRACT_DETAILS]({commit, state, getters, rootState}) {
      const contract = rootState.SelfServiceEditionCuration;

      const paused = await contract.methods.paused().call();
      const owner = await contract.methods.owner().call();
      const maxEditionSize = await contract.methods.maxEditionSize().call();
      const minPricePerEdition = await contract.methods.minPricePerEdition().call();
      const address = contract._address;

      commit(mutations.SET_SELF_SERVICE_CONTROLS, {
        paused,
        owner,
        address,
        maxEditionSize,
        minPricePerEdition
      });
    },
    async [actions.GET_SELF_SERVICE_ENABLED_FOR_ACCOUNT]({commit, dispatch, state, getters, rootState}, {artistAccount}) {
      const contract = rootState.SelfServiceEditionCuration;
      const canCreateAnotherEdition = await contract.methods.canCreateAnotherEdition(artistAccount).call();
      const isEnabledForAccount = await contract.methods.isEnabledForAccount(artistAccount).call();
      return {
        canCreateAnotherEdition,
        isEnabledForAccount
      };
    },
    async [actions.CREATE_SELF_SERVICE_EDITION]({commit, dispatch, state, getters, rootState}, data) {
      const account = rootState.account;

      const contract = rootState.SelfServiceEditionCuration;

      commit(mutations.SELF_SERVICE_TRIGGERED, {account});

      const createEdition = () => {

        let {totalAvailable, tokenUri, priceInWei, enableAuctions, artist, optionalSplitAddress, startDate, endDate} = data;

        const isCallingArtists = account === artist;
        console.log(`Is calling artist [${isCallingArtists}]`);

        let artistCommission = 85;
        let optionalSplitRate = 0;

        if (optionalSplitAddress) {
          optionalSplitRate = 42;
          artistCommission = 43;
        } else {
          optionalSplitAddress = '0x0000000000000000000000000000000000000000';
        }

        if (!startDate) { startDate = 0 }
        if (!endDate)   { endDate = 0 }
        /*
        const startDate = 0;
        const endDate = 0;
        */
        const editionType = 1;

        // If the caller isn't the artist assume its KO making it for them
        return isCallingArtists
          ? contract.methods.createEdition(enableAuctions, optionalSplitAddress, optionalSplitRate, totalAvailable, priceInWei, startDate, endDate, artistCommission, editionType, tokenUri).send({from: account})
          : contract.methods.createEditionFor(artist, enableAuctions, optionalSplitAddress, optionalSplitRate, totalAvailable, priceInWei, startDate, endDate, artistCommission, editionType, tokenUri).send({from: account});
      };

      let txsHash = null;

      createEdition()
        .on('transactionHash', hash => {
          console.log('transactionHash', hash);
          rootState.notifier.hash(hash);
          txsHash = hash;
          commit(mutations.SELF_SERVICE_STARTED, {
            transactionHash: hash,
            account,
          });
        })
        .on('receipt', receipt => {
          console.log('receipt', receipt);
          console.log('edition#', receipt.events.SelfServiceEditionCreated.returnValues[0])
          txsHash = receipt.transactionHash;
          commit(mutations.SELF_SERVICE_SUCCESSFUL, {
            transactionHash: receipt.transactionHash,
            account,
            receipt
          });
        })
        .on('error', error => {
          console.log('error', error);
          if (txsHash) {
            commit(mutations.SELF_SERVICE_FAILED, {
              transactionHash: txsHash,
              account,
              error
            });
          } else {
            commit(mutations.SELF_SERVICE_RESET, {account});
          }
        })
        .catch((error) => {
          console.log('catch', error);
          if (txsHash) {
            commit(mutations.SELF_SERVICE_FAILED, {
              transactionHash: txsHash,
              account,
              error
            });
          } else {
            commit(mutations.SELF_SERVICE_RESET, {account});
          }
        })
        .finally(() => {
          // Do something else
        });
    },

  }
};

export default selfServiceStateModule;
