<template>
  <div class="current-network">
    Network: {{ currentNetwork }}
  </div>
</template>

<script>
  /* global web3:true */

  import {mapState} from 'vuex';

  export default {
    name: 'currentNetwork',
    computed: {
      ...mapState(['currentNetwork']),
    },
  };
</script>

<style scoped>

  .current-network {
    font-size: 0.75rem;
  }
</style>
