import {AXIOS_CONFIG, getApi} from '../../utils';
import axios from 'axios';

export default class ArtistApiService {

  constructor(currentNetworkId = 1) {
    this.currentNetworkId = currentNetworkId;
    this.api = getApi();
  }

  async loadArtistsData() {
    console.log(`Loading artist data on network`);
    const results = await axios.get(`${this.api}/artist/all`, AXIOS_CONFIG);
    if (results.data) {
      return results.data;
    }
    return [];
  }

  /*
  async instaData() {
    const result = (await axios.get(`${this.api}/artist/insta`, AXIOS_CONFIG))
    return result && result.data
  }
  */

}
