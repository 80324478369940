import {gql} from "@apollo/client";

export const ARTIST_STATS = gql`
  query artistStats($address: ID!) {
    artistStats: artist(id: $address) {
      address
      totalValueInEth
      highestSaleValueInEth
      salesCount
      supply
      firstEditionTimestamp
      lastEditionTimestamp
      editionsCount
      giftsCount
      firstEditionTimestamp
    }
  }
`;

export const BID_ACCEPTED_EVENTS = gql`
  query bidAcceptedEvents($editionIds: [String!]) {
    bidAcceptedEvents: activityEvents(where: {eventType: "BidAccepted", editionId_in: $editionIds}, orderBy: timestamp, orderDirection: desc) {
      id 
      editionId
      type
      eventType
      edition {
        id
        endDate
      }
      timestamp
      eventValueInWei
      creator
      creatorCommission
      collaborator
      collaboratorCommission
      transactionHash
      triggeredBy
      buyer
      seller
    }
  }
`;


export const BID_PLACED_EVENTS = gql`
  query bidPlacedEvents($editionIds: [String!]) {
    bidPlacedEvents: activityEvents(where: {eventType: "BidPlaced", editionId_in: $editionIds}, orderBy: timestamp, orderDirection: desc) {
      id 
      editionId
      type
      eventType
      edition {
        id
        endDate
      }
      timestamp
      eventValueInWei
      creator
      creatorCommission
      collaborator
      collaboratorCommission
      transactionHash
      triggeredBy
      buyer
      seller
    }
  }
`;

export const BID_EVENTS = (type) => gql`
  ${type} bidEvents($editionIds: [String!], $eventTypes: [String!]) {
    bidEvents: activityEvents(where: {eventType_in: $eventTypes, editionId_in: $editionIds}, orderBy: timestamp, orderDirection: desc) {
      id 
      editionId
      type
      eventType
      edition {
        id
        endDate
      }
      timestamp
      eventValueInWei
      creator
      creatorCommission
      collaborator
      collaboratorCommission
      transactionHash
      triggeredBy
      buyer
      seller
    }
  }
`;

export const TOKENS = (type='query') => gql`
  ${type} tokens($editionIds: [String!]) {
    tokens: tokens(where: {editionNumber_in: $editionIds}, orderBy: editionNumber, orderDirection: asc) {
      id
      tokenId
      transferCount
      editionNumber
      edition { 
        id 
        endDate
      }
      tokenURI
      metadata
      birthTimestamp
      primaryValueInEth
      totalPurchaseValue
      totalPurchaseCount
      lastSalePriceInEth
      lastTransferTimestamp
      currentOwner { address }
      allOwners
      transfers
      tokenEvents
      openOffer {
        id
        token
        edition
        ownerAtTimeOfBid { address }
        bidder { address }
        ethValue
        weiValue
        timestamp
      }
      currentTopBidder
      artistAccount
      editionTotalAvailable
      editionActive
      isListed
      listPrice
      lister
      listingTimestamp
    }
  }
`;


// Getting endDate updates for overtime is all we care about for now
// editionNmber is a typo until the new subgraph is pushed
export const EDITIONS = (type='query') => gql`
  ${type} editions($editionIds: [String!]) {
    editions: editions(where: {editionNmber_in: $editionIds}, orderBy: id, orderDirection: asc) {
      id
      endDate
    }
  }
`;


//    return contract.tokenOffer(tokenId)
//      .then((offer) => {
//        return {
//          edition: edition,
//          token: tokenId,
//          enabled: offer._enabled,
//          value: offer._offer.toString(10),
//          controller: Web3.utils.toChecksumAddress(offer._owner),
//          highestBidder: Web3.utils.toChecksumAddress(offer._bidder),
//          highestBid: Web3.utils.fromWei(offer._offer.toString(10), 'ether'),
//          highestBidWei: offer._offer.toString(10)
//        };

export const AUCTIONS = (type='query') => gql`
  ${type} offers($editionIds: [String!]) {
    offers: offers(where: {edition_in: $editionIds, isActive: true}, orderBy: id, orderDirection: desc) {
      id
      type
      token { id }
      edition { 
        id 
        endDate
      }
      currentOwner { address }
      bidder { address }
      ethValue
      weiValue
      timestamp
      transactionHash
      isActive
      secondaryMarketVersion
    }
  }
`;



export const ARTIST_LEADERBOARDS = gql`
  {
    mostEth: artists(first: 100, orderBy: totalValueInEth, orderDirection: desc, where: {totalValueInEth_gt: 0}) {
      address
      totalValueInEth
      salesCount
      editionsCount
      firstEditionTimestamp
      lastEditionTimestamp
      supply
      highestSaleValueInEth
    }
  }
`;

//export const PURCHASE_EVENTS = gql`
//{
//  query activityEvents($editionIds: [where: {buyer: "0x0f48669B1681D41357EAc232F516B77D0c10F0F1", eventType_in: ["Purchase", "BidAccepted"]}) {
//    edition {
//      id
//    }
//    token {
//      id
//    }
//    type
//    eventType
//    eventValueInWei
//    transactionHash
//  }
//}
//`;
//
//
// Examples
// export const PURCHASE_EVENTS = gql`
// {
//   activityEvents(where: {buyer: "0x0f48669B1681D41357EAc232F516B77D0c10F0F1", eventType_in: ["Purchase", "BidAccepted"]}) {
//     edition {
//       id
//     }
//     token {
//       id
//     }
//     type
//     eventType
//     eventValueInWei
//     transactionHash
//   }
// }
// `;
// 
// export const EDITION_BIDDING_HISTORY = gql`
// {
//   editionsWithActiveBidsAndHistory: editions(where: {activeBid_not: null}) {
//     id
//     biddingHistory {
//       name
//       timestamp
//       ethValue
//     }
//     activeBid {
//       name
//       timestamp
//       ethValue
//     }
//   }
// }
// `
// 
// export const EDITION_AUCTION_HISTORY = gql`
// {
//   auctionHistoryForEdition: auctionEvents(where: {edition: "12000"}) {
//     id
//     name
//     bidder
//     caller
//     timestamp
//     ethValue
//     edition {
//       id
//       metadata {
//         name
//         description
//       }
//     }
//   }
// }
// `
// 
// export const TOP_TOKENS = gql`
// {
//   tokens(first: 100, orderBy: primaryValueInEth, orderDirection: desc) {
//     tokenId
//     primaryValueInEth
//     birthTimestamp
//     metadata {
//       name
//       image
//       artist
//     }
//   }
// }
// `
// 
// export const LATEST_EDITION = gql`
// {
//   editions(orderBy: createdTimestamp, orderDirection: desc) {
//     id
//     createdTimestamp
//     tokenIds
//     artistAccount
//     priceInWei
//     totalSupply
//     active
//     metadata {
//       id
//       name
//       description
//       artist
//       scarcity
//       tags
//     }
//   }
// }
// `
// 
// export const EDITION_ACTIVITY = gql`
// {
//   activityEvents(where: {edition_in: ["163825"], type: "EDITION"}) {
//     id
//     type
//     eventType
//     edition {
//       id
//     }
//     timestamp
//     eventValueInWei
//     creator
//     creatorCommission
//     collaborator
//     collaboratorCommission
//     transactionHash
//     triggeredBy
//     buyer
//     seller
//   }
// }
// `
// 
// export const EDITION_ACTIVE_BIDS = gql`
// {
//   editionsWithActiveBids: editions(where: {activeBid_not: null}) {
//     id
//     artistAccount
//     metadata {
//       name
//       description
//       image
//     }
//     activeBid {
//       id
//       name
//       bidder
//       caller
//       timestamp
//       ethValue
//     }
//   }
// }
// `
// 
// export const EDITION_OPEN_OFFERS = gql`
// {
//   openOffers: editions(where: {activeBid_not: null}) {
//     id
//     artistAccount
//     tokenURI
//     metadataName
//     metadataArtist
//     artistAccount
//     activeBid {
//       id
//       name
//       bidder
//       caller
//       timestamp
//       ethValue
//       edition {
//         id
//       }
//     }
//   }
// }
// `
// 
// 
// 
// export const ARTIST_MOST_ETH = gql`
// {
//   mostEditions: artists(first: 3, orderBy: editionCreationCount, orderDirection: desc) {
//     address
//     editionCreationCount
//   }
//   biggestSupply: artists(first: 10, orderBy: supply, orderDirection: desc) {
//     address
//     supply
//     salesCount
//   }
//   mostEth: artists(first: 10, orderBy: totalValueInEth, orderDirection: desc) {
//     address
//     totalValueInEth
//   }
// }
// `
// 
// export const ARTIST_ONE_ETH = gql`
// {
//   oneEthClub: artists(first: 1000, where: {totalValueInEth_gt: 1}, orderBy: totalValueInEth, orderDirection: desc) {
//     address
//     totalValueInEth
//   }
// }
// `
// 
// export const BEST_SALES_DAY = gql`
// {
//   days(first: 1, orderBy: totalValue, orderDirection: desc) {
//     id
//     date
//     transferCount
//     totalValue
//     highestGasPrice
//   }
// }
// `
// 
// export const COLLECTORS = gql`
// {
//   mostEth: collectors(first: 100, orderBy: primarySaleEthSpent, orderDirection: desc, where: {primarySaleEthSpent_gt: 0}) {
//     id
//     address
//     firstSeen
//     firstPurchaseTimeStamp
//     primarySaleCount
//     primarySaleEthSpent
//   }
// }
// `
