import artRankings from './artRankings.json'
import collectibleRankings from './collectibleRankings.json'
import tradingCardRankings from './tradingCardRankings.json'

//import axios from 'axios';
//import {getApi, AXIOS_CONFIG} from "../../utils";
import {flatten, uniqBy, mapValues, map, sum} from 'lodash';

export default class RankingsApiService {

  // Yes it looks like garbage. It is just copied straight from the browser URL request
  // (pragma and cache-control headers had to be removed)
  // UPDATE: opensea no long allows CORS
  //async artRankingsRaw() {
  //  return await (await fetch("https://api.opensea.io/graphql/", {"credentials":"include","headers":{"accept":"*/*","accept-language":"en-US,en;q=0.9","content-type":"application/json","sec-fetch-dest":"empty","sec-fetch-mode":"cors","sec-fetch-site":"same-site","x-api-key":"60944e40f19f4139ae80d2d973bdc42d","x-viewer-address":""},"referrer":"https://opensea.io/rankings?sortBy=seven_day_volume&vertical=art","referrerPolicy":"no-referrer-when-downgrade","body":"{\"id\":\"rankingsQuery\",\"query\":\"query rankingsQuery(\\n  $count: Int!\\n  $cursor: String\\n  $sortBy: CollectionSort\\n  $sortAscending: Boolean\\n  $parents: [CollectionSlug!]\\n  $createdAfter: DateTime\\n) {\\n  ...rankings_collections\\n}\\n\\nfragment rankings_collections on Query {\\n  collections(after: $cursor, first: $count, sortBy: $sortBy, sortAscending: $sortAscending, parents: $parents, createdAfter: $createdAfter) {\\n    edges {\\n      node {\\n        createdDate\\n        imageUrl\\n        name\\n        slug\\n        stats {\\n          averagePrice\\n          marketCap\\n          numOwners\\n          sevenDayChange\\n          sevenDayVolume\\n          totalSupply\\n          totalVolume\\n          id\\n        }\\n        id\\n        __typename\\n      }\\n      cursor\\n    }\\n    pageInfo {\\n      endCursor\\n      hasNextPage\\n    }\\n  }\\n}\\n\",\"variables\":{\"count\":100,\"cursor\":null,\"sortBy\":\"SEVEN_DAY_VOLUME\",\"sortAscending\":false,\"parents\":[\"art\"],\"createdAfter\":null}}","method":"POST","mode":"cors"})).json()
  //}

  //async collectibleRankingsRaw() {
  //  return await (await fetch("https://api.opensea.io/graphql/", {"credentials":"include","headers":{"accept":"*/*","accept-language":"en-US,en;q=0.9","content-type":"application/json","sec-fetch-dest":"empty","sec-fetch-mode":"cors","sec-fetch-site":"same-site","x-api-key":"60944e40f19f4139ae80d2d973bdc42d","x-viewer-address":""},"referrer":"https://opensea.io/rankings?sortBy=seven_day_volume&vertical=collectibles","referrerPolicy":"no-referrer-when-downgrade","body":"{\"id\":\"rankingsQuery\",\"query\":\"query rankingsQuery(\\n  $count: Int!\\n  $cursor: String\\n  $sortBy: CollectionSort\\n  $sortAscending: Boolean\\n  $parents: [CollectionSlug!]\\n  $createdAfter: DateTime\\n) {\\n  ...rankings_collections\\n}\\n\\nfragment rankings_collections on Query {\\n  collections(after: $cursor, first: $count, sortBy: $sortBy, sortAscending: $sortAscending, parents: $parents, createdAfter: $createdAfter) {\\n    edges {\\n      node {\\n        createdDate\\n        imageUrl\\n        name\\n        slug\\n        stats {\\n          averagePrice\\n          marketCap\\n          numOwners\\n          sevenDayChange\\n          sevenDayVolume\\n          totalSupply\\n          totalVolume\\n          id\\n        }\\n        id\\n        __typename\\n      }\\n      cursor\\n    }\\n    pageInfo {\\n      endCursor\\n      hasNextPage\\n    }\\n  }\\n}\\n\",\"variables\":{\"count\":100,\"cursor\":null,\"sortBy\":\"SEVEN_DAY_VOLUME\",\"sortAscending\":false,\"parents\":[\"collectibles\"],\"createdAfter\":null}}","method":"POST","mode":"cors"})).json()
  //}
  //
  async artRankingsRaw() {
  }

  async collectibleRankings() {
    return this.parseRankings(await this.collectibleRankingsRaw())
  }

  async artRankings() {
    return this.parseRankings(await this.artRankingsRaw())
  }

  rankings() {
    return uniqBy(flatten([artRankings, collectibleRankings, tradingCardRankings].map(this.parseRankings)), 'slug')
  }

  parseRankings(rankings) {
    return rankings.data.collections.edges.map(e => e.node)
  }

  formatRankings(usdPrice) {
    const rankings = this.rankings()
    const sevenDay = sum(map(rankings, 'stats.sevenDayVolume'))
    const total = sum(map(rankings, 'stats.totalVolume'))

    const volume = {
      sevenDay,
      total,
      sevenDayUSD: (sevenDay * usdPrice),
      totalUSD: (total * usdPrice)
    }

    volume.formatted = mapValues(volume, n => Number(n.toFixed(0)).toLocaleString())

    return volume

  }

}
