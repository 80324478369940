import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import router from '../router';
import * as actions from './actions';
import * as mutations from './mutation';

import { find, isArray, mapValues, omit, keys } from 'lodash';
const _ = { find, isArray, mapValues, omit, keys };
import axios from 'axios';
//import {utils} from 'web3';
import { toBN, toChecksumAddress } from 'web3-utils'; 


import {getApi, getEtherscanAddress, getNetIdString, safeToCheckSumAddress, sleep} from '../utils';

import createLogger from 'vuex/dist/logger';
import createPersistedState from 'vuex-persistedstate';

//import web3Connect from '../services/web3connect';

import {abi, contracts} from 'nrda-contract-tools';

import purchase from './modules/purchase';
import highres from './modules/highres';
import kodaV2 from './modules/kodaV2';
import loading from './modules/loading';
import auction from './modules/auction';
import artistControls from './modules/artistEditionControls';
import selfService from './modules/selfService';
import auth from './modules/auth';

import EventsApiService from '../services/events/EventsApiService';
import ArtistApiService from '../services/artist/ArtistApiService';
import LikesApiService from '../services/likes/LikesApiService';
import FeedApiService from '../services/feed/FeedApiService';
import InfoApiService from '../services/stats/InfoApiService';
import AuctionsApiService from '../services/auctions/AuctionsApiService';
import EditionLookupService from '../services/edition/EditionLookupService';
import AccountService from '../services/account/AccountService';
import RelayService from '../services/relay/RelayService';
import TwitterService from '../services/twitter/TwitterService';
import RankingsService from '../services/opensea/RankingsService';
import notifier from '../services/notifications/bcNotifer';
import SmartwalletService from '../services/smartwallet';

import { cacheObj } from '../services/workers/cachefetch.worker';

Vue.use(Vuex);

const defaultNetwork = 137;
const {firebasePath} = getNetIdString(defaultNetwork);

const store = new Vuex.Store({
  plugins: [
    // This logger caused an extreme slowdown
    // Not sure exactly why, because the master branch did not have this problem
    // Regardless, state can be tracked using the Vue chrome plugin instead
    /*createLogger(),*/
    // This persisted state maintains between reloads
    // Not certain if this is necessary. But could be useful to use in the future.
    createPersistedState({
      key: 'koda',
      paths: [
        'artists',
        'artistLookupCache',
        'purchase.purchaseState'
      ]
    }),
  ],
  modules: {
    kodaV2,
    purchase,
    highres,
    loading,
    auction,
    artistControls,
    selfService,
    auth,
  },
  state: {
    currentNetwork: 'Main',
    currentNetworkId: 1,
    etherscanBase: 'https://etherscan.io',
    etherscanBaseWallet: 'https://etherscan.io',
    // connectivity
    web3: null,
    l2Web3: null,
    relayWeb3: null,
    biconomy: null,
    matic: null,
    dagger: {},
    isOnMatic: false,
    account: null,
    smartwallet: null,
    txInFlight: false,
    balance: {
      // token == amount ready to use on not real (wrapped & in gnosis safe)
      token:'0', 
      wallet:'0',
      // wallet == amount in matic
      walletL2:'0',
      // wallet == amount in native eth wallet 
      walletNative: '0',
      // wrapped amount in native eth wallet, wrapped (weth)
      walletToken: '0',
      bn: {
        token: toBN('0'), 
        wallet: toBN('0'), 
        walletL2: toBN('0'), 
        walletNative: toBN('0'), 
        walletToken: toBN('0') 
      }
    },
    totalOwnedTokens: null,
    currentUsdPrice: null,
    firebasePath, // This is key to data lookups which are split by network

    // artist data
    artists: null,
    artistLookupCache: {},

    // contracts
    NotRealDigitalAssetV2: null,
    TokenMarket: null,
    ArtistAcceptingBids: null,
    ArtistEditionControls: null,
    SelfServiceEditionCuration: null,
    Erc20: null,
    Erc20L2: null,
    RootChain: null,
    RootChainManager: null,

    // All services default to mainnet by default, they get overridden on INIT is=f the network is different
    eventService: new EventsApiService(defaultNetwork),
    likesService: new LikesApiService(defaultNetwork),
    infoService: new InfoApiService(defaultNetwork),
    feedService: new FeedApiService(defaultNetwork),
    editionLookupService: new EditionLookupService(defaultNetwork),
    artistService: new ArtistApiService(defaultNetwork),
    auctionsService: new AuctionsApiService(defaultNetwork),
    accountService: new AccountService(defaultNetwork),
    relayService: new RelayService(defaultNetwork),
    twitterService: new TwitterService(),
    rankingsService: new RankingsService(),
    smartwalletService: new SmartwalletService(),
    notifier: notifier(1),
    allEditions: []
  },
  getters: {
    findArtistsForAddress: (state) => (artistAddress) => {

      if (_.isArray(artistAddress)) {
        const artist = _.find(state.artists, (artist) => {
          if (_.isArray(artist.ethAddress)) {
            return _.find(artist.ethAddress, (address) => {
              return _.find(artistAddress, addressToMatch => safeToCheckSumAddress(address) === addressToMatch);
            });
          }
          return safeToCheckSumAddress(artist.ethAddress) === artistsAddress;
        });

        if (!artist) {
          console.warn(`Unable to find artists [${artistAddress}]`);
        }
        state.artistLookupCache[artistAddress] = artist;

        return artist;
      }

      if (state.artistLookupCache[artistAddress]) {
        return state.artistLookupCache[artistAddress];
      }

      const artistsAddress = safeToCheckSumAddress(artistAddress);

      const artist = _.find(state.artists, (artist) => {
        if (_.isArray(artist.ethAddress)) {
          return _.find(artist.ethAddress, (address) => safeToCheckSumAddress(address) === artistsAddress);
        }
        return safeToCheckSumAddress(artist.ethAddress) === artistsAddress;
      });

      if (!artist) {
        console.warn(`Unable to find artists [${artistAddress}]`);
      }
      state.artistLookupCache[artistAddress] = artist;
      return artist;
    },
    liveArtists: (state) => {
      return state.artists.filter((a) => a.enabled).filter((a) => a.ethAddress).filter((a) => a.enabled);
    },
    isOnMainnet: (state) => {
      if (!state.currentNetwork) {
        return true; // assume on mainnet when loading to prevent a flash of the banner
      }
      return state.currentNetwork === 'Main';
    },
    /*
    // Save for later when we want to support direct matic connection
    isOnMatic: (state) => {
      return [137, 80001].includes(state.currentNetworkId);
    },
    */
    ethAddressLink: (state) => (address) => {
      return `${this.etherscanBase}/address/${address}`;
    }
  },
  mutations: {
    ...vuexfireMutations,

    [mutations.SET_ARTISTS](state, artistData) {
      state.artists = artistData;
      //console.log('state', state)
      // clear cache to force update
      state.artistLookupCache = {};
    },
    [mutations.SET_ACCOUNT](state, {account}) {
      state.account = toChecksumAddress(account);
    },
    [mutations.SET_BALANCE](state, balance) {
      state.balance = balance;
    },
    [mutations.SET_SMARTWALLET](state, {smartwallet, nativeSmartwallet}) {
      console.log('smartwallet: ', smartwallet.address)
      state.smartwallet = smartwallet
      state.nativeSmartwallet = nativeSmartwallet
      //state.account = Web3.utils.toChecksumAddress(account);
    },
    [mutations.SET_CURRENT_NETWORK](state, {network, walletNetwork}) {
      const {id, human, firebasePath} = network
      // Updated services classes if the network is not the default mainnet
      //if (id !== 1) {
      console.log(`Switching network based services to channel - id=[${id}] firebasePath=[${firebasePath}]`);
      state.editionLookupService.setNetworkId(id);
      state.notifier = notifier(id);
      state.auctionsService.setNetworkId(id);
      state.infoService.setNetworkId(id);
      state.feedService.setNetworkId(id);
      state.likesService.setNetworkId(id);
      state.eventService.setNetworkId(id);
      state.accountService.setNetworkId(id);
      state.relayService.setNetworkId(id);
      //}

      state.currentNetworkId = id;
      state.currentNetwork = human;
      state.walletNetwork = walletNetwork;
      state.maticNetworkId = [1, 137].includes(id) ? 137 : 80001;
      state.firebasePath = firebasePath;
    },
    [mutations.SET_USD_PRICE](state, currentUsdPrice) {
      state.currentUsdPrice = currentUsdPrice;
    },
    [mutations.SET_ETHERSCAN_NETWORK](state, {wallet, network}) {
      state.etherscanBase = network;
      state.etherscanBaseWallet = wallet;
    },
    [mutations.SET_TOTAL_OWNED_TOKENS](state, totalOwnedTokens) {
      state.totalOwnedTokens = totalOwnedTokens;
    },
    [mutations.SET_WEB3](state, {web3, l1Web3, l2Web3, relayWeb3}) {
      state.web3 = web3;
      state.l2Web3 = l2Web3;
      state.l1Web3 = l1Web3;
      state.relayWeb3 = relayWeb3;
    },
    [mutations.SET_BICONOMY](state, biconomy) {
      state.biconomy = biconomy;
    },
    [mutations.SET_MATIC](state, { dagger, matic, maticWs, isOnMatic }) {
      state.dagger = dagger;
      state.maticWs = maticWs;
      state.matic = matic;
      state.isOnMatic = isOnMatic;
    },
    [mutations.SET_KODA_CONTRACT](state, {v2, auction, editionControls, selfServiceCuration, tokenMarket, erc20, erc20L2, rootChain, rootChainManager}) {

      state.NotRealDigitalAssetV2      = v2;
      state.ArtistAcceptingBids        = auction;
      state.TokenMarket                = tokenMarket;
      state.ArtistEditionControls      = editionControls;
      state.SelfServiceEditionCuration = selfServiceCuration;
      state.Erc20                      = erc20;
      state.Erc20L2                    = erc20L2;
      state.RootChain                  = rootChain;
      state.RootChainManager           = rootChainManager;


      // For debugging
      //const {
      //  NotRealDigitalAssetV2,
      //  TokenMarket,
      //  ArtistAcceptingBids,
      //  ArtistEditionControls,
      //  SelfServiceEditionCuration,
      //  Erc20,
      //  Erc20L2,
      //  RootChain,
      //  RootChainManager,
      //} = state

      //console.log({
      //  NotRealDigitalAssetV2,
      //  TokenMarket,
      //  ArtistAcceptingBids,
      //  ArtistEditionControls,
      //  SelfServiceEditionCuration,
      //  Erc20,
      //  Erc20L2,
      //  RootChain,
      //  RootChainManager,
      //})
    },
  },
  actions: {
    async [actions.BIND_FIREBASE]({commit, dispatch, state}) {
      //console.log('BIND_FIREBASE1')
      //await sleep(10000)
      if (!window.firebaseInit) {
        //console.log('BIND_FIREBASE2')
        // Firebase initialized in src/main.js
        await Promise.all([
          import("firebase/analytics"),
          import("firebase/auth"),
          import("firebase/firestore"),
          import("firebase/performance")
        ])

        //console.log('BIND_FIREBASE3')

        window.analytics = window.firebase.analytics();
        window.firebase.performance()

        dispatch(actions.BIND_REALTIME);

        window.firebaseInit = true
      }
    },
    [actions.BIND_REALTIME]:  firestoreAction(function ({bindFirestoreRef, state}) {
      const db = this._vm.$firebase.firestore()
      const endpoint = `edition/${state.firebasePath}/data`
      //console.log(endpoint)
      return bindFirestoreRef('allEditions', db.collection(endpoint))
    }),
    async [actions.WALLET_CONNECT_LOGIN]({commit, dispatch, state}) {

      const web3Connect = (await import('../services/web3connect')).default
      // open modal on button click
      const ethereum = await web3Connect.connect();
      await dispatch(actions.INIT_APP, ethereum);
    },
    [actions.GET_USD_PRICE]({commit, state}) {
      const getUsdPrice = () => {
        return state.infoService.getUsdEthPrice()
          .then((usdPrice) => {
            commit(mutations.SET_USD_PRICE, usdPrice);
          });
      };

      // Hack to try 3 times getting the usd price
      getUsdPrice()
        .catch(() => getUsdPrice())
        .catch(() => getUsdPrice());
    },
    async [actions.FORCE_LOGIN_WEB3]({commit, dispatch, state}) {
      const onError = (msg, err) => console.log(msg)

      if (!state.account) {

        if (window.ethereum) {
          try {
            // Request account access if needed
            await ethereum.request({method: 'eth_requestAccounts'});



          } catch (error) {
            onError('Access denied - we need access to your wallet to fully connect to the site', error);
          }

          // Note: Other ways of resetting state are tricky.
          // Much simpler to do full reload
          const reloadAndLogin = () => {
            window.location.reload()
          }

          ethereum.on('chainChanged', reloadAndLogin)
          ethereum.on('accountsChanged', reloadAndLogin)
          await dispatch(actions.INIT_APP, ethereum);

        } else { // Non-dapp browsers...
          onError('Unable to find web3 wallet - try installing MetaMask or Coinbase Wallet');
        }
      }
    },
    async [actions.INIT_APP]({commit, dispatch, state}, ethereum) {


        dispatch(actions.BIND_FIREBASE)

        await state.relayService.init(ethereum)

        const {
          web3,
          relayWeb3, 
          l1Web3, 
          l2Web3, 
          network, 
          l1Network, 
          walletNetwork, 
          biconomy,
          matic
        } = state.relayService.connections


        console.log(`INIT_APP called web3 version ${web3 && web3.version}`);

        const {metaContract} = state.relayService

        commit(mutations.SET_BICONOMY, biconomy) 
        commit(mutations.SET_MATIC, matic);
        commit(mutations.SET_WEB3, {web3, l1Web3, l2Web3, relayWeb3});

        commit(mutations.SET_CURRENT_NETWORK, {network: getNetIdString(network), walletNetwork: getNetIdString(walletNetwork)});
        commit(mutations.SET_ETHERSCAN_NETWORK, {wallet: getEtherscanAddress(walletNetwork), network: getEtherscanAddress(network)});

        // NON-ASYNC action
        let contractsMap = {
          v2: new relayWeb3.eth.Contract(abi.kodaV2, contracts.getKodaV2Address(network)),
          auction: new relayWeb3.eth.Contract(abi.auctionV2, contracts.getAuctionV2Address(network)),
          editionControls: new relayWeb3.eth.Contract(abi.artistControlsV2, contracts.getArtistControlsV2Address(network)),
          selfServiceCuration: new relayWeb3.eth.Contract(abi.selfServiceMinterV4, contracts.getSelfServiceMinterV4Address(network)),
          tokenMarket: new relayWeb3.eth.Contract(abi.tokenMarket, contracts.getTokenMarketAddress(network)),
          erc20L2: new relayWeb3.eth.Contract(abi.weth, contracts.getWethAddress(network)),
          erc20: new l1Web3.eth.Contract(abi.weth, contracts.getWethAddress(l1Network)),
          rootChain: new l1Web3.eth.Contract(abi.rootChain, contracts.getMaticRootChainAddress(l1Network)),
          rootChainManager: new l1Web3.eth.Contract(abi.rootChainManager, contracts.getMaticRootChainManagerAddress(l1Network)),
        }
        
        // Contract names that correspond the name set in the NativeMetaTransaction constructor
        let contractNames = {
          v2: 'NotRealDigitalAssetV2',
          auction: 'ArtistAcceptingBidsV2',
          editionControls: 'ArtistEditionControlsV2',
          selfServiceCuration: 'SelfServiceEditionCurationV4',
          tokenMarket: 'TokenMarketplaceV2'
        }

        // Load account once bootstrapped
        await dispatch(actions.GET_ACCOUNT);

        await dispatch(actions.GET_SMARTWALLET, {network: walletNetwork, web3, relayWeb3});



        ////
        // Wrap contract methods with meta transaction decorator
        /////
        //const skip = ['erc20']
        //Object.keys(contractsMap).forEach(ck => {
        //  if(skip.includes(ck)) { return }
        //  const contract = contractsMap[ck]
        //  contract.name = contractNames[ck]
        //  contractsMap[ck] = metaContract(contract, state)
        //})

        //_.transformValue
        const keys = _.keys(_.omit(contractsMap, ['erc20'])) // (Skip ERC20 contract)
        for(let i=0; i<keys.length; i++) {
          contractsMap[keys[i]].name = contractNames[keys[i]]
          contractsMap[keys[i]] = metaContract(contractsMap[keys[i]], state)
        }

        commit(mutations.SET_KODA_CONTRACT, contractsMap);

        dispatch(actions.GET_BALANCE, state.account);

        dispatch(`kodaV2/${actions.GET_MARKET_APPROVAL}`);
        //dispatch(`kodaV2/${actions.APPROVE_MARKET}`, null, {root: true})

        // Add sleep so we don't bump into free matic endpoint limits
        //await sleep(250)

        dispatch(`auction/${actions.GET_AUCTION_MIN_BID}`);
        // Load auction contract owner
        dispatch(`auction/${actions.GET_AUCTION_OWNER}`);

        //await sleep(250)

        // Load self service details
        dispatch(`selfService/${actions.GET_SELF_SERVICE_CONTRACT_DETAILS}`);

        //await sleep(250)
        // Load control contract owner
        dispatch(`artistControls/${actions.GET_ARTIST_EDITION_CONTROLS_DETAILS}`);

      //} catch (e) {
      //  console.log('ERROR', e);
      //}
    },
    async [actions.GET_SMARTWALLET]({commit, dispatch, state, rootState}, {network, web3, relayWeb3}) {
      state.smartwalletService.init({network, web3, relayWeb3})
      const smartwallet = await state.smartwalletService.walletFor(state.account)

      // Whitelist smartwallet as proxy contract
      const whitelisted = await cacheObj(`biconomy-whitelist`) || {}
      if(!whitelisted[smartwallet.address]) {
        await rootState.relayService.whitelistProxy(smartwallet.address).then(result => {
          cacheObj(`biconomy-whitelist`, {[smartwallet.address]: true}, {merge: true})
        })
      }

      commit(mutations.SET_SMARTWALLET, {smartwallet});

    },
    async [actions.GET_ACCOUNT]({commit, dispatch, state, rootState}) {

      const loadAccountData = (account) => {
        console.log(`Loading data for account [${account}]`);
        commit(mutations.SET_ACCOUNT, {account});
        dispatch(actions.GET_BALANCE, account);


        // when we set the account, if looking at the account page, add the eth address for deeplinking
        if (router.currentRoute.name === 'account' && !router.currentRoute.params.address) {
          router.push({name: 'account', params: {address: account}});
        }

        try {
          dispatch(actions.LOAD_TOTAL_OWNED_TOKENS, account);
          if (state.auction.owner !== account) {
            // We want to avoid calling this action if the logged in account is the contract owner
            // This action loads owned editions directly from contract, which is very slow
            // The artist/owner looks up editions from firebase, via LOAD_EDITIONS_FOR_ARTIST
            dispatch(`kodaV2/${actions.LOAD_EDITIONS_PURCHASED_BY_ACCOUNT}`, {account});
          }
        } catch (e) {
          console.log('Unable to load account assets', e);
        }
      };

      return state.web3.eth.getAccounts((error, accounts) => {
        if (!error) {
          let account = accounts[0];

          // TODO refactor with `onchange` listener
          const accountChangeHandler = () => {
            state.web3.eth.getAccounts((error, updatedAccounts) => {
              if (!error) {
                if (updatedAccounts[0] !== account) {
                  account = updatedAccounts[0];
                  return loadAccountData(account);
                }
              } else {
                console.log(`Error getting accounts`, error);
              }
            });
          };

          // This uses up too many RPC request with maticvigil
          const accountBalanceHandler = () => {
            dispatch(actions.GET_BALANCE, account);
          }

          const refreshHandler = () => {
            //accountBalanceHandler()
            accountChangeHandler()
          }

          // Every few seconds, check if the main account has changed
          setInterval(refreshHandler, 2500);

          if (account) {
            return loadAccountData(account);
          }
        } else {
          console.log(`Error getting accounts`, error);
        }
      });

    },
    async [actions.GET_BALANCE]({commit, dispatch, state, getters, rootState}, account) {
      const { l1Web3, Erc20, l2Web3, smartwallet, matic, currentNetworkId, isOnMatic } = rootState;
      //const erc20     = rootState.Erc20
      //const web3      = rootState.web3;

      //console.log('GET_BALANCE')
      if(Erc20) {
        //const token   = await Erc20.methods.balanceOf(smartwallet.address).call()
        //const tokenAccount    = await matic.balanceOfERC20(account, contracts.getWethAddress(currentNetworkId), {from: account})
        const weth = contracts.getWethAddress(currentNetworkId)

        //const tokenL2 = await l2Web3.eth.getBalance(smartwallet.address);

        //console.log('token', tokenSW, tokenL2)
        //const token = toBN(tokenSW).add(toBN(tokenL2)).toString()

        const [
          token,
          walletL2,
          walletNative,
          walletToken
        ] = await Promise.all([
          matic.balanceOfERC20(smartwallet.address, weth, {from: account}),
          matic.balanceOfERC20(account, weth, {from: account}),
          l1Web3.eth.getBalance(account),
          Erc20.methods.balanceOf(account).call()
        ])

        const balance = {
          token,
          walletL2,
          walletNative,
          walletToken
        }

        //console.log('balance', balance)

        let balSrc = ['walletNative', 'walletToken']
        //let balSrc
        //if (isOnMatic) {
        //  balSrc = ['walletNative', 'walletToken']
        //} else {
        //  balSrc = ['walletNative', 'walletToken']
        //}

        balance.bn = _.mapValues(balance, v => toBN(v))

        // Sum available wallet sources
        balance.wallet = balSrc.reduce((a,b) => balance.bn[a].add(balance.bn[b])).toString()
        balance.bn.wallet = toBN(balance.wallet)

        commit(mutations.SET_BALANCE, balance);
      }
    },
    [actions.LOAD_TOTAL_OWNED_TOKENS]: async function ({commit, dispatch, state}, account) {
      const data = await state.accountService.loadTotalOwnedTokenCount(account);
      commit(mutations.SET_TOTAL_OWNED_TOKENS, data.total);
    },
    [actions.LOAD_ARTISTS]: async function ({commit, state}) {
      // Return from here so views can react
      return state.artistService
        .loadArtistsData()
        .then((artistData) => {
          commit(mutations.SET_ARTISTS, artistData);
        });
    },
    [actions.UPDATE_ARTIST_DATA]: async function ({commit, dispatch, state}, form) {

      const postToApi = (signedMessage) => axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        data: {
          signer: state.account,
          originalMessage: form,
          signedMessage: signedMessage
        },
        url: `${getApi()}/artist/profile/update`,
      });

      return state.web3.eth.personal
        .sign(JSON.stringify(form), state.account)
        .then((signedMessage) => postToApi(signedMessage));
    },
    [actions.REVERSE_RESOLVE_ENS_NAME]: async ({state}, address) => {
      const results = await axios.get(`${getApi()}/network/${state.currentNetworkId}/ens/reverse/${address}`);
      return results.data || null;
    },
    [actions.REVERSE_RESOLVE_USER_NAME]: async ({state}, address) => {
      const results = await axios.get(`${getApi()}/network/${state.currentNetworkId}/accounts/${address}/username`);
      return results.data || null;
    },
    [actions.RESOLVE_ENS_NAME]: async ({state}, ensName) => {
      const results = await axios.get(`${getApi()}/network/${state.currentNetworkId}/ens/resolve/${ensName}`);
      return results.data || null;
    },
    [actions.TRANSFER_TOKEN]({state}, {tokenId, recipient}) {
      let contract = state.NotRealDigitalAssetV2;

      return new Promise((resolve, reject) => {
        contract.methods.safeTransferFrom(state.account, recipient, tokenId)
          .send({from: state.account})
          .on('transactionHash', transaction => {
            console.log('Purchase transaction submitted', transaction);
            resolve(transaction);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
});

export default store;
