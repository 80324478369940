//import notify from 'bnc-notify';

//export default (network = 1) => {
//  return notify({
//    dappId: '81060c81-977e-4041-904a-90e0e724e594',
//    networkId: null,
//    darkMode: true,
//    desktopPosition: "bottomRight",
//  });
//};
export default (network = 1) => {
  return {
    hash: () => {},
    notification: () => {}
  };
};

//export default (network = 1) => {
//  return {}
//  //return notify({
//  //  dappId: null,
//  //  networkId: network,
//  //  darkMode: true,
//  //  desktopPosition: "bottomRight",
//  //});
//};
