import axios from 'axios';
import {getApi, AXIOS_CONFIG} from "../../utils";
import _ from 'lodash';

export default class LikesApiService {

  constructor(currentNetworkId = 1) {
    this.currentNetworkId = currentNetworkId;
    this.api = getApi();
  }

  setNetworkId(currentNetworkId) {
    this.currentNetworkId = currentNetworkId;
  }

  getLikesForEditionAndAccount(editionNumber, account) {
    // console.log(`Loading favourite count for [${editionNumber}] and account [${account}] on network [${this.currentNetworkId}]`);
    return axios
      .get(`${this.api}/network/${this.currentNetworkId}/likes/edition/${editionNumber}/address/${account}`, AXIOS_CONFIG)
      .then((res) => {
        return {
          like: _.get(res.data, 'like'),
          totalEditionLikes: _.get(res.data, 'totalEditionLikes')
        };
      });
  }

  getLikesForEdition(editionNumber) {
    // console.log(`Loading favourite count for [${editionNumber}] on network [${this.currentNetworkId}]`);
    return axios
      .get(`${this.api}/network/${this.currentNetworkId}/likes/edition/${editionNumber}`, AXIOS_CONFIG)
      .then((res) => {
        return {
          like: false,
          totalEditionLikes: _.get(res.data, 'likes')
        };
      });
  }

  getLikesForAccount(account) {
    console.log(`Get likes for account [${account}] on network [${this.currentNetworkId}]`);
    return axios
      .get(`${this.api}/network/${this.currentNetworkId}/likes/address/${account}`, AXIOS_CONFIG)
      .then((res) => {
        const accountFavorites = _.map(_.get(res.data, 'likes', []), 'edition');
        console.log(`Found a total of [${_.size(accountFavorites)}] favorites for your account`);
        return accountFavorites;
      });
  }

  registerLike(editionNumber, account) {
    console.log(`Registering like for [${editionNumber}] and account [${account}] on network [${this.currentNetworkId}]`);
    return axios
      .post(`${this.api}/network/${this.currentNetworkId}/likes`, {
        "edition": editionNumber,
        "address": account
      }, AXIOS_CONFIG)
      .then((res) => {
        return {
          like: _.get(res.data, 'like'),
          totalEditionLikes: _.get(res.data, 'totalEditionLikes')
        };
      });
  }
}
