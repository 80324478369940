
const loadContractAccess = (build) => {

  const kodaV2                      = require(`../${build}/networks/NotRealDigitalAssetV2.json`);
  const auctionV2                   = require(`../${build}/networks/ArtistAcceptingBidsV2.json`);
  const artistControlsV2            = require(`../${build}/networks/ArtistEditionControlsV2.json`);
  const selfServiceMinterV4         = require(`../${build}/networks/SelfServiceEditionCurationV4.json`);
  const selfServiceAccessControlsV1 = require(`../${build}/networks/SelfServiceAccessControls.json`);
  const tokenMarket                 = require(`../${build}/networks/TokenMarketplaceV2.json`);
  const WETH                        = require(`../${build}/networks/WETH9.json`);

  const gnosis = require('../gnosis/networks.json');

  const GnosisSafe  = require('../networks/GnosisSafe.json');
  const ProxyFactory  = require('../networks/ProxyFactory.json');
  const MultiSend  = require('../networks/MultiSend.json');
  const DefaultCallbackHandler  = require('../networks/DefaultCallbackHandler.json');
  const CPKFactory  = require('../networks/CPKFactory.json');

  const gnosisLocal = {
    GnosisSafe,
    GnosisSafeProxyFactory: ProxyFactory,
    MultiSend,
    DefaultCallbackHandler,
    CPKFactory
  }

  // TODO: KODAv2 rename to NRDAv2

  const values = require('lodash/values')

  const contractsMap = {
    kodaV2,
    auctionV2,
    artistControlsV2,
    selfServiceMinterV4,
    selfServiceAccessControlsV1,
    tokenMarket,
  }

  const contracts = values(contractsMap)

  // Set defaults just so we don't get undefined errors
  contracts.forEach(c => {
    if(!c['80001']) { c['80001'] = {} }
    if(!c['5777']) { c['5777'] = {} }
    if(!c['137']) { c['137'] = {} }
  })

  const networkSplitter = (network, {ropsten, rinkeby, goerli, mainnet, matic, maticlive, local}) => {
    switch (network) {
      case 1:
      case '1':
      case 'mainnet':
        return mainnet;
      case 3:
      case '3':
      case 'ropsten':
        return ropsten;
      case 4:
      case '4':
      case 'rinkeby':
        return rinkeby;
      case 5:
      case '5':
      case 'goerli':
        return goerli;
      case 80001:
      case '80001':
      case 'matic':
        // This may change if a clean deploy of KODA locally is not done
        return matic;
      case 137:
      case '137':
      case 'maticlive':
        // This may change if a clean deploy of KODA locally is not done
        return maticlive;
      case 5777:
      case '5777':
      case 'local':
        return local;
      default:
        throw new Error(`Unknown network ID ${network}`);
    }
  };

  const getNetwork = (network) => {
    return networkSplitter(network, {
      mainnet:   'mainnet',
      ropsten:   'ropsten',
      rinkeby:   'rinkeby',
      goerli:    'goerli',
      matic:     'matic',
      maticlive: 'maticlive',
      local:     'local'
    });
  };

  const INFURA_KEY     = 'fb81a1a1f7bb471bb61f80207f2fee26';
  const MATICVIGIL_KEY = '013e160691e8a673b36ff011b49bb6995800e2e5';

  const ethEndpoint   = (network, protocol) => `${protocol}://${network}.infura.io/v3/${INFURA_KEY}`
  const maticEndpoint = (network, protocol, ver) => `${protocol}://rpc-${network}.maticvigil.com/${ver}/${MATICVIGIL_KEY}`

  const rpcEndpoint = (networkId, protocol, ver='v1') => {
    const network = getNetwork(networkId);

    return networkSplitter(network, {
      mainnet:   ethEndpoint(network,     protocol),
      ropsten:   ethEndpoint(network,     protocol),
      rinkeby:   ethEndpoint(network,     protocol),
      goerli:    ethEndpoint(network,     protocol),
      matic:     maticEndpoint('mumbai',  protocol, ver),
      maticlive: maticEndpoint('mainnet', protocol, ver),
      local:     'http://127.0.0.1:7545'
    })
  }

  const rpcHttpEndpoint = (network) => rpcEndpoint(network, 'https')
  const rpcWebsocketEndpoint = (network) => rpcEndpoint(network, 'wss', 'ws/v1')

  //const getKodaV1DeploymentBlock = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: 5381767,
  //    ropsten: 3061645,
  //    rinkeby: 2054121,
  //    matic: 9978340,
  //    maticlive: null,
  //    local: 0
  //  });
  //};
  const getKodaV2DeploymentBlock = (network) => {
    return networkSplitter(network, {
      mainnet: 6270484,
      ropsten: 3970612,
      rinkeby: 2932902,
      matic: null,
      maticlive: null,
      local: 0
    });
  };

  //const getAuctionV1DeploymentBlock = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: 6568535,
  //    ropsten: 4265131,
  //    rinkeby: 3211004,
  //    matic: 9978362,
  //    maticlive: null,
  //   local: 0
  //  });
  //};

  const getAuctionV2DeploymentBlock = (network) => {
    return networkSplitter(network, {
      mainnet: 7271800,
      ropsten: 5096828,
      matic: 9978404,
      maticlive: null,
    rinkeby: 3620647,
      local: 0
    });
  };

  // TODO: Fill addresses
  // Not using TokenMarketV1, so this refers to TokenMarketplaceV2 instead
  const getTokenMarketDeploymentBlock = (network) => {
    return networkSplitter(network, {
      mainnet: null,
      ropsten: null,
      rinkeby: null,
      matic: 9978681,
      maticlive: null,
      local: 0
    });
  };

  //const getArtistsControlsV1DeploymentBlock = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: 6659821,
  //    ropsten: 4379391,
  //    rinkeby: 3293890,
  //    matic: 9978378,
  //    maticlive: null,
  //    local: 0
  //  });
  //};

  const getArtistsControlsV2DeploymentBlock = (network) => {
    return networkSplitter(network, {
      mainnet: 7271760,
      ropsten: 5076082,
      rinkeby: 3919265,
      matic: 9978391,
      maticlive: null,
     local: 0
    });
  };

  //const getSelfServiceMinterV1DeploymentBlock = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: 7526372,
  //    ropsten: 5281837,
  //    rinkeby: 5476277,
  //    matic: 9978415,
  //    maticlive: null,
  //    local: 0
  //  });
  //};
  //
  //const getSelfServiceMinterV2DeploymentBlock = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: 7636779,
  //    ropsten: 5475595,
  //    rinkeby: 5476282,
  //    matic: 9978428,
  //    maticlive: null,
  //    local: 0
  //  });
  //};
  //
  //const getSelfServiceMinterV3DeploymentBlock = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: 7668966,
  //    ropsten: 5507046,
  //    rinkeby: 5476286,
  //    matic: 9978649,
  //    maticlive: null,
  //    local: 0
  //  });
  //};

  const getSelfServiceMinterV4DeploymentBlock = (network) => {
    return networkSplitter(network, {
      mainnet: 9093729,
      ropsten: 6915912,
      rinkeby: 5565942,
      matic: 9978660,
      maticlive: null,
      local: 0
    });
  };

  const getSelfServiceAccessControlsV1DeploymentBlock = (network) => {
    return networkSplitter(network, {
      mainnet: 7636773,
      ropsten: 5475593,
      rinkeby: 5476281,
      matic: 9978638,
      maticlive: null,
      local: 0
    });
  };

  const getKodaV2Address = (network) => {
    return networkSplitter(network, {
      mainnet: '0xfbeef911dc5821886e1dda71586d90ed28174b7d',
      ropsten: '0x29a3f97e9ac395e2e1bfa789bbbbb5468e6022af',
      rinkeby: '0x2df6816286c583a7ef8637cd4b7cc1cc62f6161e',
      matic:     kodaV2['80001'].address,
      maticlive: kodaV2['137'].address,
      local: kodaV2['5777'].address
    });
  };

  //const getAuctionV1Address = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: '0x921ade9018Eec4a01e41e80a7eeBa982B61724Ec',
  //    ropsten: '0x159Fd194fc15CEfBEd9b3F95bD977031aec8B91C',
  //    rinkeby: '0xC68373E4BCCA5630A82b35b989e9F4Adb1d375c8',
  //    matic: auctionV1['80001'].address,
  //    maticlive: null,
  //    local: auctionV1['5777'].address
  //  });
  //};


  const getAuctionV2Address = (network) => {
    return networkSplitter(network, {
      mainnet: '0x848b0ea643e5a352d78e2c0c12a2dd8c96fec639',
      ropsten: '0x55F184620659aF703650724178A1B2Dc39247d7A',
      rinkeby: '0xb05DA17AA04ac6f57821513572fb7760a203cd98',
      matic:     auctionV2['80001'].address,
      maticlive: auctionV2['137'].address,
      local: auctionV2['5777'].address
    });
  };

  // TODO: Fill addresses
  const getTokenMarketAddress = (network) => {
    return networkSplitter(network, {
      mainnet: '',
      ropsten: '',
      rinkeby: '',
      matic:     tokenMarket['80001'].address,
      maticlive: tokenMarket['137'].address,
      local: tokenMarket['5777'].address
    });
  };

  //const getKodaV1Address = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: '0xdde2d979e8d39bb8416eafcfc1758f3cab2c9c72',
  //    ropsten: '0x986933d91344c7b4f98c747f8a7c98f0ce27cee2',
  //    rinkeby: '0xf0d6a41a3f011e06260f9133101b82b405539167',
  //    matic: kodaV1['80001'].address,
  //    local: kodaV1['5777'].address
  //  });
  //};

  //const getArtistControlsV1Address = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: '0x06c741e6df49d7fda1f27f75fffd238d87619ba1',
  //    ropsten: '0x6ffde0f6d2b623c5f6fec29dcffb6f91faf8b224',
  //    rinkeby: '0x73ab17b11ec1e4fa18934115f9d170cbf0d5c6c6',
  //    matic: artistControlsV1['80001'].address,
  //    local: artistControlsV1['5777'].address
  //  });
  //};

  const getArtistControlsV2Address = (network) => {
    return networkSplitter(network, {
      mainnet: '0x5327cf8b4127e81013d706330043e8bf5673f50d',
      ropsten: '0x15C73806D6944Fc03aBBA67d43548377977968EB',
      rinkeby: '0xC2d4Df7b146a5e5898e66DAa759213882e06dE59',
      matic:     artistControlsV2['80001'].address,
      maticlive: artistControlsV2['137'].address,
      local: artistControlsV2['5777'].address
    });
  };

  //const getSelfServiceMinterV1Address = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: '0x8ab96f7b6c60df169296bc0a5a794cae90493bd9',
  //    ropsten: '0x90025F59BB0F1F4Fb6Db8696798FbD9eBe24A4cD',
  //    rinkeby: '0x20ad639D07B8626a47b9B67d73D3Aae61f83B511',
  //    matic: selfServiceMinterV1['80001'].address,
  //    local: selfServiceMinterV1['5777'].address
  //  });
  //};
  //
  //const getSelfServiceMinterV2Address = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: '0xff043a999a697fb1efdb0c18fd500eb7eab4e846',
  //    ropsten: '0x171b37442e527e82A13984cD5Aa92f01a9F29b21',
  //    rinkeby: '0x6ddd236A53979e4fbB49a32a43395fd3b76e140f',
  //    matic: selfServiceMinterV2['80001'].address,
  //    local: selfServiceMinterV2['5777'].address
  //  });
  //};
  //
  //const getSelfServiceMinterV3Address = (network) => {
  //  return networkSplitter(network, {
  //    mainnet: '0x50782A63b7735483bE07ef1C72D6d75e94b4a8F6',
  //    ropsten: '0x328152B0b22fb2431E5E4A8d55f191d7D5fF2C72',
  //    rinkeby: '0x8e1ED436366e0226734dD596d25B65cEB3ffDb63',
  //    matic: selfServiceMinterV3['80001'].address,
  //    local: selfServiceMinterV3['5777'].address
  //  });
  //};

  const getSelfServiceMinterV4Address = (network) => {
    return networkSplitter(network, {
      mainnet: '0xBd679aD9dd6e65B7e447b3C2707711d7dA4cB783',
      ropsten: '0x93C42bFef2a3e229C1dd1F62D176504f6f75dA4b',
      rinkeby: '0x9495997eaa3bF58A63407bE1Ef771cAc7D6a204C',
      matic:     selfServiceMinterV4['80001'].address,
      maticlive: selfServiceMinterV4['137'].address,
      local: selfServiceMinterV4['5777'].address
    });
  };

  const getSelfServiceAccessControlsV1Address = (network) => {
    return networkSplitter(network, {
      mainnet: '0xec133df5d806a9069aee513b8be01eeee2f03ff0',
      ropsten: '0x70fd51b73775e0eAE11d5bf3D1fBB895404fDcB8',
      rinkeby: '0x1c9817aEdeC8Af91e439c228b70AEb9145ae5508',
      matic:     selfServiceAccessControlsV1['80001'].address,
      maticlive: selfServiceAccessControlsV1['137'].address,
      local: selfServiceAccessControlsV1['5777'].address
    });
  };

  const getGnosisAddress = (contract, network) => {
    const local = gnosisLocal[contract]['5777']
    return networkSplitter(network, {
      mainnet: gnosis[contract]['1'].address,
      rinkeby: gnosis[contract]['4'].address,
      goerli: gnosis[contract]['5'].address,
      // In theory, gnosis safe deployment address should be deterministic, consistent and same for matic
      matic: gnosis[contract]['1'].address,
      maticlive: gnosis[contract]['1'].address,
      local: local && local.address
    })
  }

  // Local WETH address is deployed locally in contract deployment/migration scripts
  const getWethAddress = (network) => {
    return networkSplitter(network, {
      mainnet: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      maticlive: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      rinkeby: '0xc778417e063141139fce010982780140aa0cd5ab',
      ropsten: '0xbCA556c912754Bc8E7D4Aad20Ad69a1B1444F42d',
      kovan: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
      goerli: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
      matic: '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa',
      //matic: '0xE8F3118fDB41edcFEF7bF1DCa8009Fa8274aa070',
      //mainnet: '0xa45b966996374E9e65ab991C6FE4Bfce3a56DDe8',
      local: WETH['5777'] && WETH['5777'].address
    })
  }

  const getMaticRootChainManagerAddress = (network) => {
    return networkSplitter(network, {
      mainnet: '0xA0c68C638235ee32657e8f720a23ceC1bFc77C77',
      goerli: '0xBbD7cBFA79faee899Eaf900F13C9065bF03B1A74',
    })
  }

  const getMaticChildChainManagerAddress = (network) => {
    return networkSplitter(network, {
      maticlive: '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa',
      matic: '0xb5505a6d998549090530911180f38aC5130101c6',
    })
  }

  const getMaticRootChainAddress = (network) => {
    return networkSplitter(network, {
      mainnet: '0x86E4Dc95c7FBdBf52e33D563BbDB00823894C287',
      goerli: '0x2890bA17EfE978480615e330ecB65333b880928e',
    })
  }

  const getMaticChildAddress = (network) => {
    return networkSplitter(network, {
      matic: '0xb5505a6d998549090530911180f38aC5130101c6',
      maticlive: '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa'
    })
  }

  // For printing contract addresses
  //console.log(_.map(contractsMap, (c,n) => [n, c['80001'].address]))

  const contractAccess = {
    getNetwork,
    //getKodaV1Address,
    getKodaV2Address,
    //getAuctionV1Address,
    getAuctionV2Address,
    getTokenMarketAddress,
    //getArtistControlsV1Address,
    getArtistControlsV2Address,
    //getSelfServiceMinterV1Address,
    //getSelfServiceMinterV2Address,
    //getSelfServiceMinterV3Address,
    getSelfServiceMinterV4Address,
    getSelfServiceAccessControlsV1Address,

    //getKodaV1DeploymentBlock,
    getKodaV2DeploymentBlock,
    //getAuctionV1DeploymentBlock,
    getAuctionV2DeploymentBlock,
    getTokenMarketDeploymentBlock,
    //getArtistsControlsV1DeploymentBlock,
    getArtistsControlsV2DeploymentBlock,
    //getSelfServiceMinterV1DeploymentBlock,
    //getSelfServiceMinterV2DeploymentBlock,
    //getSelfServiceMinterV3DeploymentBlock,
    getSelfServiceMinterV4DeploymentBlock,
    getSelfServiceAccessControlsV1DeploymentBlock,
    rpcHttpEndpoint,
    rpcWebsocketEndpoint,
    getGnosisAddress,
    getWethAddress,
    getMaticChildAddress,
    getMaticRootChainAddress,
    getMaticRootChainManagerAddress,
    getMaticChildChainManagerAddress
  };

  return contractAccess
}

module.exports = loadContractAccess
