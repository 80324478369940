// setup calls
export const INIT_APP = 'INIT_APP';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_BALANCE = 'GET_BALANCE';
export const FORCE_LOGIN_WEB3 = 'FORCE_LOGIN_WEB3';
export const GET_SMARTWALLET = 'GET_SMARTWALLET';

// Web3 lookups
export const GET_CURRENT_NETWORK = 'GET_CURRENT_NETWORK';

export const WALLET_CONNECT_LOGIN = 'WALLET_CONNECT_LOGIN';

// External API calls
export const GET_USD_PRICE = 'GET_USD_PRICE';

///////////////
// User Auth //
///////////////
export const WEB3_AUTH_LOGIN = 'WEB3_AUTH_LOGIN';
export const WEB3_AUTH_LOAD_PROFILE = 'WEB3_AUTH_LOAD_PROFILE';
export const WEB3_AUTH_SAVE_PROFILE = 'WEB3_AUTH_SAVE_PROFILE';
export const WEB3_AUTH_CHECK_DUPLICATE_USERNAME = 'WEB3_AUTH_CHECK_DUPLICATE_USERNAME';
export const WEB3_AUTH_ACCOUNT_HAS_PROFILE = 'WEB3_AUTH_ACCOUNT_HAS_PROFILE';
export const CHECK_USER_SIGNUP_PROMPT = 'CHECK_USER_SIGNUP_PROMPT';
export const DISMISS_USER_SIGNUP_PROMPT = 'DISMISS_USER_SIGNUP_PROMPT';

//////////////////////
// Artist/User Data //
//////////////////////
export const UPDATE_ARTIST_DATA = 'UPDATE_ARTIST_DATA';
export const LOAD_ARTISTS = 'LOAD_ARTIST_DATA';
export const RESOLVE_ENS_NAME = 'RESOLVE_ENS_NAME';
export const REVERSE_RESOLVE_ENS_NAME = 'REVERSE_RESOLVE_ENS_NAME';
export const REVERSE_RESOLVE_USER_NAME = 'REVERSE_RESOLVE_USER_NAME';
export const LOAD_TOTAL_OWNED_TOKENS = 'LOAD_TOTAL_OWNED_TOKENS';

///////////////////
// V2 Page Loads //
///////////////////

export const LOAD_EDITIONS = 'LOAD_EDITIONS';
export const LOAD_EDITIONS_FOR_TYPE = 'LOAD_EDITIONS_FOR_TYPE';
export const LOAD_EDITIONS_FOR_ARTIST = 'LOAD_EDITIONS_FOR_ARTIST';
export const LOAD_INDIVIDUAL_EDITION = 'LOAD_INDIVIDUAL_EDITION';
export const REFRESH_AND_LOAD_INDIVIDUAL_EDITION = 'REFRESH_AND_LOAD_INDIVIDUAL_EDITION';
export const REFRESH_OPEN_OFFERS = 'REFRESH_OPEN_OFFERS';

///////////////////
// Market Approval //
///////////////////

export const GET_MARKET_APPROVAL = 'GET_MARKET_APPROVAL';
export const APPROVE_MARKET = 'APPROVE_MARKET';

///////////////////
// LOADING STATE //
///////////////////

export const LOADING_STARTED = 'LOADING_STARTED';
export const LOADING_FINISHED = 'LOADING_FINISHED';

//////////////////////
// Purchase flow V2 //
//////////////////////

export const RESET_PURCHASE_STATE = 'RESET_PURCHASE_STATE';
export const PURCHASE_EDITION = 'PURCHASE_EDITION';
export const CHECK_IN_FLIGHT_TRANSACTIONS = 'CHECK_IN_FLIGHT_TRANSACTIONS';

////////////////////
// Shared V1 & V2 //
////////////////////
export const HIGH_RES_DOWNLOAD = 'HIGH_RES_DOWNLOAD';
export const REFRESH_CONTRACT_DETAILS = 'REFRESH_CONTRACT_DETAILS';
export const LOAD_EDITIONS_PURCHASED_BY_ACCOUNT = 'LOAD_EDITIONS_PURCHASED_BY_ACCOUNT';

/////////////
// Auction //
/////////////
export const GET_AUCTION_OWNER = 'GET_AUCTION_OWNER';
export const GET_AUCTION_MIN_BID = 'GET_AUCTION_MIN_BID';
export const GET_AUCTION_DETAILS_FOR_ARTIST = 'GET_AUCTION_DETAILS_FOR_ARTIST';
export const GET_AUCTION_DETAILS_FOR_EDITIONS = 'GET_AUCTION_DETAILS_FOR_EDITIONS';
export const GET_AUCTION_DETAILS = 'GET_AUCTION_DETAILS';
export const REFRESH_AUCTION_DETAILS = 'REFRESH_AUCTION_DETAILS';
export const PLACE_BID = 'PLACE_BID';
export const INCREASE_BID = 'INCREASE_BID';
export const ACCEPT_BID = 'ACCEPT_BID';
export const WITHDRAW_BID = 'WITHDRAW_BID';
export const CANCEL_AUCTION = 'CANCEL_AUCTION';
export const REJECT_BID = 'REJECT_BID';
export const MINT_TOKEN = 'MINT_TOKEN';

/////////////////////
// Artist Controls //
/////////////////////
export const GET_ARTIST_EDITION_CONTROLS_DETAILS = 'GET_ARTIST_EDITION_CONTROLS_DETAILS';
export const UPDATE_EDITION_PRICE = 'UPDATE_EDITION_PRICE';
export const GIFT_EDITION = 'GIFT_EDITION';
export const TRANSFER_TOKEN = 'TRANSFER_TOKEN';

//////////////////
// Self Service //
//////////////////
export const GET_SELF_SERVICE_CONTRACT_DETAILS = 'GET_SELF_SERVICE_CONTRACT_DETAILS';
export const GET_SELF_SERVICE_ENABLED_FOR_ACCOUNT = 'GET_SELF_SERVICE_ENABLED_FOR_ACCOUNT';
export const CREATE_SELF_SERVICE_EDITION = 'CREATE_SELF_SERVICE_EDITION';

export const BIND_FIREBASE = 'BIND_FIREBASE';
export const BIND_REALTIME= 'BIND_REALTIME';
