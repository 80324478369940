import './registerServiceWorker'
import setContractBuild from 'nrda-contract-tools';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigs = {
  'beta-not-real-co': {
    apiKey: "AIzaSyAdgAsyWp-f45VQdunoJVytFe4zX-3dW-U",
    authDomain: "beta-not-real-co.firebaseapp.com",
    databaseURL: "https://beta-not-real-co.firebaseio.com",
    projectId: "beta-not-real-co",
    storageBucket: "beta-not-real-co.appspot.com",
    messagingSenderId: "461361173336",
    appId: "1:461361173336:web:d9a54d1992f22110640b2d",
    measurementId: "G-KJKPE5R0QZ"
  },
  'not-real-co': {
    apiKey: "AIzaSyCvqqOFdRJnZfXrOHnfkqab9CHs6J8LwT4",
    authDomain: "not-real-co.firebaseapp.com",
    databaseURL: "https://not-real-co.firebaseio.com",
    projectId: "not-real-co",
    storageBucket: "not-real-co.appspot.com",
    messagingSenderId: "179093565651",
    appId: "1:179093565651:web:195f90d65b6f8d07e46426",
    measurementId: "G-JMYBE4M93E"
  }
}


import firebase from "firebase/app";
import "firebase/remote-config";
window.firebase = firebase;

//const beta = window.location.port == 8081 || window.location.hostname.includes('beta-not-real-co')
// For now just point to beta-not-real-co on firebase for production
const beta = true
const config = beta ? 'beta-not-real-co' : 'not-real-co';


setContractBuild('build/contracts')
// Initialize Firebase
// console.log('fb config', firebaseConfigs[config])
const app = firebase.initializeApp(firebaseConfigs[config]);

//window.firestore = app.firestore()

window.firebaseRemoteConfig = firebase.remoteConfig();
window.firebaseRemoteConfig.settings = {
    minimumFetchIntervalMillis: 600000,
}

window.firebaseRemoteConfig.defaultConfig = ({
  'version': ''
})
window.firebaseRemoteConfig.fetchAndActivate()

//const perf = firebase.performance()

//if (navigator.serviceWorker) {
//  navigator.serviceWorker.getRegistrations().then( function(registrations) { for(let registration of registrations) { registration.unregister(); } });
//}


// This pollyfill is required for some browsers
import 'intersection-observer';
import {fromWei} from 'web3-utils';
import {
  startCase,
  truncate,
  merge,
  mapValues
} from 'lodash';

import Vue from 'vue';
//import BootstrapVue from 'bootstrap-vue';
import Vue2Filters from 'vue2-filters';
import VueLazyload from 'vue-lazyload';
import VueMoment from 'vue-moment';

import VueIntro from 'vue-introjs';

//import VueAnalytics from 'vue-analytics';
import vmodal from 'vue-js-modal';

// Apollo gql magic
//import VueApollo from 'vue-apollo';
//import apolloProvider from './apollo'
import VueApollo from 'vue-apollo';
//import { ApolloClient, InMemoryCache } from '@apollo/client/core';
//import { WebSocketLink } from '@apollo/client/link/ws';
//import { HttpLink, split } from '@apollo/client';
//import { getMainDefinition } from '@apollo/client/utilities';


// Add fontawesome
import {library} from '@fortawesome/fontawesome-svg-core';
import {faTwitter, faMedium, faTelegramPlane, faEthereum, faInstagram, faDiscord} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelopeSquare,
  faSpinner,
  faCube,
  faCog,
  faPalette,
  faBolt,
  faReceipt,
  faDownload,
  faTimes,
  faHeart as fasHeart,
  faInfoCircle,
  faLock,
  faLockOpen
} from '@fortawesome/free-solid-svg-icons';

import {faHeart, faQuestionCircle} from '@fortawesome/free-regular-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import VueGtag from "vue-gtag";

library.add(faTelegramPlane, faTwitter, faInstagram, faMedium, faEnvelopeSquare, faEthereum, faSpinner, faCube, faCog, faPalette, faBolt, faReceipt, faDownload, faTimes, faInfoCircle, faHeart, fasHeart, faLock, faLockOpen, faQuestionCircle, faDiscord);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueIntro);

Vue.use(VueLazyload, {
  // set observer to true
  observer: true,
  loading: '/static/64px_missingImageIcon.png',
  lazyComponent: true
});

Vue.use(VueGtag, {
  config: { id: "G-B71THWP904" }
});


/*
Vue.use(VueAnalytics, {
  id: 'UA-156567456-1',
  router,
  autoTracking: {
    exception: true
  },
  batch: {
    enabled: true // default 2 every 500ms
  },
  debug: {
    // set to false to disable GA - locally NODE_ENV set to `development`
    sendHitTask: _.get(process.env, 'NODE_ENV', 'production') === 'production'
  }
});
*/

//Vue.use(BootstrapVue);
Vue.use(VueMoment);
Vue.use(Vue2Filters);
Vue.use(VueApollo);
Vue.use(vmodal, {dynamic: true, injectModalsContainer: true});

Vue.config.productionTip = (process.env.NODE_ENV === 'production');
Vue.config.silent = false;

Vue.filter('toEth', function (value) {
  if (!value) return '';
  return fromWei(value.toString(), 'ether').valueOf();
});

Vue.filter('to4Dp', function (value) {
  if (!value && value !== 0) return '';
  return parseFloat(value).toFixed(4);
});

Vue.filter('to3Dp', function (value) {
  if (!value) return '';
  return parseFloat(value).toFixed(3);
});

Vue.filter('to2Dp', function (value) {
  if (!value) return '';
  return parseFloat(value).toFixed(2);
});

Vue.filter('to0Dp', function (value) {
  if (!value) return '';
  return parseFloat(value).toFixed(0);
});

Vue.filter('humanize', function (value) {
  if (!value) return '';
  return startCase(value);
});

Vue.filter('truncate', function (value, length = 30) {
  if (!value) return '';
  return truncate(value, {length: length});
});

Vue.filter('dotDotDot', function (value) {
  if (!value) return '';
  if (value) {
    return value.substr(0, 6) + '...' + value.substr(value.length - 6, value.length);
  }
  return "";
});

Vue.directive('visible', function(el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

//const graphBase = {
//  local: "http://localhost:8000/subgraphs/name",
//  http: "https://api.thegraph.com/subgraphs/name",
//  ws: "wss://api.thegraph.com/subgraphs/name",
//}
//
//const graphName = {
//  mumbai: 'not-real-cats-mumbai',
//  matic: 'not-real-cats'
//}
//
//
//const uri = `${graphBase.hosted}/notrealai/${graphName.mumbai}`
//
//const httpLink = new HttpLink({
//  uri: `${graphBase.http}/notrealai/${graphName.mumbai}`,
//})
//
//const wsLink = new WebSocketLink({
//  uri: `${graphBase.ws}/notrealai/${graphName.mumbai}`,
//  options: {
//    reconnect: true
//  }
//})
//
//const link = split(
//  // split based on operation type
//  ({ query }) => {
//    const definition = getMainDefinition(query)
//    return definition.kind === 'OperationDefinition' &&
//      definition.operation === 'subscription'
//  },
//  wsLink,
//  httpLink
//)
//
//const client = new ApolloClient({
//  link,
//  cache: new InMemoryCache(),
//  connectToDevTools: true
//});
//
//const apolloProvider = new VueApollo({
//  defaultClient: client
//});


//const versions = {
//  ['notreal.ai']: 'notreal',
//  ['notrealcats.com']: 'notrealcats',
//}

const configOverrides = () => {
  return {version: 'market'}
}

Vue.prototype.$firebase = window.firebase

let vueApp = null

import store from './store';
import router from './router';
import logging from './logging';
import { apolloProvider } from './apollo'
import App from './App';

const initApp = () => {
  // Don't Re-initalize multiple times yo!
  if (vueApp) { return }
  Vue.prototype.$build = window.build
  Vue.prototype.$ga = { event: (evt, hitType, eventCategory, eventAction, eventLabel, eventValue) => {
    return window.analytics.logEvent(evt, {
      hitType,
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    })
  }}

  vueApp = new Vue({
    store,
    router,
    logging,
    apolloProvider,
    render: h => h(App)
  }).$mount('#app')
}


// We want to make sure the app gets initialized no matter what,
// even if firebase is unavailable or there is an error
if (window.firebaseRemoteConfig) {
  window.firebaseRemoteConfig.ensureInitialized().then(() => {
    const remoteConfig = mapValues(window.firebaseRemoteConfig.getAll(), '_value')
    Vue.prototype.$remoteConfig = merge(remoteConfig, configOverrides())
    initApp()
  }).catch(() => {
    Vue.prototype.$remoteConfig = configOverrides()
    initApp()
  })
} else {
  Vue.prototype.$remoteConfig = configOverrides()
  initApp()
}
