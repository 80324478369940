<template>
  <div>

    <header-bar v-if="!$route.name == 'home'"></header-bar>
    <!--
    <header class="header-navbar">
      <b-navbar toggleable="md" class="navbar-position pl-3 pt-1 pr-3 pb-1 z-2">
        <b-navbar-brand :to="{ name: 'home' }">
          <span class="notreal-logo">
            <b>not</b> <br />
            <b>real.</b>
          </span>
        </b-navbar-brand>
        <b-navbar-toggle class="pr-0" target="collapseNav"></b-navbar-toggle>
        <b-collapse is-nav id="collapseNav">
          <b-navbar-nav class="ml-auto">
            <web3-connect-navbar-login></web3-connect-navbar-login>
            <router-link :to="{ name: 'gallery' }" class="nav-link">Gallery</router-link>
            <router-link :to="{ name: 'studio' }" class="nav-link">Studio</router-link>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <network-warning-banner></network-warning-banner>
    </header>
    -->

    <main role="main" class="container-fluid pt-0 px-0 pb-3 overflow-hidden">
      <router-view :key="$route.fullPath"></router-view>
    </main>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col d-none d-md-inline">
            <small class="slogan">
              Amplify art.
            </small>
            <div class="m2-3">
              <!--
              <router-link :to="{ name: 'darkness' }" class="small">Darkness️</router-link> &bull;
              <router-link :to="{ name: 'dao-osaka' }" class="small">DAO Osaka</router-link> &bull;
              <router-link :to="{ name: 'boards' }" class="small">Boards</router-link>
              -->
            </div>
          </div>
          <!-- temp for demo -->
          <div class="col-xs col-sm text-center mb-2" v-if="$remoteConfig.version != 'nomarket'">
            <small>
              <router-link :to="{ name: 'gallery' }">Gallery</router-link> &bull;
              <!-- Boards/Activity/Offers not ready yet -->
              <!--<router-link :to="{ name: 'boards' }">Boards</router-link> &bull; -->
              <!-- <router-link :to="{ name: 'artists' }">Artists</router-link> &bull;--> 
              <!-- <router-link :to="{ name: 'activity' }">Activity</router-link> &bull; -->
              <!-- <router-link :to="{ name: 'openOffers' }">Offers</router-link> &bull; -->
              <router-link :to="{ name: 'contracts' }">Contracts</router-link>

              <div>
                <router-link :to="{ name: 'privacy' }">Privacy</router-link> &bull;
                <router-link :to="{ name: 'tos' }">Terms of Service</router-link>
              </div>
            </small>
          </div>
          <div class="col-xs col-sm text-center">
            <a href="mailto:play@notreal.co" target="_blank" class="p-2" title="Mail">
              <font-awesome-icon :icon="['fas', 'envelope-square']" size="lg"></font-awesome-icon>
            </a>
            <a href="https://instagram.com/notreal.ai" target="_blank" class="p-2" title="Instagram">
              <font-awesome-icon :icon="['fab', 'instagram']" size="lg"></font-awesome-icon>
            </a>
            <a href="https://twitter.com/notrealAI" target="_blank" class="p-2" title="Twitter">
              <font-awesome-icon :icon="['fab', 'twitter']" size="lg"></font-awesome-icon>
            </a>
            <a href="https://discord.gg/P77P66TdmB" target="_blank" class="p-2" title="Discord">
              <font-awesome-icon :icon="['fab', 'discord']" size="lg"></font-awesome-icon>
            </a>
            <div class='d-none'>
              <a href="https://medium.com/notreal" target="_blank" class="p-2" title="Medium">
                <font-awesome-icon :icon="['fab', 'medium']" size="lg"></font-awesome-icon>
              </a>
            </div>
            <div class="row mt-2 mb-2">
              <div class="col text-center text-white small">
                  <p>
                    With heartfelt gratitude,
                    <br />
                    - The Not Real Company
                  </p> 
              </div>
            </div>
            <!-- temp for demo -->
            <div class="row mt-2 mb-2 d-none">
              <div class="col text-center small slogan">
                <current-network></current-network>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import * as actions from './store/actions';
  import CurrentNetwork from './components/generic/CurrentNetwork';
  import smoothscroll from 'smoothscroll-polyfill';
  import HeaderBar from './components/header/HeaderBar';
  //import NetworkWarningBanner from './components/generic/NetworkWarningBanner';
  // import * as vConsole from 'vconsole';
  //import Web3ConnectNavbarLogin from './components/auth/Web3ConnectNavBarLogin';
  //import { BNavbar, BNavbarBrand, BCollapse, BNavbarNav, BNavbarToggle } from 'bootstrap-vue';


  export default {
    name: 'app',
    metaInfo() {
      return { title: this.$build.title, meta:[{charset: 'utf-8' }]}
    },
    components: {
      CurrentNetwork,
      HeaderBar
    },
    computed: {},
    /*eslint brace-style: "off"*/
    mounted() {
      //console.log('router', this.$route)
      //const aspect = window.innerWidth / window.innerHeight
      //const body = document.querySelector('body')

      //const isMobile = /Mobi|Android/i.test(navigator.userAgent)
      //if (isMobile) {
      //  body.classList.add('is-mobile')
      //}

      //if (aspect < 1) {
      //  body.classList.add('portrait')
      //} else {
      //  body.classList.add('landscape')
      //}

      //console.log('Attempting to bootstrap application');

      // if (window.location.href.indexOf('__debug') > -1) {
      //   // eslint-disable-next-line new-cap,no-new
      //   new vConsole();
      // }

      //this.$store.dispatch(actions.FORCE_LOGIN_WEB3);

      // Re-init app on chain change
      //if (window.ethereum) {
      //  let firstConnection = window.ethereum.isConnected()
      //  const reloadAndLogin = () => {
      //    // First connection is already being triggered by LOGIN_WEB3,
      //    // don't be redundant and reload page
      //    if(!firstConnection) {
      //      firstConnection = true
      //      return
      //    }

      //    this.$router.go(this.$router.currentRoute);
      //    this.$store.dispatch(actions.FORCE_LOGIN_WEB3);
      //  }

      //  window.ethereum.on('chainChanged', reloadAndLogin)
      //  //window.ethereum.on('accountsChanged', reloadAndLogin)
      //}

      // Load USD value on start
      this.$store.dispatch(actions.GET_USD_PRICE);

      // Load Artist data at the earliest possibility
      this.$store.dispatch(actions.LOAD_ARTISTS);

      // Try to log in
      //if(this.$remoteConfig.version != 'nomarket') {
      //  this.$store.dispatch(actions.FORCE_LOGIN_WEB3, false);
      //}

      // polyfill so that anchor links scroll smoothly in every browser
      smoothscroll.polyfill();
  } 
};


</script>

<style lang="scss">

  // Bodoni fonts
  @import url("https://use.typekit.net/qzm3sju.css");
  @import url('https://fonts.googleapis.com/css?family=Maitree&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=DM+Serif+Display:400,400i&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=B612+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

  @import './ko-colours.scss';
  @import '../node_modules/bootstrap/scss/bootstrap.scss';
  @import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css';
  @import '../node_modules/vue-multiselect/dist/vue-multiselect.min.css';
  @import "../node_modules/rfs/scss";

  @import '../node_modules/intro.js/introjs.css';

  .neumorph {
    @import 'styles/neumorphism';
  }


  //$enable-responsive-font-sizes: true;
  $rfs-base-font-size: 0.75rem;
  $rfs-factor: 20;

  .jumbo-0r { @include font-size(24rem); }
  .jumbo-1r { @include font-size(20rem); }
  .jumbo-2r { @include font-size(14rem); }
  .jumbo-3r { @include font-size(12rem); }
  .jumbo-4r { @include font-size(10rem); }
  .display-0r { @include font-size(8rem); }
  .display-1r { @include font-size(7rem); }
  .display-2r { @include font-size(6rem); }
  .display-3r { @include font-size(5.5rem); }
  .display-4r { @include font-size(4.5rem); }
  .display-5r { @include font-size(3.5rem); }
  .display-6r { @include font-size(3rem); }
  .h1r { @include font-size(2.5rem); }
  .h2r { @include font-size(2rem); }
  .h3r { @include font-size(1.75rem); }
  .h4r { @include font-size(1.5rem); }
  .h5r { @include font-size(1.25rem); }
  .h6r { @include font-size(1rem); }
  .h7r { @include font-size(0.85rem); }
  .lh1r { @include rfs(2.5rem, line-height); }
  .lh2r { @include rfs(2rem, line-height); }
  .lh3r { @include rfs(1.75rem, line-height); }
  .lh4r { @include rfs(1.5rem, line-height); }
  .lh5r { @include rfs(1.25rem, line-height); }
  .lh6r { @include rfs(1rem, line-height); }
  .ht1r { @include rfs(2.5rem, height); }
  .ht2r { @include rfs(2rem, height); }
  .ht3r { @include rfs(1.75rem, height); }
  .ht4r { @include rfs(1.5rem, height); }
  .ht5r { @include rfs(1.25rem, height); }
  .ht6r { @include rfs(1rem, height); }

 /* $primary: #fff; */

  /* Sticky footer styles
-------------------------------------------------- */
  html {
    position: relative;
    min-height: 100%;
    scroll-behavior: smooth;
  }

  body {

    margin-bottom: 150px;
    padding-top: 50px;
    overflow-x: hidden;

    //padding-bottom: 15px;
    //-webkit-text-stroke: 1px rgba(0, 0, 0, 0.4);
    //text-shadow: transparent 0px 0px 0px, $realBlack 0.01% 0.01% 0.01%;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
  }

  h1 {
    margin-top: 30px;
  }

  main {
    min-height: 350px;
    overflow-x: hidden;
  }

  [v-cloak] {
    display: none
  }

  .hand-pointer {
    cursor: pointer;
  }

  body {
    .z-1 {
      z-index: 1 !important;
    }

    .z-2 {
      z-index: 2 !important;
    }

    .z-3 {
      z-index: 3 !important;
    }

    .z-4 {
      z-index: 4 !important;
    }

    .z-5 {
      z-index: 5 !important;
    }

    .z-n1 {
      z-index: -1 !important;
    }

    .z-0 {
      z-index: 0 !important;
    }

    .z-10 {
      z-index: 10 !important;
    }
  }

  .navbar {
    padding: 5px;
    min-height: 50px;
    color: $realBlack;

    .blue-dot {
      color: $primary;
    }
  }

  .nav-link a {
    color: black !important;
  }

  .nav-link {
    .is-active {
      $color: $realBlack;
    }
  }


  .navbar-brand {
    /*margin-left: 85px*/
  }

  .nav_collapse {
    /*margin-right: 85px;*/
  }

  .navbar-light .navbar-brand {
    font-weight: 500;
    font-style: normal;
    font-size: 28px;
    letter-spacing: .02em;
    line-height: 1em;
    text-transform: none;
  }

  .floating-nav {
    /*box-shadow: 0px 1px 1px #999;*/
  }

  .nav-tabs {
    border-bottom: 0;
    font-family: 'Roboto', sans-serif;
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    padding: 10px;
    margin: 10px;
    border-style: solid;
    border-width: 1px;
    background-color: $primary;
    border-color: $primary;
    color: $white;
    font-size: 0.85rem;
  }

  .nav-link.active:hover {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }

  .nav-tabs .nav-link {
    padding: 10px;
    margin: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: $primary;
    background-color: $white;
    font-size: 0.85rem;
    color: $primary;
  }

  .nav-link:hover {
    border-color: $primary !important;
    //background-color: $primary-fade;
    font-weight: bold;
    color: $primary;
  }

   .btn-outline-primary:hover {
     border-color: $primary !important;
     background-color: $primary-fade;
     color: $primary;
   }

  .full-banner {
    font-size: 2.75rem;
    background-image: url("../public/static/kodo_pattern.jpeg");
  }

  /* never have a border */
  .card {
    border: 0;
  }

  .custom-select {
    font-family: 'Roboto', sans-serif;
  }

  .sans-serif {
    font-family: 'Roboto', sans-serif;
  }

  .b612 {
    font-family: 'B612 mono', monospace;
  }

  .bodoni {
    font-family: bodoni-urw;
  }

  .neue-haas {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  .futura-light {
    font-family: futura-pt, sans-serif;
    font-style: normal;
  }

  .form-control {
    font-family: 'Roboto', sans-serif;
  }

  .bg-primary-fade {
    background-color: $primary-fade;
  }

  .bg-ok {
    background-color: #C5E3BF;
  }

  .bg-warn {
    background-color: #FFF3D8;
  }

  .bg-argh {
    background-color: #FF6347;
  }

  .justify-navbar {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
  }


  @mixin portrait {
    @media screen and (max-aspect-ratio: 1/1) {
      @content;
    }
  }

  @mixin landscape {
    @media screen and (min-aspect-ratio: 1/1) {
      @content;
    }
  }

  /* mobile only */
  @media screen and (max-aspect-ratio: 1/1) {
    body {
      //padding-bottom: 100px;
    }

    .footer {
      .col-sm {
        padding-bottom: 10px;
      }
    }

    .navbar-brand {
      margin-left: 5px
    }

    .navbar-light .navbar-brand {
      font-weight: 500;
      font-style: normal;
      font-size: 18px;
      letter-spacing: .02em;
      line-height: 1em;
      text-transform: none;
    }

    h1 {
      font-size: 1.5rem;
    }

    .justify-navbar {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    nav.navbar {
      background: white;
      min-height: 50px;
      .blend-burn {
        mix-blend-mode: initial;
      }
    }

    .navbar-position {
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      /*
      //left: auto;
      //border-top-left-radius: 40px;
      */
      z-index: 1030;
    }
  }

  @media screen and (min-aspect-ratio: 1/1) {
    .navbar-position {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;
    }
  }

  nav.navbar .nav-link {
    font-family: 'Josefin Sans', sans-serif;
  }

  nav.navbar {
    /*background: #c9d3f7a3;*/
    background: #748ed04a;
    align-items: start;
    backdrop-filter: blur(4px);
    box-shadow: inset 1px 1px 1px rgba( 255,255,255, 0.6 ),
                inset -1px -1px 1px rgba( 12,13,18, 0.06 ),
		1px 1px 1px 0px rgba( 12,13,18, 0.16), 
		2px 2px 3px 0px rgba( 12,13,18, 0.16);
  }

  nav.navbar .navbar-brand {
    text-shadow: 0px 1px 2.5px rgba(255,255,255,1), 0px -1px 1.5px rgba(0,0,0,0.5);
  }

  nav.navbar .navbar-toggler {
    border: none
  }


  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 150px;
    line-height: 30px;
    background-color: $realBlack;
  }

  body > .container {
    padding: 60px 60px 0;
  }

  .footer > .container {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    color: #f2f5fb;

    .slogan {
      color: rgba(255, 255, 255, 0.5);
    }

    a {
      color: #f2f5fb;
      /*padding-left: 2px;*/
      /*padding-right: 2px;*/
    }
  }

  .container-fluid > .container {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .btn-group-vertical > button {
    margin-bottom: 10px;
  }

  .badge {
    font-weight: normal !important;
    border-radius: 0;
  }

  .badge-nav {
    background-color: rgba(255, 255, 255, 0.5);
    color: $primary;
  }

  .badge-extra-data {
    background-color: $primary;
    color: $white;
    padding: 6px;
    margin: 2px;

    a {
      color: $white;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .back-arrow {
    cursor: pointer;
  }

  .card-desc {
    font-size: 0.75rem;
  }

  .artist-name {
    font-size: 0.95rem;
    font-weight: bold;
  }

  .artist-name-lg {
    font-size: 0.9rem;
  }

  .artist-link {
    color: $secondary;
  }

  .artist-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .tag {
    font-size: 0.75rem;
    display: inline-block;
  }

  #mc_embed_signup {
    color: $body-bg;
    clear: left;
    width: 100%;
  }

  .no-top-border {
    border-top: 0 none !important;
  }

  .full-banner-secondary {
    height: 50px;
    color: $body-bg;
  }

  .header-title {
    color: $realBlack;
  }

  code {
    color: $primary;
  }

  .img-thumbnail {
    border: 0;
  }

  // pulsing opacity animation
  @keyframes flickerAnimation {
    0% {
      opacity: .85;
    }
    50% {
      opacity: .15;
    }
    100% {
      opacity: .85;
    }
  }

  @-o-keyframes flickerAnimation {
    0% {
      opacity: .85;
    }
    50% {
      opacity: .15;
    }
    100% {
      opacity: .85;
    }
  }

  @-moz-keyframes flickerAnimation {
    0% {
      opacity: .85;
    }
    50% {
      opacity: .15;
    }
    100% {
      opacity: .85;
    }
  }

  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: .85;
    }
    50% {
      opacity: .15;
    }
    100% {
      opacity: .85;
    }
  }

  // vue-modal
  .v--modal-overlay {
    background: rgba(0, 0, 0, 0.7) !important;
  }

  // lazy loading
  img[lazy=loading] {
    -webkit-animation: flickerAnimation 4s infinite;
    -moz-animation: flickerAnimation 4s infinite;
    -o-animation: flickerAnimation 4s infinite;
    animation: flickerAnimation 4s infinite;
  }

  .bg-black {
    background-color: rgb(4,4,4);
  }
  .text-black {
    color: rgb(4,4,4);
  }
  
  .notreal-logo {
    font-family: bodoni-urw;
    font-weight: 700;
    text-shadow: none;
    line-height: 0.9rem;
    font-size: 1.8rem;
    transform: scale(1, 1.3);
    display: block;
  }

  .notreal-logo > *:first-child {
    color: black;
  }

  .notreal-logo > *:last-child {
    color: white;
    text-shadow: 0 0 2px rgba(0,0,0,0.5), 0 0 2px rgba(0,0,0,0.5), 0 0 2px rgba(0,0,0,0.5), 0 0 2px rgba(0,0,0,0.5);
  }

  .nav-brand-tagline {
    font-size: .5rem;
    font-weight: 300;
    font-family: bodoni-urw;
    color: #6177bba1;
  }

  // Web3 connect modal styling
  .dRFATY, .v-modal-overlay {
    z-index: 3000;
  }

  @font-face {
          font-family: sohne-var;
          src: url("../public/static/sohne-var.woff2") format("woff2-variations");
          font-weight: 1 1000;
          font-display: block;
          unicode-range: U+0000-007e
  }
  @font-face {
          font-family: sohne-var;
          src: url("../public/static/sohne-var-ext.woff2") format("woff2-variations");
          font-weight: 1 1000;
          font-display: block;
          unicode-range: U+007f-ffff
  }

  .sohne-var {
    font-family: sohne-var;
    //font-weight: 300;
    //color: #425466;
    //letter-spacing: .2px;
    //line-height: 1.55555556;
    //font-size: 18px;
  }


  // Portrait
  @media screen and (max-aspect-ratio: 1/1) {
    .flex-vmax {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }

    .flex-vmin {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
  }

  // Landscape
  @media screen and (min-aspect-ratio: 1/1) {
    .flex-vmax {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }

    .flex-vmin {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;

    }
  }

  @include portrait {
    .order-p-0 {  order: 0;   }
    .order-p-1 {  order: 1;   }
    .order-p-2 {  order: 2;   }
    .order-p-3 {  order: 3;   }
    .order-p-4 {  order: 4;   }
    .order-p-5 {  order: 5;   }
    .order-p-6 {  order: 6;   }
    .order-p-7 {  order: 7;   }
    .order-p-8 {  order: 8;   }
    .order-p-9 {  order: 9;   }
    .order-p-10 { order: 10; }
    .order-p-11 { order: 11; }
    .order-p-12 { order: 12; }
    .hide-p { display: none; }
  }

  @include landscape {
    .order-l-0 {  order: 0; }
    .order-l-1 {  order: 1; }
    .order-l-2 {  order: 2; }
    .order-l-3 {  order: 3; }
    .order-l-4 {  order: 4; }
    .order-l-5 {  order: 5; }
    .order-l-6 {  order: 6; }
    .order-l-7 {  order: 7; }
    .order-l-8 {  order: 8; }
    .order-l-9 {  order: 9; }
    .order-l-10 { order: 10; }
    .order-l-11 { order: 11; }
    .order-l-12 { order: 12; }
    .hide-l { display: none; }
  }

  .op-100 {
    opacity: 1 !important;
  }

  .op-75 {
    opacity: .75;
  }

  .op-65 {
    opacity: .65;
  }

  .op-50 {
    opacity: 0.5;
  }

  .op-40 {
    opacity: 0.4;
  }

  .op-25 {
    opacity: 0.25;
  }

  .b-0 {
    bottom: 0;
  }

  .r-0 {
    right: 0;
  }
  .t-0 {
    top: 0;
  }
  .l-0 {
    left: 0;
  }

  .r-auto {
    right: auto;
  }



  .w-20 {
    width: 20%;
  }

  .w-33 {
    width: 33.3333%;
  }

  // Width utils
  .w-100vmin {
    width: 100vmin;
  }

  .h-100vmin {
    height: 100vmin;
  }

  .wh-100 {
    width: 100%;
    height: 100%;
  }

  .wh-95vmin {
    width: 95vmin;
    height: 95vmin;
  }

  .w-0 {
    width: 0;
  }

  .w-100 {
    width: 100%;
  }
  .w-50 {
    width: 50%;
  }
  .w-vh {
    width: 100vh;
  }

  .w-auto {
    width: auto;
  }

  .justify-self-end {
    justify-self: end;
  }

  .justify-self-start {
    justify-self: end;
  }

  .justify-self-center {
    justify-self: center;
  }

  .lh-100 {
    line-height: 100%;
  }

  .lh-70 {
    line-height: 70%;
  }

  .lh-50 {
    line-height: 50%;
  }

  .lh-1 {
    line-height: 1;
  }

  .lh-1_2 {
    line-height: 1.2;
  }

  .lh-1_3 {
    line-height: 1.3;
  }

  .lh-0 {
    line-height: 0;
  }

  .offscreen {
    position:absolute !important;
    left: -9999px !important;
    visibility: hidden !important;
  }

  .josefin {
    font-family: 'Josefin Sans', sans-serif;
  }

  h1 {
    color: $realBlack;
  }

  h2 {
    color: $realBlack;
  }

  h6 {
    color: $realBlack;
  }

  .gutter {
    padding-left: 15px;
    padding-right: 15px;
  }

  .lh-1 {
    line-height: 1;
  }

  .p-1_2 {
    padding: 0.125rem !important;
  }

  .hide {
    visibility: hidden;
  }
  .usr-select-none {
    user-select: none;
  }

  .width-fit {
    width: fit-content !important;
  }

  .color-black {
    color: black !important;
  }
  .color-white {
    color: white !important;
  }

  .text-normalcase {
    text-transform: none;
  }

  .strikethrough {
    text-decoration: line-through;
  }

  .filter-grayscale {
    filter: grayscale(1);
  }

  .icomoon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }



</style>
