import axios from 'axios';
import {getApi, AXIOS_CONFIG} from '../../utils';

export default class AccountService {

  constructor(currentNetworkId = 1) {
    this.currentNetworkId = currentNetworkId;
    this.api = getApi();
  }

  /**
   * Updates the currentNetworkId, needed for when switching network
   * @param currentNetworkId
   */
  setNetworkId(currentNetworkId) {
    this.currentNetworkId = currentNetworkId;
  }

  /**
   * Get total owned tokens for account
   *
   * @param account
   * @return {Promise<*>}
   */
  async loadTotalOwnedTokenCount(account) {
    console.log(`Get KODA V1 & V2 owned tokens for account [${account}] on network [${this.currentNetworkId}]`);

    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/accounts/${account}/total`, AXIOS_CONFIG);
    // console.log(results.data);
    return results.data || {total: 0};
  }

  /**
   * Get tokens owned for account
   *
   * @param account
   * @return {Promise<*>}
   */
  async getAccountTokens(account) {
    console.log(`Get owned tokens for account [${account}] on network [${this.currentNetworkId}]`);

    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/accounts/${account}/tokens`, AXIOS_CONFIG);
    // console.log(results.data);
    return results.data || {};
  }

  /**
   * Get KODA v2 editions for account
   *
   * @param account
   * @return {Promise<*>}
   */
  async getKodaV2EditionsOwnerByAccount(account) {
    console.log(`Get KODA V2 editions owned by [${account}] on network [${this.currentNetworkId}]`);

    const results = await axios.get(`${this.api}/network/${this.currentNetworkId}/accounts/${account}/kodav2/editions`, AXIOS_CONFIG);
    // console.log(results.data);
    return results.data || [];
  }
}
