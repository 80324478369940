import axios from 'axios';
import {getApi, AXIOS_CONFIG} from '../../utils';

export default class TwitterService {

  constructor() {
    this.api = getApi();
  }

  /**
   * Get a tweet
   * @param account
   * @return {Promise<*>}
   */
  async getTweets(ids={}) {
    const results = await axios.get(`${this.api}/twitter?${new URLSearchParams(ids)}`, AXIOS_CONFIG);
    return results
  }
}
