// Apollo gql magic
//import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

class Apollo {
  constructor() {
    const graphBase = {
      local: "http://localhost:8000/subgraphs/name",
      http: "https://api.thegraph.com/subgraphs/name",
      ws: "wss://api.thegraph.com/subgraphs/name",
    }

    const graphName = {
      mumbai: 'not-real-cats-mumbai',
      matic: 'not-real-cats'
    }

    //const uri = `${graphBase.hosted}/notrealai/${graphName.mumbai}`

    const httpLink = new HttpLink({
      uri: `${graphBase.http}/notrealai/${graphName.mumbai}`,
    })

    const wsLink = new WebSocketLink({
      uri: `${graphBase.ws}/notrealai/${graphName.mumbai}`,
      options: {
        reconnect: true
      }
    })

    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
      },
      wsLink,
      httpLink
    )

    this.apolloClient = new ApolloClient({
      link,
      cache: new InMemoryCache(),
      connectToDevTools: false 
    });

    this.apolloProvider = new VueApollo({
      defaultClient: this.apolloClient
    });
  }
}

const {apolloClient, apolloProvider} = new Apollo()

//Vue.use(VueApollo);

export {
  apolloClient,
  apolloProvider
}

